import { createI18n } from 'vue-i18n';
import { sv } from './locales/sv';
import { Locale } from './models/locale';
import { en } from './locales/en';

export const createBafI18n = (defaultLocale = Locale.Swedish) =>
  createI18n({
    locale: defaultLocale,
    fallbackLocale: defaultLocale,
    availableLocales: [Locale.Swedish, Locale.English],
    legacy: false,
    messages: {
      sv,
      en,
    },
  });

export const i18n = createBafI18n();
