import type { Component } from 'vue';

export class DynamicPrintPages {
  availableHeightOfOnePageInPx: number;
  pages: Array<DynamicPrintPage> = [];

  constructor(availableHeightOfOnePageInPx: number) {
    this.availableHeightOfOnePageInPx = availableHeightOfOnePageInPx;
  }

  get lastPage() {
    return this.pages.at(-1) ?? new DynamicPrintPage();
  }

  availableHeightOnLastPage(breakpoint?: number) {
    let availableHeightOnLastPage = this.availableHeightOfOnePageInPx - this.lastPage.totalHeight;
    if (breakpoint && availableHeightOnLastPage <= breakpoint) {
      availableHeightOnLastPage = this.availableHeightOfOnePageInPx;
    }
    return availableHeightOnLastPage;
  }

  addComponent(component: DynamicPrintComponent) {
    const page = new DynamicPrintPage();
    page.addComponents(component);
    this.addPage(page);
  }

  private addPage(pageToAdd: DynamicPrintPage) {
    if (this.pages.length === 0) {
      this.pages.push(pageToAdd);
      return;
    }

    const canContentFitIntoLastPage = pageToAdd.totalHeight <= this.availableHeightOnLastPage();
    if (canContentFitIntoLastPage) {
      this.lastPage.addComponents(...pageToAdd.components);
    } else {
      this.pages.push(pageToAdd);
    }
  }
}

export class DynamicPrintPage {
  components: Array<DynamicPrintComponent> = [];

  get totalHeight() {
    return this.components
      .map((c) => c.height)
      .reduce((totalHeight, componentHeight) => totalHeight + componentHeight, 0);
  }

  addComponents(...components: DynamicPrintComponent[]) {
    this.components.push(...components);
  }
}

interface DynamicPrintComponent {
  component: Component;
  props?: Record<string, unknown>;
  height: number;
}
