import { createDataFunction } from '../../functions/index.js';
import { PressureTestSequentialNumberWarehouse, } from '../../models/index.js';
export const createPressureTestSequentialNumberKarlskrona = createDataFunction({
    id: '0f0efe5a-addc-40e9-8a9e-82779630bd7a',
    sequentialNumber: 1,
    warehouse: PressureTestSequentialNumberWarehouse.Karlskrona,
    date: '2024-05-22T16:03:23.906Z',
    created: '2024-05-22T16:03:23.906Z',
    updated: '2024-05-23T21:48:52.584Z',
});
export const createPressureTestSequentialNumberKalmar = createDataFunction({
    id: '26a71100-fe7d-4246-9ca8-463af4eba47b',
    sequentialNumber: 2,
    warehouse: PressureTestSequentialNumberWarehouse.Kalmar,
    date: '2024-06-22T16:03:23.906Z',
    created: '2024-06-22T16:03:23.906Z',
    updated: '2024-06-23T21:48:52.584Z',
});
export const createCreatePressureTestSequentialNumberKarlskrona = createDataFunction({
    warehouse: PressureTestSequentialNumberWarehouse.Karlskrona,
});
export const createCreatePressureTestSequentialNumberKalmar = createDataFunction({
    warehouse: PressureTestSequentialNumberWarehouse.Kalmar,
});
