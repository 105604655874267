<script setup lang="ts">
import { computed, toRefs } from 'vue';

const props = withDefaults(
  defineProps<{
    addons?: boolean;
    grouped?: boolean;
  }>(),
  {
    addons: false,
    grouped: false,
  },
);
const { addons, grouped } = toRefs(props);

const classes = computed(() => ({
  'has-addons': addons.value,
  'is-grouped': grouped.value,
}));
</script>

<template>
  <div class="field" :class="classes">
    <slot></slot>
  </div>
</template>
