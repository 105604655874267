export var BafErrorSlug;
(function (BafErrorSlug) {
    BafErrorSlug["ServiceOrderDenyWithoutReason"] = "ServiceOrderDenyWithoutReason";
    BafErrorSlug["ServiceOrderFinishWithoutRowsCompleted"] = "ServiceOrderFinishWithoutRowsCompleted";
    BafErrorSlug["AssetCannotBeRemovedWhenConnectedToCertification"] = "AssetCannotBeRemovedWhenConnectedToCertification";
    BafErrorSlug["AssetCannotBeRemovedWhenConnectedToPressureTest"] = "AssetCannotBeRemovedWhenConnectedToPressureTest";
})(BafErrorSlug || (BafErrorSlug = {}));
export class BafError extends Error {
    slug;
    constructor(props) {
        if (typeof props === 'string') {
            super(props);
        }
        else {
            super(props.message);
            this.slug = props.slug;
        }
    }
}
