<script setup lang="ts">
import { ref, toRefs } from 'vue';
import BafModal from '../standard/modal/baf-modal.vue';

const modal = ref<InstanceType<typeof BafModal>>();

const props = defineProps<{
  message: string;
  cancelCallback?: () => Promise<void>;
  confirmCallback: () => Promise<void>;
}>();
const { message, confirmCallback, cancelCallback } = toRefs(props);

async function onCancel() {
  await cancelCallback.value?.();
  modal.value?.close();
}

async function onConfirm() {
  await confirmCallback.value();
  modal.value?.close();
}
</script>

<template>
  <baf-modal ref="modal" :title="$t('general.confirm')" tag="div">
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <p>{{ message }}</p>
    </template>

    <template #footer>
      <button type="button" class="button" data-testid="cancelButton" @click="onCancel">
        {{ $t('general.cancel') }}
      </button>
      <button
        type="button"
        class="button is-primary"
        data-testid="confirmButton"
        @click="onConfirm"
      >
        {{ $t('general.confirm') }}
      </button>
    </template>
  </baf-modal>
</template>
