import { randomString } from 'baf-shared/dist/utils';
import { ref } from 'vue';

class AppService {
  readonly rootAppKey = ref('');

  reload() {
    this.rootAppKey.value = randomString();
  }
}

export const appService = new AppService();
