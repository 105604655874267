import { utils } from 'baf-shared';

const now = () => `[${utils.date.nowInLocale()}]`;

export const logger = {
  info: (...args: unknown[]) => {
    if (import.meta.env.PROD) return;
    console.log(now(), ...args);
  },

  warn: (...args: unknown[]) => {
    console.warn(now(), ...args);
  },
};
