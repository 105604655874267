import type { models, Nullish } from 'baf-shared';
import { ServiceOrderStatus } from 'baf-shared/dist/models';
import { computed, type Ref } from 'vue';

export function useServiceOrderComputed(
  serviceOrder: Ref<Nullish<models.ServiceOrder | Omit<models.ServiceOrder, 'rows'>>>,
) {
  const isInProgress = computed(() => serviceOrder.value?.status === ServiceOrderStatus.InProgress);
  const isCreated = computed(() => serviceOrder.value?.status === ServiceOrderStatus.Created);
  const isAssigned = computed(() => serviceOrder.value?.status === ServiceOrderStatus.Assigned);
  const isReceived = computed(() => serviceOrder.value?.status === ServiceOrderStatus.Received);
  return {
    isInProgress,
    isCreated,
    isAssigned,
    isReceived,
  };
}
