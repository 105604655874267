<script setup lang="ts">
import { type models, sortBy } from 'baf-shared';
import { ref } from 'vue';
import TitleValueRoot from '@/components/standard/title-value/title-value-root.vue';

const props = defineProps<{
  verifications: models.CertificationVerification[];
}>();
const verifications = ref(sortBy(props.verifications, 'rowNumber'));
</script>

<template>
  <div>
    <strong>{{ $t('general.checkpoints') }}</strong>
    <div class="columns is-multiline pt-2">
      <div v-for="verification of verifications" :key="verification.id" class="column is-4 py-0">
        <title-value-root fullwidth>
          <div class="title-value">
            <dt class="title-value-title">{{ verification.name }}</dt>
            <dd class="title-value-value">
              {{ $t(`general.enums.certificationVerificationStatusShort.${verification.status}`) }}
            </dd>
          </div>
        </title-value-root>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/overrides';

.title-value {
  margin: 0.5em 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $black;
  white-space: nowrap;

  &-title {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
  }

  &-value {
    margin-left: 0.5em;
  }
}
</style>
