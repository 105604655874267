<script setup lang="ts">
import { type models } from 'baf-shared';
import BafTable from '@/components/standard/table/baf-table.vue';
import { computed, toRefs } from 'vue';
import { TableColumn } from '@/components/standard/table/models/table';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  certification: models.Certification;
}>();
const { certification } = toRefs(props);

const columns = computed(() => [
  TableColumn.for('fireAlarmName', t('general.name')),
  TableColumn.for('fireAlarmCentral', t('general.central')),
  TableColumn.for('fireAlarmAlarmedArea', t('general.alarmedArea')),
]);

const rows = [
  {
    fireAlarmName: certification.value.fireAlarmName,
    fireAlarmCentral: certification.value.fireAlarmCentral,
    fireAlarmAlarmedArea: certification.value.fireAlarmAlarmedArea,
  },
];
</script>

<template>
  <baf-table :columns="columns" :rows="rows"></baf-table>
</template>
