<script setup lang="ts" generic="TModel extends Nullish<string | number | symbol | object>">
import { models, type Nullish, utils } from 'baf-shared';
import { ref } from 'vue';
import BafField from '@/components/standard/form/baf-field.vue';
import { useElementHover, useFocus } from '@vueuse/core';
import { useLoading } from '@/composeables/use-loading';
import { certificationService } from '@/services/certification/certification-service';

const { loadingClass, startLoading, stopLoading } = useLoading();

type CertificationRowDataType = {
  id: string;
  data: models.Suit;
  created: string;
};
const emit = defineEmits<{
  (e: 'click-row', certificationRow: models.CertificationRow): void;
}>();

const certificationType = models.CertificationType.Suit;

const searchInputDropdown = ref();
const isSearchInputDropdownHovered = useElementHover(searchInputDropdown);

const searchInput = ref();
const { focused: searchInputFocused } = useFocus(searchInput);

const search = ref('');
const searchCertificationRows = ref<models.CertificationRow[]>([]);

const certificationRowData = ref<CertificationRowDataType[]>(
  utils.type.asType<CertificationRowDataType[]>([]),
);

function createCertificationRowsData(rows?: models.CertificationRow[]): CertificationRowDataType[] {
  return rows
    ? rows.map((row) => {
        return {
          id: row.id,
          data: utils.json.copy(row?.data ?? {}) as models.Suit,
          created: utils.date.formatDate(row.created),
        };
      })
    : [];
}

let timeout = 0;
async function onSearch() {
  startLoading();
  window.clearTimeout(timeout);
  timeout = window.setTimeout(async () => {
    if (search.value === '') {
      searchCertificationRows.value = [];
    } else {
      searchCertificationRows.value = await certificationService.searchRows({
        search: search.value,
        type: certificationType,
      });
      certificationRowData.value = createCertificationRowsData(searchCertificationRows.value);
    }

    stopLoading();
  }, 500);
}

function onSearchClick(value: CertificationRowDataType) {
  search.value = '';
  searchCertificationRows.value = [];
  emit('click-row', value as models.CertificationRow);
}
</script>

<template>
  <div class="dropdown is-active">
    <div ref="searchInputDropdown" class="dropdown-trigger">
      <baf-field>
        <div class="control is-medium" :class="loadingClass">
          <input
            ref="searchInput"
            v-model="search"
            class="input"
            type="text"
            inputmode="text"
            :placeholder="`${$t('general.search')} ${$t('general.serialNumber').toLowerCase()}`"
            @update:model-value="onSearch"
          />
          <div
            v-if="
              (searchInputFocused || isSearchInputDropdownHovered) &&
              searchCertificationRows.length > 0
            "
            id="dropdown-menu"
            class="dropdown-menu pressure-test-menu"
            role="menu"
          >
            <div class="dropdown-content">
              <a
                v-for="certificationRow in certificationRowData"
                :key="certificationRow.id"
                class="dropdown-item nowrap"
                @click.stop.prevent="onSearchClick(certificationRow)"
              >
                <strong> {{ certificationRow.data.name }} </strong>:
                <br />
                {{ certificationRow.created }}
              </a>
            </div>
          </div>
        </div>
      </baf-field>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pressure-test-menu {
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid lightgray;
  border-radius: 0.25rem;
}
.nowrap {
  white-space: nowrap;
}
</style>
