export var ArticleType;
(function (ArticleType) {
    ArticleType["Article"] = "Article";
    ArticleType["Action"] = "Action";
})(ArticleType || (ArticleType = {}));
export var ArticleState;
(function (ArticleState) {
    ArticleState["Active"] = "active";
    ArticleState["Inactive"] = "inactive";
})(ArticleState || (ArticleState = {}));
