import { authService } from '@/services/auth/auth-service';
import { models } from 'baf-shared';
import { computed } from 'vue';

export function useAuth() {
  const authenticated = computed(() => {
    const state = authService.state().value;
    return state?.loading === false && state?.authenticated === true;
  });
  const me = computed(() => authService.state().value?.me);
  const isAdmin = computed(() => me.value?.access?.includes(models.Access.Admin));
  const loading = computed(() => authService.state().value?.loading === true);
  const accessToken = computed(() => authService.state().value?.accessToken);
  const login = () => authService.login();
  const logout = () => authService.logout();

  return {
    authenticated,
    me,
    isAdmin,
    loading,
    accessToken,
    login,
    logout,
  };
}
