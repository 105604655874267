export var ServiceOrderRowStatus;
(function (ServiceOrderRowStatus) {
    ServiceOrderRowStatus["InProgress"] = "InProgress";
    ServiceOrderRowStatus["Finished"] = "Finished";
})(ServiceOrderRowStatus || (ServiceOrderRowStatus = {}));
export var ServiceOrderRowAddition;
(function (ServiceOrderRowAddition) {
    ServiceOrderRowAddition["Approved"] = "Approved";
    ServiceOrderRowAddition["Disapproved"] = "Disapproved";
    ServiceOrderRowAddition["Unchecked"] = "Unchecked";
    ServiceOrderRowAddition["Inaccessible"] = "Inaccessible";
    ServiceOrderRowAddition["NotPicked"] = "NotPicked";
})(ServiceOrderRowAddition || (ServiceOrderRowAddition = {}));
