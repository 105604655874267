<script setup lang="ts">
import { type models, utils } from 'baf-shared';
import { toRefs } from 'vue';
import TitleValueRoot from '@/components/standard/title-value/title-value-root.vue';
import TitleValue from '@/components/standard/title-value/title-value.vue';

const props = defineProps<{
  pressureTests: models.PressureTest[];
  startRowNumber: number;
}>();
const { pressureTests } = toRefs(props);
</script>

<template>
  <table>
    <thead>
      <th class="minimal-table-column"></th>
      <th class="minimal-table-column nowrap">
        {{ $t('general.manufacturer') }} / {{ $t('general.rowNumberShort') }}
      </th>
      <th></th>
      <th></th>
      <th></th>
    </thead>

    <tbody>
      <template v-for="(pressureTest, index) in pressureTests" :key="pressureTest.id">
        <tr>
          <td>{{ startRowNumber + index }}</td>
          <td class="minimal-table-column">
            {{ pressureTest.manufacturer }} <br />
            {{ pressureTest.serialNumber }}
          </td>
          <td class="nowrap">
            <title-value-root fullwidth>
              <title-value
                class="py-0"
                :title="$t('general.gas')"
                :value="pressureTest.gas"
              ></title-value>
              <title-value
                class="py-0"
                :title="$t('general.volumeLiter')"
                :value="pressureTest.volume"
              ></title-value>
              <title-value
                class="py-0"
                :title="$t('general.diameter')"
                :value="pressureTest.diameter"
              ></title-value>
              <title-value class="py-0" :title="$t('general.emptyFilledWeight')">
                {{ pressureTest.emptyWeight || '-' }} / {{ pressureTest.filledWeight || '-' }}
              </title-value>
              <title-value
                :title="$t('general.typeOfControl')"
                :value="$t('general.liquidSample')"
                class="py-0 nowrap"
              >
              </title-value>
              <title-value
                :title="$t('general.inspectionCertificateText')"
                class="py-0 nowrap"
                default-value=""
                block
              ></title-value>
            </title-value-root>
          </td>
          <td class="nowrap">
            <title-value-root fullwidth>
              <title-value
                class="py-0"
                :title="$t('general.filledPressure')"
                :value="pressureTest.filledPressure"
              ></title-value>
              <title-value
                class="py-0"
                :title="$t('general.pressureTest')"
                :value="pressureTest.testedPressure"
              ></title-value>
              <title-value
                class="py-0"
                :title="$t('general.date')"
                :value="utils.date.formatDate(pressureTest.validFrom)"
              ></title-value>
              <title-value
                class="py-0"
                :title="$t('general.nextService')"
                :value="utils.date.formatDate(pressureTest.nextService)"
              ></title-value>
              <title-value
                class="py-0"
                :title="$t('general.manufacturingDateShort')"
                :value="utils.date.formatDate(pressureTest.manufacturingDate)"
              ></title-value>
              <title-value class="py-0" :title="$t('general.remark')">
                <span class="limit-text-to-three-rows">
                  {{ utils.joinValues(pressureTest.actions, ', ') }}
                </span>
              </title-value>
            </title-value-root>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
.minimal-table-column {
  width: 1%;
}
.nowrap {
  white-space: nowrap;
}
.limit-text-to-three-rows {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
