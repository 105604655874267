<script setup lang="ts">
import PrintPage from '@/components/print/standard/print-page.vue';
import TitleValueRoot from '@/components/standard/title-value/title-value-root.vue';
import TitleValue from '@/components/standard/title-value/title-value.vue';
import { models, sortBy, utils } from 'baf-shared';
import { computed, toRefs } from 'vue';
import { DynamicPrintPages } from '@/components/print/dynamic-print/dynamic-print-pages';
import type { PrintOrientation } from '@/models/print-orientation';
import PrintServiceOrderRowTable from '@/components/print/service-order/print-service-order-row-table.vue';

const props = defineProps<{
  orientation: PrintOrientation;
  serviceOrder: models.ServiceOrder;
}>();
const { orientation, serviceOrder } = toRefs(props);

const customer = computed(() => {
  const { customer } = serviceOrder.value ?? {};
  return customer;
});

const customerPostcodeAndCity = computed(() => {
  return utils.joinValues(
    [customer.value?.name, customer.value?.postCode, customer.value?.city],
    ' ',
  );
});

const customerAddress = computed(() => {
  return utils.joinValues(
    [customer.value?.address1, customer.value?.address2, customerPostcodeAndCity.value],
    '\n',
  );
});

const contactInformation = computed(() => {
  return utils.joinValues(
    [
      serviceOrder.value.contactName,
      serviceOrder.value.contactEmail,
      serviceOrder.value.contactPhone,
    ],
    '\n',
  );
});

const deliveryInformation = computed(() => {
  return utils.joinValues(
    [
      serviceOrder.value.deliveryAddress1,
      serviceOrder.value.deliveryPostCode,
      serviceOrder.value.deliveryCountry,
    ],
    '\n',
  );
});

const disapprovedServiceOrderRows = computed(() => {
  return sortBy(
    serviceOrder.value.rows.filter(
      (row) => row.addition === models.ServiceOrderRowAddition.Disapproved,
    ),
    'rowNumber',
  );
});

const availableHeightOfOnePageInPx = 600;
const dynamicPrintPages = new DynamicPrintPages(availableHeightOfOnePageInPx);

const disapprovedServiceOrderRowsPerPrintPage = 5;

const copyOfDisapprovedServiceOrderRows = utils.json.copy(disapprovedServiceOrderRows.value);

while (copyOfDisapprovedServiceOrderRows.length !== 0) {
  const disapprovedOrderRowsForPage = copyOfDisapprovedServiceOrderRows.splice(
    0,
    disapprovedServiceOrderRowsPerPrintPage,
  );
  dynamicPrintPages.addComponent({
    component: PrintServiceOrderRowTable,
    props: {
      disapprovedServiceOrderRows: disapprovedOrderRowsForPage,
    },
    height: availableHeightOfOnePageInPx,
  });
}
</script>

<template>
  <template v-for="(page, index) in dynamicPrintPages.pages" :key="index">
    <print-page
      :title="$t('general.serviceOrder')"
      data-testid="print-page"
      text-size="0.75rem"
      :page-number="index + 1"
      :orientation="orientation"
    >
      <div class="columns is-multiline">
        <div class="column is-4">
          <title-value-root>
            <title-value :title="$t('general.customerNumber')" :value="customer?.fortnoxId" block>
            </title-value>
          </title-value-root>
        </div>

        <div class="column is-4">
          <title-value-root>
            <title-value :title="$t('general.phoneNumber')" :value="customer?.phone" block>
            </title-value>
          </title-value-root>
        </div>

        <div class="column is-4">
          <title-value-root>
            <title-value :title="$t('general.customer')" block render-as-is>
              {{ customerAddress }}
            </title-value>
          </title-value-root>
        </div>

        <div class="column is-4">
          <title-value-root>
            <title-value :title="$t('general.contact')" block render-as-is>
              {{ contactInformation }}
            </title-value>
          </title-value-root>
        </div>

        <div class="column is-4">
          <title-value-root>
            <title-value :title="$t('general.deliveryInformation')" block render-as-is>
              {{ deliveryInformation }}
            </title-value>
          </title-value-root>
        </div>
      </div>

      <template v-for="(component, componentIndex) in page.components" :key="componentIndex">
        <component :is="component.component" v-bind="component.props"></component>
      </template>
    </print-page>
  </template>
</template>

<style lang="scss">
@media print {
  @page {
    margin: 0;
    size: portrait;
  }
}

.swedac {
  width: 45px;
}
</style>
