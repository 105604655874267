<script setup lang="ts">
import { useLoading } from '@/composeables/use-loading';
import { models, type Nullish } from 'baf-shared';
import { onBeforeMount, ref, toRefs } from 'vue';
import BafModal from '../standard/modal/baf-modal.vue';
import { categoryValueService } from '@/services/category-value/category-value-service';
import BafTextField from '@/components/standard/form/baf-text-field.vue';
import BafField from '@/components/standard/form/baf-field.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { startLoading, stopLoading, loadingClass } = useLoading();
const modal = ref<InstanceType<typeof BafModal>>();

const categories = Object.values(models.CategoryValueCategory).map((category) => ({
  text: t(`general.enums.categoryValueCategory.${category}`),
  value: category,
}));

const formData = ref(createFormData());
type FormData = models.UpcreateCategoryValue;

function createFormData(categoryValue?: Nullish<models.CategoryValue>): FormData {
  const [firstCategory] = categories;
  return {
    category: categoryValue?.category ?? firstCategory.value,
    value: categoryValue?.value ?? '',
  };
}

const props = defineProps<{
  categoryValue?: models.CategoryValue;
}>();

const { categoryValue } = toRefs(props);
const isUpdating = !!categoryValue.value;

const emit = defineEmits<{
  (e: 'upcreate'): void;
}>();

async function onCancel() {
  formData.value = createFormData(categoryValue.value);
  modal.value?.close();
}

async function upcreateAsset(): Promise<Nullish<models.CategoryValue>> {
  const upcreateCategoryValue: models.UpcreateCategoryValue = {
    value: formData.value.value,
    category: formData.value.category,
  };

  if (isUpdating) {
    return categoryValueService.update(categoryValue.value!.id, upcreateCategoryValue);
  } else {
    await categoryValueService.create(upcreateCategoryValue);
    return undefined;
  }
}

async function onSubmit() {
  try {
    startLoading();
    const upcreatedAsset = await upcreateAsset();
    formData.value = createFormData(upcreatedAsset);
    emit('upcreate');
    modal.value?.close();
  } finally {
    stopLoading();
  }
}

onBeforeMount(async () => {
  try {
    startLoading();
    formData.value = createFormData(categoryValue.value);
  } finally {
    stopLoading();
  }
});
</script>

<template>
  <baf-modal
    ref="modal"
    :title="isUpdating ? $t('general.categoryValueUpdate') : $t('general.categoryValueCreate')"
    tag="form"
    @submit.prevent="onSubmit"
  >
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <baf-field>
        <label class="label" for="categoryValueCategory">{{ $t('general.category') }}</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select
              id="categoryValueCategory"
              v-model="formData.category"
              required
              data-testid="categoryValueCategorySelect"
            >
              <option v-for="(category, index) in categories" :key="index" :value="category.value">
                {{ category.text }}
              </option>
            </select>
          </div>
        </div>
      </baf-field>

      <baf-text-field
        id="categoryValueValue"
        v-model="formData.value"
        :label="$t('general.value')"
        required
      ></baf-text-field>
    </template>

    <template #footer>
      <button
        type="button"
        class="button"
        data-testid="cancelButton"
        :class="loadingClass"
        @click="onCancel()"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        type="submit"
        class="button is-primary"
        data-testid="upcreateButton"
        :class="loadingClass"
      >
        {{ isUpdating ? $t('general.update') : $t('general.create') }}
      </button>
    </template>
  </baf-modal>
</template>
