<script setup lang="ts">
import type { NotificationData } from '@/services/notification/models/notification';
import { notificationService } from '@/services/notification/notification-service';
import { computed } from 'vue';

const notifications = computed(() => notificationService.notifications.value);

function notificationClass(notification: NotificationData) {
  if (!notification.type) {
    return undefined;
  }

  return [`is-${notification.type}`];
}
</script>

<template>
  <div class="notifications">
    <div
      v-for="(notfication, index) in notifications"
      :key="notfication.id"
      :data-testid="`notification-${index + 1}`"
      class="box notification"
      :class="notificationClass(notfication)"
    >
      <button class="delete" @click="notificationService.close(notfication.id)"></button>
      {{ notfication.text }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'bulma/sass/utilities/_all.sass';

.notifications {
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 4em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  pointer-events: none;
  touch-action: none;

  @media screen and (max-width: $desktop) {
    justify-content: start;
  }

  .notification {
    pointer-events: all;
    touch-action: all;
    z-index: 101;
    max-width: 50%;

    @media screen and (max-width: $desktop) {
      max-width: 100%;
    }

    :not(:last-of-type) {
      margin-bottom: 1em;
    }
  }
}
</style>
