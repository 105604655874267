export const enum RouteName {
  Root = 'Root',

  ServiceOrdersRoot = 'ServiceOrdersRoot',
  ServiceOrdersStart = 'ServiceOrdersStart',
  ServiceOrdersCreate = 'ServiceOrdersCreate',
  ServiceOrdersLabels = 'ServiceOrdersLabels',
  ServiceOrdersServiceOrder = 'ServiceOrdersServiceOrder',

  CertificationsRoot = 'CertificationsRoot',
  CertificationsStart = 'CertificationsStart',
  CertificationsCreate = 'CertificationsCreate',
  CertificationsUpdate = 'CertificationsUpdate',
  CertificationsAssets = 'CertificationsAssets',

  PressureTestsRoot = 'PressureTestsRoot',
  PressureTestsStart = 'PressureTestsStart',
  PressureTestsAssets = 'PressureTestsAssets',
  PressureTestsCategoryValues = 'PressureTestsCategoryValues',
  PressureTestsSequentialNumber = 'PressureTestsSequentialNumber',

  DataRoot = 'DataRoot',
  DataArticles = 'DataArticles',
  DataCustomers = 'DataCustomers',
  DataEmployees = 'DataEmployees',
  Print = 'Print',
}
