<script setup lang="ts">
import BafTable from '@/components/standard/table/baf-table.vue';
import { TableColumn, type TableRow } from '@/components/standard/table/models/table';
import { useLoading } from '@/composeables/use-loading';
import { models, utils } from 'baf-shared';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { pressureTestSequentialNumberService } from '@/services/pressure-test-sequential-number/pressure-test-sequential-number-service';
import { RouteName } from '@/router/models/route-name';
import { router } from '@/router/router';

const { t } = useI18n();
const route = useRoute();
const { loading, startLoading, stopLoading } = useLoading();

const columns = [
  TableColumn.for('sequentialNumber', t('general.sequentialNumber')),
  TableColumn.for('warehouse', t('general.warehouse'), {
    renderer: (value) => t(`general.enums.warehouse.${value as string}`),
  }),
  TableColumn.for('date', t('general.date'), {
    renderer: (value) => utils.date.formatDate(value as string),
    charactersWidth: 16,
  }),
];

const data = ref<models.PressureTestSequentialNumber[]>([]);

async function onClickRow(row: models.PressureTestSequentialNumber | TableRow) {
  const sequentialNumber = row as models.PressureTestSequentialNumber;

  await router.push({
    name: RouteName.PressureTestsSequentialNumber,
    params: {
      sequentialNumberId: sequentialNumber.id,
    },
  });
}

onBeforeMount(async () => {
  try {
    startLoading();
    data.value = await pressureTestSequentialNumberService.getAll();
  } finally {
    stopLoading();
  }
});
</script>

<template>
  <baf-table
    :columns="columns"
    :rows="data"
    :loading="loading"
    :sort-default="{ key: 'sequentialNumber', asc: false }"
    :search-query="route.query"
    search
    paginate
    hover
    @click-row="onClickRow"
  ></baf-table>
</template>
