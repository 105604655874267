import { labelIdbService } from '@/services/indexed-db/label-idb-service';
import { models } from 'baf-shared';
import { appHttpService } from '../standard/http-service';

class LabelService {
  async getAll(): Promise<models.Label[]> {
    const labels = await appHttpService.request.url('/labels').get().json<models.Label[]>();
    labelIdbService.putAll(labels);
    return labels;
  }

  async delete(id: string): Promise<models.Label> {
    const label = await appHttpService.request.url(`/labels/${id}`).delete().json<models.Label>();
    await labelIdbService.delete(id);
    return label;
  }

  async create(description: string) {
    const label = await appHttpService.request
      .url(`/labels`)
      .post({ description })
      .json<models.Label>();
    await labelIdbService.put(label);
    return label;
  }

  async update(id: string, description: string) {
    const label = await appHttpService.request
      .url(`/labels/${id}`)
      .put({ description })
      .json<models.Label>();
    await labelIdbService.put(label);
    return label;
  }
}

export const labelService = new LabelService();
