<script setup lang="ts">
import BafModal from '@/components/standard/modal/baf-modal.vue';
import { models, utils } from 'baf-shared';
import { computed, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
type FormData = models.UpcreateCertificationVerification & { note: string };
const modal = ref<InstanceType<typeof BafModal>>();

const emit = defineEmits<{
  (e: 'upcreate', value: models.UpcreateCertificationVerification): void;
}>();

const props = withDefaults(
  defineProps<{
    verifications?: models.UpcreateCertificationVerification[];
    certificationVerification?: models.UpcreateCertificationVerification;
  }>(),
  {
    verifications: () => [],
    certificationVerification: undefined,
  },
);

const { verifications, certificationVerification } = toRefs(props);

const isUpdating = !!certificationVerification.value;
const formData = ref(createFormData(certificationVerification.value));

const STATUSES = Object.values(models.CertificationVerificationStatus).map((status) => ({
  text: t(`general.enums.certificationVerificationStatus.${status}`),
  value: status,
}));
const certificationVerificationStatuses = computed(() => STATUSES);
function createFormData(verification?: models.UpcreateCertificationVerification): FormData {
  const rowNumber = utils.number.getNext10thIncrement(verifications.value.length * 10);

  return {
    rowNumber: verification?.rowNumber ?? rowNumber,
    name: verification?.name ?? '',
    status: verification?.status ?? models.CertificationVerificationStatus.Approved,
    note: verification?.note ?? '',
  };
}

async function onCancel() {
  formData.value = createFormData(certificationVerification.value);
  modal.value?.close();
}

async function onSubmit() {
  emit('upcreate', utils.json.copy(formData.value));
  if (!isUpdating) {
    formData.value = createFormData();
  }
  modal.value?.close();
}
</script>

<template>
  <baf-modal
    ref="modal"
    :title="
      isUpdating
        ? $t('general.certificationVerificationUpdate')
        : $t('general.certificationVerificationCreate')
    "
    tag="form"
    @submit.prevent="onSubmit"
  >
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <div class="field">
        <label class="label" for="certificationVerificationRowNumber">{{
          $t('general.rowNumber')
        }}</label>
        <div class="control">
          <input
            id="certificationVerificationRowNumber"
            v-model="formData.rowNumber"
            data-testid="certificationVerificationRowNumberInput"
            type="number"
            class="input"
          />
        </div>
      </div>
      <div class="field">
        <label class="label" for="certificationVerificationName">{{ $t('general.name') }}</label>
        <div class="control">
          <input
            id="certificationVerificationName"
            v-model="formData.name"
            data-testid="certificationVerificationNameInput"
            type="text"
            class="input"
            required
          />
        </div>
      </div>

      <div class="field">
        <label class="label" for="certificationVerificationStatus">{{
          $t('general.status')
        }}</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select
              id="certificationVerificationStatus"
              v-model="formData.status"
              data-testid="certificationVerificationStatusInput"
              class="input"
              required
            >
              <option
                v-for="(status, index) in certificationVerificationStatuses"
                :key="index"
                :value="status.value"
              >
                {{ status.text }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label" for="certificationVerificationNote">{{
          $t('general.internalNote')
        }}</label>
        <div class="control">
          <textarea
            id="certificationVerificationNote"
            v-model="formData.note"
            data-testid="certificationVerificationNoteInput"
            rows="5"
            class="textarea"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <button type="button" class="button" data-testid="cancelButton" @click="onCancel()">
        {{ $t('general.cancel') }}
      </button>
      <button type="submit" class="button is-primary" data-testid="upcreateButton">
        {{ isUpdating ? $t('general.update') : $t('general.create') }}
      </button>
    </template>
  </baf-modal>
</template>

<style scoped lang="scss"></style>
