import { models, queries, utils } from 'baf-shared';
import { articleIdbService } from '../indexed-db/article-idb-service';
import { isFailedToFetchErrorOrThrow } from '../standard/failed-to-fetch-service';
import { appHttpService } from '../standard/http-service';

class ArticleService {
  async getAll(query?: queries.GetArticlesQuery): Promise<models.Article[]> {
    try {
      const articles = await appHttpService.request
        .url('/articles')
        .query({
          ...query,
        })
        .get()
        .json<models.Article[]>();

      articleIdbService.putAll(articles);
      return articles;
    } catch (error) {
      isFailedToFetchErrorOrThrow(error);
      let articles = await articleIdbService.getAll();
      if (query?.type) {
        articles = articles.filter((article) => article.type === query.type);
      }
      if (query?.category) {
        const categories = utils.type.isArray(query?.category)
          ? query?.category
          : [query?.category];

        articles = articles.filter((article) =>
          article.categories.some((category) => categories.includes(category)),
        );
      }

      return articles;
    }
  }
}

export const articleService = new ArticleService();
