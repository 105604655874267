<script setup lang="ts">
import { models, utils } from 'baf-shared';
import { ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import BafModal from '../../standard/modal/baf-modal.vue';

type FormData = models.UpcreateCertificationRow & {
  data: models.ExtinguishingSystem & { note?: string };
};

const { t } = useI18n();
const modal = ref<InstanceType<typeof BafModal>>();

const emit = defineEmits<{
  (e: 'upcreate', value: models.UpcreateCertificationRow): void;
}>();

const props = withDefaults(
  defineProps<{
    rows?: models.UpcreateCertificationRow[];
    certificationRow?: models.UpcreateCertificationRow;
  }>(),
  {
    rows: () => [],
    certificationRow: undefined,
  },
);
const { rows, certificationRow } = toRefs(props);
watch(certificationRow, () => {
  formData.value = createFormData(certificationRow.value);
});

const isUpdating = !!certificationRow.value;
const formData = ref(createFormData(certificationRow.value));
const titleAction = isUpdating
  ? t('general.certificationRowUpdate')
  : t('general.certificationRowCreate');
const title = `${titleAction} - ${t('general.enums.certificationType.ExtinguishingSystem')}`;

function createFormData(row?: models.UpcreateCertificationRow): FormData {
  const data = utils.json.copy(row?.data ?? {}) as models.ExtinguishingSystem;
  const rowNumber = utils.number.getNext10thIncrement(rows.value.length * 10);

  return {
    rowNumber: row?.rowNumber ?? rowNumber,
    data: { ...data, note: data.note ?? undefined },
    deleted: row?.deleted ?? false,
    hidden: row?.hidden ?? false,
  };
}

async function onCancel() {
  formData.value = createFormData(certificationRow.value);
  modal.value?.close();
}

async function onSubmit() {
  emit('upcreate', formData.value);
  formData.value = createFormData();
  modal.value?.close();
}
</script>

<template>
  <baf-modal ref="modal" :title="title" tag="form" @submit.prevent="onSubmit">
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <div class="field">
        <label class="label" for="extinguishingSystemRowNumber">{{
          $t('general.rowNumber')
        }}</label>
        <div class="control">
          <input
            id="extinguishingSystemRowNumber"
            v-model="formData.rowNumber"
            data-testid="extinguishingSystemRowNumberInput"
            type="number"
            class="input"
          />
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label" for="extinguishingSystemBottleManufacturer">{{
              $t('general.bottleManufacturer')
            }}</label>
            <div class="control">
              <input
                id="extinguishingSystemBottleManufacturer"
                v-model="formData.data.bottleManufacturer"
                data-testid="extinguishingSystemBottleManufacturerInput"
                type="text"
                class="input"
                required
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label" for="extinguishingSystemManufacturingNumber">{{
              $t('general.manufacturingNumber')
            }}</label>
            <div class="control">
              <input
                id="extinguishingSystemManufacturingNumber"
                v-model="formData.data.manufacturingNumber"
                data-testid="extinguishingSystemManufacturingNumberInput"
                type="text"
                class="input"
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label" for="extinguishingSystemGas">{{ $t('general.gas') }}</label>
            <div class="control">
              <input
                id="extinguishingSystemGas"
                v-model="formData.data.gas"
                data-testid="extinguishingSystemGasInput"
                type="text"
                class="input"
                required
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label" for="extinguishingSystemVolume">{{
              $t('general.volumeLiter')
            }}</label>
            <div class="control">
              <input
                id="extinguishingSystemVolume"
                v-model="formData.data.volume"
                data-testid="extinguishingSystemVolumeInput"
                type="text"
                class="input"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label" for="extinguishingSystemEmptyWeight">{{
              $t('general.emptyWeight')
            }}</label>
            <div class="control">
              <input
                id="extinguishingSystemEmptyWeight"
                v-model="formData.data.emptyWeight"
                data-testid="extinguishingSystemEmptyWeightInput"
                type="text"
                class="input"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label" for="extinguishingSystemFilledWeight">{{
              $t('general.filledWeight')
            }}</label>
            <div class="control">
              <input
                id="extinguishingSystemFilledWeight"
                v-model="formData.data.filledWeight"
                data-testid="extinguishingSystemFilledWeightInput"
                type="text"
                class="input"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label" for="extinguishingSystemFilledPressure">{{
              $t('general.filledPressure')
            }}</label>
            <div class="control">
              <input
                id="extinguishingSystemFilledPressure"
                v-model="formData.data.filledPressure"
                data-testid="extinguishingSystemFilledPressureInput"
                type="text"
                class="input"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label" for="extinguishingSystemPressureTest">{{
              $t('general.pressureTest')
            }}</label>
            <div class="control">
              <input
                id="extinguishingSystemPressureTest"
                v-model="formData.data.pressureTest"
                data-testid="extinguishingSystemPressureTestInput"
                type="text"
                class="input"
                :placeholder="$t('general.monthYearPlaceholder')"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label" for="extinguishingSystemNote">{{ $t('general.note') }}</label>
        <div class="control">
          <textarea
            id="extinguishingSystemNote"
            v-model="formData.data.note"
            data-testid="extinguishingSystemNoteInput"
            rows="5"
            class="textarea"
          ></textarea>
        </div>
      </div>
    </template>

    <template #footer>
      <button type="button" class="button" data-testid="cancelButton" @click="onCancel()">
        {{ $t('general.cancel') }}
      </button>
      <button type="submit" class="button is-primary" data-testid="upcreateButton">
        {{ isUpdating ? $t('general.update') : $t('general.create') }}
      </button>
    </template>
  </baf-modal>
</template>
