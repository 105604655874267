<script setup lang="ts">
import ServiceOrderUpdateEmployeeModal from '@/components/service-order/service-order-update-employee-modal.vue';
import { useAuth } from '@/composeables/use-auth';
import { useLoading } from '@/composeables/use-loading';
import { RouteName } from '@/router/models/route-name';
import { router } from '@/router/router';
import { serviceOrderService } from '@/services/service-order/service-order-service';
import { settingsService } from '@/services/settings/settings-service';
import { models, sortBy, utils } from 'baf-shared';
import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import BafTable from '../standard/table/baf-table.vue';
import {
  TableColumn,
  type TableColumnSelectOption,
  type TableRow,
} from '../standard/table/models/table';
import ServiceOrderUpdateLabelsModal from './service-order-update-labels-modal.vue';
import ConfirmModal from '@/components/modal/confirm-modal.vue';
import { uniqueArrayByKey } from 'baf-shared/dist/utils';

const { t } = useI18n();
const route = useRoute();
const { loading, startLoading, stopLoading } = useLoading();
const serviceOrderTable = ref();
const lastVisitedServiceOrderId = ref(settingsService.getSettings('lastVisitedServiceOrderId'));

const data = ref<models.ListServiceOrder[]>([]);
const serviceOrderLabelSelectOptions = ref<TableColumnSelectOption[]>();

const { isAdmin } = useAuth();

const allowedServiceOrderStatusesForServiceTechnicians = [
  models.ServiceOrderStatus.Received,
  models.ServiceOrderStatus.Accepted,
  models.ServiceOrderStatus.InProgress,
  models.ServiceOrderStatus.Finished,
];

const serviceOrderStatusSelectOptions = Object.values(models.ServiceOrderStatus)
  .filter(
    (value) => isAdmin.value || allowedServiceOrderStatusesForServiceTechnicians.includes(value),
  )
  .map((value) => {
    const translated = t(`general.enums.serviceOrderStatus.${value}`);
    return {
      value: translated,
      text: translated,
    } as TableColumnSelectOption;
  });

const columns = computed(() => [
  TableColumn.for('userActions', '', { search: false, sort: false }),
  TableColumn.for('status', t('general.status'), {
    select: true,
    selectOptions: serviceOrderStatusSelectOptions,
    renderer: (value, row) => {
      const serviceOrder = row as models.ServiceOrder;
      const translatedStatus = t(`general.enums.serviceOrderStatus.${String(value)}`);
      const statusReason = serviceOrder.statusReason ? `(${serviceOrder.statusReason})` : undefined;
      return utils.joinValues([translatedStatus, statusReason]);
    },
  }),
  TableColumn.for('customer', t('general.customer'), {
    renderer: (value) => (value as models.ListServiceOrder['customer']).name,
  }),
  TableColumn.for('address', t('general.address'), {
    renderer: (value, _row) => {
      const row = _row as unknown as models.ListServiceOrder;
      return utils.joinValues([row.address, row.postCode], ', ') ?? '-';
    },
  }),
  TableColumn.for('deliveryAddress', t('general.deliveryAddress'), {
    renderer: (value, _row) => {
      const row = _row as unknown as models.ListServiceOrder;
      return utils.joinValues([row.deliveryAddress, row.deliveryPostCode], ', ') ?? '-';
    },
  }),
  TableColumn.for('lastService', t('general.lastService'), {
    renderer: (value) => utils.date.formatDate(value as string),
  }),
  TableColumn.for('warehouse', t('general.warehouse'), {
    charactersWidth: 16,
  }),
  TableColumn.for('employee', t('general.employee'), {
    renderer: (value) => {
      const employee = utils.type.asType<models.Employee>(value);
      return employee?.fullName ?? '';
    },
  }),
  TableColumn.for('labels', t('general.labels'), {
    select: true,
    selectOptions: serviceOrderLabelSelectOptions.value,
    renderer: (value) => {
      const labels = value as unknown as models.ServiceOrder['labels'];
      return utils.joinValues(
        labels.map((label) => label.description),
        ', ',
      );
    },
  }),
  TableColumn.for('connectedServiceOrderRows', t('general.serviceOrderRows'), {
    charactersWidth: 26,
    right: true,
  }),
]);

async function onClickRow(row: models.ListServiceOrder | TableRow) {
  const serviceOrder = row as models.ListServiceOrder;

  settingsService.setSettings({ lastVisitedServiceOrderId: serviceOrder.id });
  await router.push({
    name: RouteName.ServiceOrdersServiceOrder,
    params: {
      id: serviceOrder.id,
    },
  });
}

function onUpdateServiceOrder() {
  loadServiceOrders();
}

function onCopyServiceOrders(serviceOrderIds: string[]) {
  return async () => {
    try {
      startLoading();
      await serviceOrderService.copyServiceOrders(serviceOrderIds);
      await loadServiceOrders();
    } finally {
      stopLoading();
    }
  };
}

function getServiceOrderLabelSelectOptions() {
  const labels = data?.value
    ?.map((serviceOrder) =>
      serviceOrder.labels.map((label) => ({ value: label.description, text: label.description })),
    )
    ?.flat();

  const uniqueLabels = uniqueArrayByKey(labels, 'text');
  return sortBy(uniqueLabels, 'text');
}
async function loadServiceOrders() {
  try {
    startLoading();
    data.value = await serviceOrderService.getAll();
    serviceOrderService.syncServiceOrdersForOfflineUsage(data.value);
    serviceOrderLabelSelectOptions.value = getServiceOrderLabelSelectOptions();
  } finally {
    stopLoading();
  }
}

const lastVisitedServiceOrderRowClassName = 'has-text-weight-bold';

function scrollToLastVisitedServiceOrder() {
  const tableRows = (serviceOrderTable.value?.tableRows ?? []) as HTMLTableRowElement[];
  const lastVisitedServiceOrder = tableRows.find(
    (tableRow) => tableRow.className === lastVisitedServiceOrderRowClassName,
  );
  if (lastVisitedServiceOrder) {
    lastVisitedServiceOrder.scrollIntoView();
  }
}

onBeforeMount(async () => {
  await loadServiceOrders();
  scrollToLastVisitedServiceOrder();
});
</script>

<template>
  <baf-table
    ref="serviceOrderTable"
    :columns="columns"
    :rows="data"
    :row-class="{
      [lastVisitedServiceOrderRowClassName]: (row) => row.id === lastVisitedServiceOrderId,
    }"
    :loading="loading"
    :sort-default="{ key: 'updated', asc: false }"
    :search-query="route.query"
    search
    paginate
    hover
    :checkbox="isAdmin"
    actions
    max-actions-height="80vh"
    persist-state="serviceOrderTable"
  >
    <template #actions="{ rowsChecked }">
      <div v-if="rowsChecked.length > 0" class="tags">
        <service-order-update-employee-modal
          v-if="isAdmin"
          v-slot="{ open }"
          :service-order="rowsChecked[0]"
          :service-order-ids="rowsChecked.map((row) => row.id)"
          @update="onUpdateServiceOrder"
        >
          <a class="tag is-link is-medium" data-testid="updateEmployeeTag" @click.stop="open">
            <iconify-icon icon="mdi:edit"></iconify-icon>
            <span class="ml-2">{{ $t('general.employee') }}</span>
          </a>
        </service-order-update-employee-modal>

        <service-order-update-labels-modal
          v-slot="{ open }"
          :service-order="rowsChecked[0]"
          :service-order-ids="rowsChecked.map((row) => row.id)"
          @update="onUpdateServiceOrder"
        >
          <a class="tag is-link is-medium" @click.stop="open">
            <iconify-icon icon="mdi:edit"></iconify-icon>
            <span class="ml-2">{{ $t('general.labels') }}</span>
          </a>
        </service-order-update-labels-modal>

        <confirm-modal
          v-if="isAdmin"
          :confirm-callback="onCopyServiceOrders(rowsChecked.map((row) => row.id))"
          :message="$t('general.copyServiceOrdersConfirm', rowsChecked.length)"
          #="{ open }"
        >
          <a class="tag is-link is-medium" data-testid="copyServiceOrderTag" @click.stop="open">
            <iconify-icon icon="mdi:content-copy"></iconify-icon>
            <span class="ml-2">
              {{ $t('general.copy') }}
            </span>
          </a>
        </confirm-modal>
      </div>
    </template>

    <template #column-userActions="{ row }">
      <router-link
        class="tag is-primary is-medium"
        :to="{ name: RouteName.ServiceOrdersServiceOrder, params: { id: row.id } }"
        @click.stop="onClickRow(row)"
      >
        <iconify-icon icon="mdi:eye"></iconify-icon>
        <span class="ml-2">{{ $t('general.details') }}</span>
      </router-link>
    </template>
  </baf-table>
</template>
