<script setup lang="ts" generic="T extends Record<string, unknown>">
import { useOpenable } from '@/composeables/use-openable';
import { onClickOutside, useElementBounding } from '@vueuse/core';
import { type Primitive, sortBy, utils } from 'baf-shared';
import { computed, onBeforeMount, ref, type StyleValue, toRefs, useAttrs, watch } from 'vue';
import { useI18n } from 'vue-i18n';

defineOptions({
  inheritAttrs: false,
});

const attrs = useAttrs();
const { t } = useI18n();

const valueForPath = (value: T, path: string) => {
  return (
    path
      .split('.')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((result, part) => (result && result?.[part]) ?? undefined, value as any)
  );
};

const rootElement = ref<HTMLElement>();

const searchInput = ref<HTMLInputElement>();
const searchInputBinding = useElementBounding(searchInput);

const props = withDefaults(
  defineProps<{
    values: T[];
    textKey?: string;
    valueKey: string;
  }>(),
  {
    textKey: undefined,
    values: () => [],
  },
);
const { values, textKey, valueKey } = toRefs(props);

const vModel = defineModel<T[] | Primitive[] | T | Primitive>();
watch(vModel, () => {
  focused.value = false;
  loadSearchQuery();
  close();
});

const focused = ref(false);
const searchQuery = ref('');
const oldSearchQuery = ref('');

const { openValue, open, close, elementClass } = useOpenable();

const textOrValueKey = computed(() => textKey.value || valueKey.value);
const menuStyle = computed(() => {
  if (!searchInput.value) {
    return {};
  }

  const top = searchInputBinding.top.value + searchInput.value.clientHeight;
  const left = searchInputBinding.left.value;
  const width = searchInputBinding.width.value;

  return {
    position: 'fixed',
    top: `${top}px`,
    left: `${left}px`,
    width: `${width}px`,
  } as StyleValue;
});

const vModelArray = computed(() => {
  const safeVModelValue = vModel.value ?? [];
  return utils.type.isArray(safeVModelValue) ? safeVModelValue : [safeVModelValue];
});
const vModelStringified = computed(() => {
  return vModelArray.value
    .map((value) =>
      utils.type.isObject(value) ? valueForPath(value, textOrValueKey.value) : value,
    )
    .join(', ');
});
const isMultiSelect = computed(() => utils.type.isArray(vModel.value ?? []));

const $values = computed(() => {
  const MAX_LENGTH = 128;

  const sorted = sortBy<T>(values.value, textOrValueKey.value);
  if (!searchQuery.value || !focused.value) {
    return sorted.slice(0, MAX_LENGTH);
  }

  const filtered = sorted.filter((value) =>
    String(valueForPath(value, textOrValueKey.value))
      .toLowerCase()
      .includes(searchQuery.value.toLowerCase()),
  );

  return filtered.slice(0, MAX_LENGTH);
});
const isValuesEmpty = computed(() => $values.value.length === 0);

const searchInputClass = computed(() => {
  return {
    'is-info': focused.value,
  };
});
const searchInputType = computed(() => (focused.value ? 'search' : 'text'));
const searchInputPlaceholder = computed(() => {
  if (vModelArray.value.length === 0) {
    return t('general.noValueSelected');
  } else {
    return vModelStringified.value;
  }
});

const itemIdPrefix = computed(() => attrs.id ?? 'search-select');
function getItemId(value: T) {
  return `${itemIdPrefix.value}-item-${valueForPath(value, valueKey.value)}`;
}

function onInput() {
  oldSearchQuery.value = searchQuery.value;
}
async function onFocus() {
  focused.value = true;
  searchQuery.value = oldSearchQuery.value;

  open();
}
async function onBlur() {
  loadSearchQuery();
}
function onChangeItem() {
  if (!isMultiSelect.value) {
    focused.value = false;
    loadSearchQuery();
    close();
  }
}

function loadSearchQuery() {
  if (focused.value) {
    searchQuery.value = oldSearchQuery.value;
  } else {
    searchQuery.value = vModelStringified.value ?? '';
  }
}

function isLastItem(index: number): boolean {
  return $values.value.length - 1 === index;
}

onClickOutside(rootElement, () => {
  focused.value = false;
  loadSearchQuery();
  close();
});

onBeforeMount(() => {
  loadSearchQuery();
});
</script>

<template>
  <div ref="rootElement" class="dropdown search-select" :class="elementClass">
    <div class="dropdown-trigger">
      <input
        v-bind="attrs"
        ref="searchInput"
        v-model="searchQuery"
        class="input is-fullwidth"
        aria-controls="dropdown-menu"
        :data-testid="attrs['data-testid'] ?? 'searchInput'"
        :aria-haspopup="openValue"
        :type="searchInputType"
        :placeholder="searchInputPlaceholder"
        :class="searchInputClass"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
      />
    </div>

    <div class="dropdown-menu search-select-dropdown-menu" role="menu" :style="menuStyle">
      <div class="dropdown-content">
        <template v-for="(value, index) in $values" :key="getItemId(value)">
          <a class="dropdown-item search-item py-0" data-testid="searchItem">
            <label class="checkbox py-3" :for="getItemId(value)">
              <input
                :id="getItemId(value)"
                v-model="vModel"
                :data-testid="getItemId(value)"
                type="checkbox"
                :value="value"
                :true-value="value"
                :false-value="undefined"
                @change="onChangeItem"
              />
              <span>{{ valueForPath(value, textOrValueKey) }}</span>
            </label>
          </a>

          <hr v-if="!isLastItem(index)" class="dropdown-divider my-0" />
        </template>

        <div v-if="isValuesEmpty" class="dropdown-item search-item">
          <span>{{ $t('general.noMatchesFound') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown,
.dropdown-trigger {
  width: 100%;
}

.dropdown {
  &-content {
    max-height: 20em;
    overflow-y: auto;
  }

  &-content {
    .search-item {
      .checkbox {
        width: 100%;

        input {
          margin-right: 1em;
        }
      }
    }
  }
}
</style>
