import App from '@/app.vue';
import '@/assets/scss/main.scss';
import { router } from '@/router/router';
import { customerIdbService } from '@/services/indexed-db/customer-idb-service';
import {
  employeeIdbService,
  listEmployeeIdbService,
} from '@/services/indexed-db/employee-idb-service';
import { labelIdbService } from '@/services/indexed-db/label-idb-service';
import 'iconify-icon';
import { createApp } from 'vue';
import { config } from './config';
import { i18n } from './i18n/i18n';
import './offline';
import { authService } from './services/auth/auth-service';
import { articleIdbService } from './services/indexed-db/article-idb-service';
import { requestIdbService } from './services/indexed-db/request-idb-service';
import {
  listServiceOrderIdbService,
  serviceOrderIdbService,
} from './services/indexed-db/service-order-idb-service';
import { seedService } from './services/seed/seed-service';
import { settingsService } from '@/services/settings/settings-service';
import { articleService } from '@/services/article/article-service';
import { employeeService } from '@/services/employee/employee-service';
import { customerService } from '@/services/customer/customer-service';
import { utils } from 'baf-shared';
import { onlineService } from '@/services/online/online-service';

async function initIdbServices() {
  await articleIdbService.init();

  await customerIdbService.init();

  await employeeIdbService.init();
  await listEmployeeIdbService.init();

  await labelIdbService.init();

  await serviceOrderIdbService.init();
  await listServiceOrderIdbService.init();

  await requestIdbService.init();
}

async function syncEntitiesForOfflineUsage() {
  const lastSync = settingsService.getSettings('lastSync');
  const now = new Date();
  if ((!lastSync || utils.date.isBeforeDate(lastSync, now)) && onlineService.online) {
    await Promise.all([
      listServiceOrderIdbService.deleteAll(),
      serviceOrderIdbService.deleteAll(),
      articleIdbService.deleteAll(),
      employeeIdbService.deleteAll(),
      customerIdbService.deleteAll(),
    ]);

    await Promise.all([
      articleService.getAll(),
      employeeService.getAll(),
      customerService.getAll(),
    ]);

    settingsService.setSettings({ lastSync: now });
  }
}

function delayedBootstrap() {
  const DELAY_IN_MS = 1000;

  setTimeout(async () => {
    await seedService.run();
    await syncEntitiesForOfflineUsage();
  }, DELAY_IN_MS);
}

async function bootstrap() {
  const app = createApp(App);

  app.use(i18n);
  app.use(router);

  if (!config.FORTNOX_AUTH_URL || !config.FORTNOX_CLIENT_ID) {
    console.warn('The app is missing config, most features will not work as intended!');
  }

  await initIdbServices();

  app.mount('#app');
  await authService.handleRedirect();
  await authService.handleRefreshToken();
  await authService.handleMeState();

  delayedBootstrap();
}

bootstrap();
