import { createDataFunction } from '../../functions/index.js';
import { Access } from '../../models/index.js';
export const createMe = createDataFunction({
    id: '45cd69c1-7167-4cd9-bda2-0aad80928b35',
    employeeId: '46cd69c1-7167-4cd9-bda2-0aad80928b36',
    fortnoxId: '860628',
    name: 'Nisse',
    email: 'nisse@email.se',
    created: '2023-08-14T04:13:11.472Z',
    updated: '2024-02-20T07:05:46.776Z',
    access: [
        Access.Admin,
        Access.ServiceOrders,
        Access.Certificates,
        Access.PressureTests,
        Access.Register,
    ],
});
