<script setup lang="ts" generic="TModel extends Nullish<string | number | symbol | object>">
import { models, type Nullish } from 'baf-shared';
import { ref } from 'vue';
import BafField from '@/components/standard/form/baf-field.vue';
import { useElementHover, useFocus } from '@vueuse/core';
import { pressureTestService } from '@/services/pressure-test/pressure-test-service';
import { useLoading } from '@/composeables/use-loading';

const { loadingClass, startLoading, stopLoading } = useLoading();

const emit = defineEmits<{
  (e: 'click-row', pressureTest: models.PressureTest): void;
}>();

const searchInputDropdown = ref();
const isSearchInputDropdownHovered = useElementHover(searchInputDropdown);

const searchInput = ref();
const { focused: searchInputFocused } = useFocus(searchInput);

const search = ref('');
const searchPressureTest = ref<models.PressureTest[]>([]);

let timeout = 0;
async function onSearch() {
  startLoading();
  window.clearTimeout(timeout);
  timeout = window.setTimeout(async () => {
    if (search.value === '') {
      searchPressureTest.value = [];
    } else {
      searchPressureTest.value = await pressureTestService.search({ search: search.value });
    }
    stopLoading();
  }, 500);
}

function onSearchClick(value: models.PressureTest) {
  search.value = '';
  searchPressureTest.value = [];
  emit('click-row', value);
}
</script>

<template>
  <div class="dropdown is-active">
    <div ref="searchInputDropdown" class="dropdown-trigger">
      <baf-field>
        <div class="control is-medium" :class="loadingClass">
          <input
            ref="searchInput"
            v-model="search"
            class="input"
            type="text"
            inputmode="text"
            :placeholder="`${$t('general.search')} ${$t('general.serialNumber').toLowerCase()}`"
            @update:model-value="onSearch"
          />
          <div
            v-if="
              (searchInputFocused || isSearchInputDropdownHovered) && searchPressureTest.length > 0
            "
            id="dropdown-menu"
            class="dropdown-menu pressure-test-menu"
            role="menu"
          >
            <div class="dropdown-content">
              <a
                v-for="pressureTest in searchPressureTest"
                :key="pressureTest.id"
                class="dropdown-item nowrap"
                @click.stop.prevent="onSearchClick(pressureTest)"
              >
                <strong> {{ pressureTest.customer.name }}, {{ pressureTest.asset.name }} </strong>:
                <br />
                {{ pressureTest.manufacturer }} {{ pressureTest.gas }}
                {{ pressureTest.serialNumber }}
              </a>
            </div>
          </div>
        </div>
      </baf-field>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pressure-test-menu {
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid lightgray;
  border-radius: 0.25rem;
}
.nowrap {
  white-space: nowrap;
}
</style>
