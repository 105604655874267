import { config } from '@/config';
import { i18n } from '@/i18n/i18n';
import { logger } from '@/utils/logger-utils';
import { ErrorSource, HttpService, HttpStatus } from 'baf-shared';
import type { FetchLike, WretchOptions } from 'wretch';
import type { QueryStringAddon } from 'wretch/addons';
import type { WretchError } from 'wretch/resolver';
import type { Wretch } from 'wretch/types';
import { appService } from '../app/app-service';
import { authService } from '../auth/auth-service';
import { notificationService } from '../notification/notification-service';
import { FailedToFetchError, failedToFetchService } from './failed-to-fetch-service';

let CHECK_ACCESS_TOKEN_INTERVAL_LOOPS = 0;

type WretchRequest = Wretch<QueryStringAddon, unknown, undefined> & QueryStringAddon;

enum ErrorMessage {
  FailedToFetch = 'Failed to fetch',
  FailedToFetchSafari = 'fetch failed',
}

function authorizationHeader(next: FetchLike) {
  return (url: string, options: WretchOptions) => {
    if (authService.accessToken) {
      options.headers.authorization = `Bearer ${authService.accessToken}`;
    }

    return next(url, options);
  };
}

async function unauthorizedCallback() {
  const CHECK_ACCESS_TOKEN_INTERVAL_IN_MS = 250;
  const MAX_INTERVAL_LOOPS = 3;

  if (authService.refreshToken) {
    try {
      await authService.refreshAccessToken({ forceRefresh: true });

      const checkAccessTokenInterval = setInterval(() => {
        CHECK_ACCESS_TOKEN_INTERVAL_LOOPS++;

        if (CHECK_ACCESS_TOKEN_INTERVAL_LOOPS >= MAX_INTERVAL_LOOPS) {
          clearInterval(checkAccessTokenInterval);
          return;
        }

        if (authService.accessToken) {
          clearInterval(checkAccessTokenInterval);
          appService.reload();
          return;
        }
      }, CHECK_ACCESS_TOKEN_INTERVAL_IN_MS);
    } catch (error) {
      authService.logout();
    }

    return;
  }

  authService.logout();
  return;
}

const { t } = i18n.global;
function badRequestCallback(error: WretchError) {
  const { source, message, slug } = error.json ?? {};
  const sources = [ErrorSource.Fortnox, ErrorSource.Server];

  if (source && sources.includes(source) && message) {
    const actualMessage = slug ? t(`general.enums.bafErrorSlug.${slug}`) : message;

    notificationService.open({
      text: `${source}: ${actualMessage}`,
      type: 'warning',
    });
  }

  throw error;
}

async function catcherFallbackCallback(error: WretchError, originalRequest: WretchRequest) {
  const url = originalRequest._url;
  logger.info(
    `Failed to send request with message: ${error.message ?? 'Unknown error'} on url: ${url}.`,
  );
  if (
    error.message === ErrorMessage.FailedToFetch ||
    error.message.includes(ErrorMessage.FailedToFetch) ||
    error.message === ErrorMessage.FailedToFetchSafari ||
    error.message.includes(ErrorMessage.FailedToFetchSafari)
  ) {
    const id = await failedToFetchService.add(originalRequest);
    throw new FailedToFetchError(url, id);
  }

  throw error;
}

class AppHttpService {
  http: HttpService;

  constructor() {
    this.http = new HttpService(`${config.BACKEND_URL}/api`)
      .middlewares(authorizationHeader)
      .catcher(HttpStatus.Unauthorized, unauthorizedCallback)
      .catcher(HttpStatus.BadRequest, badRequestCallback)
      .catcherFallback(catcherFallbackCallback);
  }

  get request(): typeof this.http.request {
    return this.http.request;
  }
}

class AuthHttpService {
  http: HttpService;

  constructor() {
    this.http = new HttpService(`${config.BACKEND_URL}/api`)
      .middlewares(authorizationHeader)
      .catcherFallback(catcherFallbackCallback);
  }

  get request(): typeof this.http.request {
    return this.http.request;
  }
}

export const appHttpService = new AppHttpService();
export const authHttpService = new AuthHttpService();
