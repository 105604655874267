<script setup lang="ts">
import { type models } from 'baf-shared';
import { toRefs } from 'vue';
import TitleValueRoot from '@/components/standard/title-value/title-value-root.vue';
import TitleValue from '@/components/standard/title-value/title-value.vue';

const props = defineProps<{
  certification: models.Certification;
}>();
const { certification } = toRefs(props);
</script>

<template>
  <div class="columns">
    <div class="column is-4">
      <title-value-root>
        <title-value
          :title="$t('general.extinguishingSystemType')"
          :value="certification?.extinguishingSystemType"
          block
        >
        </title-value>
      </title-value-root>
    </div>
    <div class="column is-4">
      <title-value-root>
        <title-value
          :title="$t('general.protectedArea')"
          :value="certification?.extinguishingSystemProtectedArea"
          block
        >
        </title-value>
      </title-value-root>
    </div>
  </div>
</template>
