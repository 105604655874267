import { isDefined } from '../functions/is-defined.js';
const LOCALE = 'sv';
export const formatDateTime = (value) => {
    if (!value)
        return '';
    return new Date(value).toLocaleString(LOCALE);
};
export const formatDate = (value) => {
    if (!value)
        return '';
    return new Date(value).toLocaleDateString(LOCALE);
};
export const formatTime = (value) => {
    if (!value)
        return '';
    return new Date(value).toLocaleTimeString(LOCALE);
};
export const changeDate = ({ date, unit, delta }) => {
    if (!isDefined(date)) {
        return date;
    }
    const value = date instanceof Date ? new Date(date.getTime()) : new Date(date);
    if (unit === 'seconds') {
        value.setSeconds(value.getSeconds() + delta);
    }
    if (unit === 'minutes') {
        value.setMinutes(value.getMinutes() + delta);
    }
    if (unit === 'months') {
        value.setMonth(value.getMonth() + delta);
    }
    if (unit === 'years') {
        value.setFullYear(value.getFullYear() + delta);
    }
    return value;
};
export const formatDateTimeLocal = (value) => {
    if (!isDefined(value)) {
        return value;
    }
    const date = value instanceof Date ? new Date(value.getTime()) : new Date(value);
    return date.toLocaleString(LOCALE).replace(' ', 'T').slice(0, 16);
};
export const formatDateTimeISO = (value) => {
    if (!isDefined(value)) {
        return value;
    }
    const date = value instanceof Date ? new Date(value.getTime()) : new Date(value);
    return date.toISOString();
};
export const nowInISO = () => new Date().toISOString();
export const nowInLocale = () => new Date().toLocaleString(LOCALE);
export const isBefore = (date1, date2) => {
    if (!isDefined(date1) || !isDefined(date2)) {
        return false;
    }
    return new Date(date1) < new Date(date2);
};
export const isBeforeDate = (date1, date2) => {
    if (!isDefined(date1) || !isDefined(date2)) {
        return false;
    }
    return new Date(formatDate(date1)) < new Date(formatDate(date2));
};
export const isAfter = (date1, date2) => {
    if (!isDefined(date1) || !isDefined(date2)) {
        return false;
    }
    return new Date(date1) > new Date(date2);
};
