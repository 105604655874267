<script setup lang="ts">
import PrintCertificationExtinguishingSystem from '@/components/print/certification/print-certification-extinguishing-system.vue';
import PrintCertificationExtinguishingSystemInfo from '@/components/print/certification/print-certification-extinguishing-system-info.vue';
import PrintCertificationFireAlarm from '@/components/print/certification/print-certification-fire-alarm.vue';
import PrintCertificationVerifications from '@/components/print/certification/print-certification-verifications.vue';
import PrintPage from '@/components/print/standard/print-page.vue';
import PrintUnderline from '@/components/print/standard/print-underline.vue';
import TitleValueRoot from '@/components/standard/title-value/title-value-root.vue';
import TitleValue from '@/components/standard/title-value/title-value.vue';
import { models, sortBy, utils } from 'baf-shared';
import { type Component, computed, toRefs } from 'vue';
import PrintCertificationFireExtinguisher from '@/components/print/certification/print-certification-fire-extinguisher.vue';
import PrintCertificationSuitLifeVest from '@/components/print/certification/print-certification-suit-life-vest.vue';
import PrintCertificationNote from '@/components/print/certification/print-certification-note.vue';
import PrintSpacer from '@/components/print/standard/print-spacer.vue';
import { DynamicPrintPages } from '@/components/print/dynamic-print/dynamic-print-pages';
import type { PrintOrientation } from '@/models/print-orientation';

const PrintHeight = Object.freeze({
  PrintCertificationExtinguishingSystemInfo: 65,
  PrintCertificationFireAlarm: 100,
  PrintSpacer: 20,

  BafTableAddNewPageBreakpoint: 100,
  BafTableHeader: 36,
  BafTableHeaderMultiline: 62,
  BafTableRow: 36,

  VerificationsAddNewPageBreakpoint: 200,
  VerificationsHeader: 18,
  VerificationsRow: 38,
  VerificationsPerRow: 3,

  Text: 21,
});
const props = defineProps<{
  certification: models.Certification;
  orientation: PrintOrientation;
}>();
const { certification, orientation } = toRefs(props);

const customerPostcodeAndCity = computed(() => {
  const { customer } = certification.value;
  return utils.joinValues([customer?.postCode, customer?.city], ' ');
});

const customerAddress = computed(() => {
  const { customer } = certification.value;
  return utils.joinValues(
    [customer?.name, customer?.address1, customer?.address2, customerPostcodeAndCity.value],
    '\n',
  );
});

const assetContact = computed(() => {
  const { asset } = certification.value;
  return utils.joinValues([asset?.contactName, asset?.contactPhone], '\n');
});

const availableHeightOfOnePageInPx = 600;
const dynamicPrintPages = new DynamicPrintPages(availableHeightOfOnePageInPx);

if (certification.value.type === models.CertificationType.ExtinguishingSystem) {
  dynamicPrintPages.addComponent({
    component: PrintCertificationExtinguishingSystemInfo,
    props: { certification: certification.value },
    height: PrintHeight.PrintCertificationExtinguishingSystemInfo,
  });
}

const rowTypes: { [key in models.CertificationType]?: Component } = {
  [models.CertificationType.ExtinguishingSystem]: PrintCertificationExtinguishingSystem,
  [models.CertificationType.FireExtinguisher]: PrintCertificationFireExtinguisher,
  [models.CertificationType.LifeVest]: PrintCertificationSuitLifeVest,
  [models.CertificationType.Suit]: PrintCertificationSuitLifeVest,
};
if (Object.keys(rowTypes).includes(certification.value.type)) {
  const rows = sortBy(certification.value.rows, 'rowNumber');

  while (rows.length !== 0) {
    const availableHeightOnLastPage = dynamicPrintPages.availableHeightOnLastPage(
      PrintHeight.BafTableAddNewPageBreakpoint,
    );
    const numberOfRowsAbleToFitOnLastPage = Math.floor(
      (availableHeightOnLastPage - PrintHeight.BafTableHeaderMultiline) / PrintHeight.BafTableRow,
    );
    const rowsForPage = rows.splice(0, numberOfRowsAbleToFitOnLastPage);
    dynamicPrintPages.addComponent({
      component: rowTypes[certification.value.type]!,
      props: { certification: { ...certification.value, rows: rowsForPage } },
      height: PrintHeight.BafTableHeaderMultiline + rowsForPage.length * PrintHeight.BafTableRow,
    });
  }

  dynamicPrintPages.addComponent({
    component: PrintSpacer,
    height: PrintHeight.PrintSpacer,
  });
}

if (certification.value.type === models.CertificationType.FireAlarm) {
  dynamicPrintPages.addComponent({
    component: PrintCertificationFireAlarm,
    props: { certification: certification.value },
    height: PrintHeight.PrintCertificationFireAlarm,
  });
  dynamicPrintPages.addComponent({
    component: PrintSpacer,
    height: PrintHeight.PrintSpacer,
  });
}

if (certification.value.verifications.length > 0) {
  const verifications = sortBy(certification.value.verifications, 'rowNumber');
  while (verifications.length !== 0) {
    const availableHeightOnLastPage = dynamicPrintPages.availableHeightOnLastPage(
      PrintHeight.VerificationsAddNewPageBreakpoint,
    );
    const numberOfRowsAbleToFitOnLastPage = Math.floor(
      (availableHeightOnLastPage - PrintHeight.VerificationsHeader) / PrintHeight.VerificationsRow,
    );
    const verificationsForPage = verifications.splice(
      0,
      numberOfRowsAbleToFitOnLastPage * PrintHeight.VerificationsPerRow,
    );
    const verificationRowsHeight =
      (verificationsForPage.length / PrintHeight.VerificationsPerRow) *
      PrintHeight.VerificationsRow;

    dynamicPrintPages.addComponent({
      component: PrintCertificationVerifications,
      props: { verifications: verificationsForPage },
      height: PrintHeight.VerificationsHeader + verificationRowsHeight,
    });
  }
  dynamicPrintPages.addComponent({
    component: PrintSpacer,
    height: PrintHeight.PrintSpacer,
  });
}

const noteHeaderNewline = 1;
const noteNewlines =
  utils.text.getNumberOfNewlinesInText(certification.value?.note) + noteHeaderNewline;

dynamicPrintPages.addComponent({
  component: PrintCertificationNote,
  props: { certification: certification.value },
  height: noteNewlines * PrintHeight.Text,
});
</script>

<template>
  <print-page
    :title="$t('general.certification')"
    data-testid="print-page"
    :orientation="orientation"
  >
    <title-value-root fullwidth narrow data-testid="print-page-information">
      <title-value
        :title="$t('general.customer')"
        :value="certification.customer?.name"
      ></title-value>
      <title-value :title="$t('general.asset')" :value="certification.asset.name"></title-value>
      <title-value
        v-if="certification.type === models.CertificationType.FireAlarm"
        :title="$t('general.certified')"
        :value="certification.fireAlarmName"
      ></title-value>
      <title-value
        v-if="certification.type === models.CertificationType.FireAlarm"
        :title="$t('general.alarmedArea')"
        :value="certification.fireAlarmAlarmedArea"
      ></title-value>

      <title-value
        v-if="certification.type === models.CertificationType.ExtinguishingSystem"
        :title="$t('general.certified')"
        :value="`${certification.service} ${certification.extinguishingSystemType}`"
      ></title-value>
      <title-value
        v-if="certification.type === models.CertificationType.ExtinguishingSystem"
        :title="$t('general.protectedArea')"
        :value="certification.extinguishingSystemProtectedArea"
      ></title-value>

      <title-value
        v-if="
          certification.type === models.CertificationType.FireExtinguisher ||
          certification.type === models.CertificationType.LifeVest ||
          certification.type === models.CertificationType.Suit
        "
        :title="$t('general.certified')"
        :value="certification.service"
      ></title-value>

      <title-value
        title=""
        :value="certification.text"
        default-value=""
        render-as-is
        block
      ></title-value>
    </title-value-root>

    <print-spacer></print-spacer>

    <div>
      <p>{{ $t('components.printCertification.body') }}</p>
    </div>

    <print-spacer></print-spacer>

    <div>
      <p class="mb-0">
        {{ certification.city }} {{ utils.date.formatDate(certification.validFrom) }}
      </p>
      <p class="mb-0">{{ $t('general.legalCompanyName') }}</p>
    </div>

    <print-spacer></print-spacer>
    <div>
      <print-underline :size="40"></print-underline>
      <br />
      <span>{{ certification.employee?.fullName }}</span>
    </div>
  </print-page>

  <template v-for="(page, index) in dynamicPrintPages.pages" :key="index">
    <print-page
      :title="utils.joinValues([$t('general.certificationService'), certification.service], ' - ')"
      title-element="h3"
      :page-number="index + 1"
      data-testid="print-page"
      :orientation="orientation"
    >
      <print-spacer></print-spacer>

      <div class="columns is-multiline">
        <div class="column is-4">
          <title-value-root>
            <title-value
              :title="$t('general.customerNumber')"
              :value="certification?.customer?.fortnoxId"
              block
            >
            </title-value>
          </title-value-root>
        </div>

        <div class="column is-4">
          <title-value-root>
            <title-value
              :title="$t('general.phoneNumber')"
              :value="certification?.customer?.phone"
              block
            >
            </title-value>
          </title-value-root>
        </div>

        <div class="column is-4">
          <title-value-root>
            <title-value
              :title="$t('general.date')"
              :value="utils.date.formatDate(certification?.validFrom)"
              block
            >
            </title-value>
          </title-value-root>
        </div>

        <div class="column is-4">
          <title-value-root>
            <title-value :title="$t('general.customer')" block render-as-is>
              {{ customerAddress }}
            </title-value>
          </title-value-root>
        </div>

        <div class="column is-4">
          <title-value-root>
            <title-value :title="$t('general.contact')" block render-as-is>
              {{ assetContact }}
            </title-value>
          </title-value-root>
        </div>

        <div class="column is-4">
          <title-value-root>
            <title-value
              :title="$t('general.serviceObject')"
              :value="certification?.asset?.name"
              block
            >
            </title-value>
          </title-value-root>
        </div>
      </div>

      <template v-for="(component, componentIndex) in page.components" :key="componentIndex">
        <component :is="component.component" v-bind="component.props"></component>
      </template>

      <template #pre-footer>
        <div class="columns py-0">
          <div class="column py-0">
            <title-value-root>
              <title-value
                class="py-0"
                :title="$t('general.certificationCity')"
                :value="certification?.city"
              >
              </title-value>
            </title-value-root>
          </div>

          <div class="column py-0">
            <title-value-root>
              <title-value
                class="py-0"
                :title="$t('general.inspector')"
                :value="certification?.employee?.fullName"
              >
              </title-value>
            </title-value-root>
          </div>
        </div>
      </template>
    </print-page>
  </template>
</template>

<style lang="scss" scoped>
@page {
  margin: 0;
  size: A4 portrait;
}
</style>
