<script setup lang="ts">
import ExtinguishingSystemUpcreateModal from '@/components/certification-row/extinguishing-system/extinguishing-system-upcreate-modal.vue';
import ConfirmModal from '@/components/modal/confirm-modal.vue';
import BafTable from '@/components/standard/table/baf-table.vue';
import { TableColumn } from '@/components/standard/table/models/table';
import { type models, sortOriginalBy } from 'baf-shared';
import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  rows: models.UpcreateCertificationRow[];
}>();
const { rows } = toRefs(props);

const columns = [
  TableColumn.for('userActions', '', {
    charactersWidth: 1,
    search: false,
    sort: false,
  }),
  TableColumn.for('rowNumber', t('general.rowNumber'), { charactersWidth: 16 }),
  TableColumn.for('data.bottleManufacturer', t('general.bottleManufacturer'), {
    renderer: (value, row) => row.data.bottleManufacturer,
  }),
  TableColumn.for('data.manufacturingNumber', t('general.manufacturingNumber'), {
    renderer: (value, row) => row.data.manufacturingNumber,
  }),
  TableColumn.for('data.gas', t('general.gas'), {
    renderer: (value, row) => row.data.gas,
  }),
  TableColumn.for('data.note', t('general.note'), {
    renderer: (value, row) => row.data.note,
  }),
];

function onDeleteRowCallback(row: models.UpcreateCertificationRow) {
  return () =>
    new Promise<void>((resolve) => {
      const index = rows.value.indexOf(row);
      rows.value.splice(index, 1);
      sortOriginalBy(rows.value, 'rowNumber');
      resolve();
    });
}

function onAddRow(row: models.UpcreateCertificationRow) {
  row.rowNumber = row?.rowNumber ?? (rows.value.length + 1) * 10;
  rows.value.push(row);
  sortOriginalBy(rows.value, 'rowNumber');
}

function onUpdateRow(rowIndex: number, row: models.UpcreateCertificationRow) {
  rows.value.splice(rowIndex, 1, row);
  sortOriginalBy(rows.value, 'rowNumber');
}
</script>

<template>
  <baf-table :columns="columns" :rows="rows" :sort="false" hover>
    <template #column-userActions="{ row, rowIndex }">
      <div class="tags">
        <extinguishing-system-upcreate-modal
          v-slot="{ open }"
          :rows="rows"
          :certification-row="row"
          @upcreate="onUpdateRow(rowIndex, $event)"
        >
          <a class="tag is-link is-medium" data-testid="editButton" @click.stop="open">
            <iconify-icon icon="mdi:edit"></iconify-icon>
            <span class="ml-2">{{ $t('general.edit') }}</span>
          </a>
        </extinguishing-system-upcreate-modal>

        <confirm-modal
          :message="$t('general.deleteConfirm', { value: row.rowNumber })"
          :confirm-callback="onDeleteRowCallback(row)"
          #="{ open }"
        >
          <a class="tag is-danger is-medium" data-testid="deleteButton" @click.stop="open">
            <iconify-icon icon="material-symbols:delete-outline"></iconify-icon>
            <span class="ml-2">{{ $t('general.delete') }}</span>
          </a>
        </confirm-modal>
      </div>
    </template>
  </baf-table>

  <div class="buttons is-right mr-3">
    <extinguishing-system-upcreate-modal v-slot="{ open }" :rows="rows" @upcreate="onAddRow">
      <button type="button" class="button is-success" @click="open">
        <iconify-icon icon="mdi:add-circle"></iconify-icon>
        <span class="ml-2">
          {{ $t('general.add') }}
        </span>
      </button>
    </extinguishing-system-upcreate-modal>
  </div>
</template>
