<script setup lang="ts">
import BafModal from '@/components/standard/modal/baf-modal.vue';
import { useLoading } from '@/composeables/use-loading';
import { models, type Nullish } from 'baf-shared';
import { computed, ref, toRefs } from 'vue';

type FormData = {
  reasonValue?: Nullish<string>;
  otherReasonValue?: Nullish<string>;
};
function createFormData(): FormData {
  return {
    reasonValue: undefined,
    otherReasonValue: undefined,
  };
}

const OTHER_REASON = {
  value: 'Annat',
};

const REASONS_MAP: { [key in models.ServiceOrderStatus]?: { value: string }[] } = {
  [models.ServiceOrderStatus.Resting]: [
    {
      value: 'Svårigheter att komma åt platsen',
    },
    {
      value: 'Bristande åtkomst till utrustning/system',
    },
    {
      value: 'Tidsbrist',
    },
    OTHER_REASON,
  ],
  [models.ServiceOrderStatus.Denied]: [
    {
      value: 'Svårigheter att komma åt platsen',
    },
    {
      value: 'Bristande åtkomst till utrustning/system',
    },
    {
      value: 'Tidsbrist',
    },
    OTHER_REASON,
  ],
};

const modal = ref<InstanceType<typeof BafModal>>();

const { startLoading, stopLoading, loadingClass } = useLoading();

const emit = defineEmits<{
  (e: 'deny', reason: string): void;
}>();

const props = defineProps<{
  status: models.ServiceOrderStatus;
}>();
const { status } = toRefs(props);

const formData = ref(createFormData());
const reasons = computed(() => REASONS_MAP[status.value]);

async function onCancel() {
  formData.value = createFormData();
  modal.value?.close();
}

async function onSubmit() {
  startLoading();
  const { reasonValue, otherReasonValue } = formData.value;
  const reason = reasonValue === OTHER_REASON.value ? otherReasonValue! : reasonValue!;
  formData.value = createFormData();

  emit('deny', reason);
  stopLoading();
  modal.value?.close();
}
</script>

<template>
  <baf-modal ref="modal" :title="$t('general.reason')" tag="form" @submit.prevent="onSubmit">
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <div class="field">
        <p v-if="status === models.ServiceOrderStatus.Resting">
          {{ $t('general.restReasonText') }}
        </p>
        <p v-else-if="status === models.ServiceOrderStatus.Denied">
          {{ $t('general.denyReasonText') }}
        </p>
      </div>

      <div class="field">
        <label for="reason" class="label">{{ $t('general.reason') }}</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select id="reason" v-model="formData.reasonValue" required data-testid="reasonSelect">
              <option v-for="(reason, index) in reasons" :key="index" :value="reason.value">
                {{ reason.value }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div v-if="formData.reasonValue === OTHER_REASON.value" class="field">
        <label for="reasonText" class="label">{{ $t('general.reasonText') }}</label>
        <div class="control">
          <input
            id="reasonText"
            v-model="formData.otherReasonValue"
            required
            maxlength="120"
            type="text"
            class="input"
            data-testid="reasonTextInput"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="button"
        data-testid="reasonCancelButton"
        :class="loadingClass"
        @click="onCancel"
      >
        {{ $t('general.cancel') }}
      </button>

      <button
        v-if="status === models.ServiceOrderStatus.Resting"
        type="submit"
        class="button is-warning"
        :class="loadingClass"
        data-testid="reasonSubmitButton"
      >
        <iconify-icon icon="mdi:bed-queen"></iconify-icon>
        <span class="ml-2">{{ $t('general.rest') }}</span>
      </button>

      <button
        v-if="status === models.ServiceOrderStatus.Denied"
        type="submit"
        class="button is-danger"
        :class="loadingClass"
        data-testid="reasonSubmitButton"
      >
        <iconify-icon icon="mdi:thumb-down"></iconify-icon>
        <span class="ml-2">{{ $t('general.deny') }}</span>
      </button>
    </template>
  </baf-modal>
</template>
