<script setup lang="ts">
import CustomerTitleValues from '@/components/customer/customer-title-values.vue';
import EmployeeTitleValues from '@/components/employee/employee-title-values.vue';
import ServiceOrderRowTable from '@/components/service-order-row/service-order-row-table.vue';
import ServiceOrderUpdateTextsModal from '@/components/service-order/modal/service-order-update-texts-modal.vue';
import ServiceOrderRowUpcreateModal from '@/components/service-order/service-order-row-upcreate-modal.vue';
import ServiceOrderUpdateEmployeeModal from '@/components/service-order/service-order-update-employee-modal.vue';
import ServiceOrderUpdateLabelsModal from '@/components/service-order/service-order-update-labels-modal.vue';
import ServiceOrderUpdateModal from '@/components/service-order/service-order-update-modal.vue';
import ServiceOrderUpdateStatusButtons from '@/components/service-order/service-order-update-status-buttons.vue';
import BafLoader from '@/components/standard/loader/baf-loader.vue';
import TitleValueRoot from '@/components/standard/title-value/title-value-root.vue';
import TitleValue from '@/components/standard/title-value/title-value.vue';
import { useServiceOrderComputed } from '@/composeables/service-order/use-service-order-computed';
import { useAuth } from '@/composeables/use-auth';
import { useLoading } from '@/composeables/use-loading';
import { serviceOrderService } from '@/services/service-order/service-order-service';
import { useRouteQuery } from '@vueuse/router';
import { models, utils } from 'baf-shared';
import { computed, onActivated, onBeforeMount, ref, toRefs } from 'vue';
import ConfirmModal from '@/components/modal/confirm-modal.vue';
import { printData } from '@/utils/print-utils';
import { PrintTypeQuery } from '@/models/print-type-query';
import { RouteName } from '@/router/models/route-name';

const ServiceOrderRowStatus = models.ServiceOrderRowStatus;

const { loading, startLoading, stopLoading, loadingClass } = useLoading();

enum PageTab {
  Start = 'start',
  ServiceOrderRows = 'service-order-rows',
}
const activePageTab = useRouteQuery<PageTab>('tab', PageTab.Start);
const onClickPageTab = (tab: PageTab) => (activePageTab.value = tab);
const isPageTabActive = (tab: PageTab) => activePageTab.value === tab;

const activeServiceOrderRowsTab = useRouteQuery<models.ServiceOrderRowStatus>(
  'rows-tab',
  models.ServiceOrderRowStatus.InProgress,
);
const onClickRowTab = (tab: models.ServiceOrderRowStatus) =>
  (activeServiceOrderRowsTab.value = tab);
const isRowTabActive = (tab: models.ServiceOrderRowStatus) =>
  activeServiceOrderRowsTab.value === tab;

const props = defineProps<{
  id: string;
}>();
const { id } = toRefs(props);

const serviceOrder = ref<models.ServiceOrder>();
const copiedFromServiceOrder = ref<models.ServiceOrder>();
const rows = computed(
  () =>
    serviceOrder.value?.rows.filter((row) => row.status === activeServiceOrderRowsTab.value) ?? [],
);

const { isInProgress, isCreated, isAssigned, isReceived } = useServiceOrderComputed(serviceOrder);
const { isAdmin } = useAuth();

const isAllowedToUpcreateRows = computed(() => {
  const statusesAdminIsAllowedToCreateRows =
    isCreated.value || isAssigned.value || isReceived.value;
  const statusesNonAdminIsAllowedToCreateRows = isInProgress.value;

  return isAdmin.value ? statusesAdminIsAllowedToCreateRows : statusesNonAdminIsAllowedToCreateRows;
});

function onUpdateServiceOrder(_serviceOrder: models.ServiceOrder) {
  serviceOrder.value = _serviceOrder;
}

async function onCopyServiceOrder() {
  try {
    startLoading();
    await serviceOrderService.copyServiceOrders([serviceOrder.value!.id]);
  } finally {
    stopLoading();
  }
}

async function loadServiceOrder() {
  serviceOrder.value = await serviceOrderService.get(id.value);
  if (serviceOrder.value.copiedFromId) {
    copiedFromServiceOrder.value = await serviceOrderService.get(serviceOrder.value.copiedFromId);
  }
}

function onClickPrint() {
  printData(PrintTypeQuery.ServiceOrder, serviceOrder.value!.id);
}

onActivated(async () => {
  try {
    startLoading();
    await loadServiceOrder();
  } finally {
    stopLoading();
  }
});

onBeforeMount(async () => {
  if (loading.value) {
    return;
  }

  try {
    startLoading();
    await loadServiceOrder();
  } finally {
    stopLoading();
  }
});

const customer = computed(() => serviceOrder.value?.customer);
const employee = computed(() => serviceOrder.value?.employee);
const statusReason = computed(() =>
  serviceOrder.value?.statusReason ? `(${serviceOrder.value?.statusReason})` : undefined,
);
</script>

<template>
  <baf-loader v-if="loading"></baf-loader>

  <template v-else-if="serviceOrder">
    <section class="section">
      <div class="columns is-multiline">
        <div class="column is-narrow">
          <p class="title is-3 p-0">
            {{ $t('general.serviceOrder') }}
            {{ serviceOrder?.fortnoxId }}
            {{ $t(`general.enums.serviceOrderStatus.${serviceOrder?.status}`) }}
            {{ statusReason }}
          </p>
        </div>

        <div class="column spacer"></div>

        <div class="column is-narrow is-12-touch">
          <div class="tabs is-toggle is-medium is-fullwidth">
            <ul>
              <li :class="{ 'is-active': isPageTabActive(PageTab.Start) }">
                <a @click="onClickPageTab(PageTab.Start)">{{ $t('general.start') }}</a>
              </li>

              <li :class="{ 'is-active': isPageTabActive(PageTab.ServiceOrderRows) }">
                <a @click="onClickPageTab(PageTab.ServiceOrderRows)">
                  {{ $t('general.serviceOrderRows') }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <template v-if="isPageTabActive(PageTab.Start)">
      <div v-if="serviceOrder.copiedFromId" class="subtitle">
        <span> {{ $t('general.copiedFrom') }}: </span>
        <router-link
          data-testid="copiedFromServiceOrderRouterLink"
          :to="{
            name: RouteName.ServiceOrdersServiceOrder,
            params: { id: serviceOrder.copiedFromId },
          }"
        >
          <span>{{ serviceOrder.copiedFromId }}</span>
        </router-link>
      </div>
      <section class="section pt-0">
        <service-order-update-status-buttons
          :service-order="serviceOrder"
          @update="onUpdateServiceOrder"
        ></service-order-update-status-buttons>
      </section>
      <section class="section">
        <div class="columns">
          <div class="column">
            <div class="box">
              <div class="columns">
                <div class="column">
                  <title-value-root>
                    <title-value
                      :title="$t('general.contact')"
                      :value="
                        utils.joinValues([
                          serviceOrder?.contactName,
                          serviceOrder?.contactPhone,
                          serviceOrder?.contactEmail,
                        ])
                      "
                    ></title-value>
                    <title-value
                      :title="$t('general.address')"
                      :value="utils.joinValues([serviceOrder?.address, serviceOrder?.postCode])"
                    ></title-value>
                    <title-value
                      :title="$t('general.deliveryAddress')"
                      :value="
                        utils.joinValues([
                          serviceOrder?.deliveryAddress1,
                          serviceOrder?.deliveryAddress2,
                          serviceOrder?.deliveryPostCode,
                          serviceOrder?.deliveryCity,
                          serviceOrder?.deliveryCountry,
                        ])
                      "
                    ></title-value>
                    <title-value
                      :title="$t('general.labels')"
                      :value="
                        utils.joinValues(
                          serviceOrder?.labels.map((label) => label.description),
                          ', ',
                        )
                      "
                    >
                    </title-value>
                    <title-value
                      :title="$t('general.warehouse')"
                      :value="serviceOrder?.warehouse"
                    ></title-value>
                  </title-value-root>
                </div>

                <div class="column">
                  <title-value-root data-testid="previousInformationWithLastAndNextServiceColumn">
                    <title-value
                      :title="$t('general.previousEmployee')"
                      :value="
                        utils.joinValues([
                          copiedFromServiceOrder?.employee?.firstName,
                          copiedFromServiceOrder?.employee?.lastName,
                        ])
                      "
                    ></title-value>
                    <title-value
                      :title="$t('general.previousService')"
                      :value="utils.date.formatDate(copiedFromServiceOrder?.lastService)"
                    ></title-value>
                    <title-value
                      :title="$t('general.lastService')"
                      :value="utils.date.formatDate(serviceOrder?.lastService)"
                    ></title-value>
                    <title-value
                      :title="$t('general.nextService')"
                      :value="utils.date.formatDate(serviceOrder?.nextService)"
                    ></title-value>
                  </title-value-root>
                </div>

                <div class="column">
                  <div class="content render-as-is information-and-note">
                    <strong>{{ $t('general.customerInformation') }}</strong>
                    <p>{{ serviceOrder?.information || '-' }}</p>
                  </div>

                  <div class="content render-as-is information-and-note">
                    <strong>{{ $t('general.internalNote') }}</strong>
                    <p>{{ serviceOrder?.note || '-' }}</p>
                  </div>
                </div>
              </div>

              <div
                v-if="isInProgress || isAdmin"
                class="buttons is-right"
                data-testid="userActions"
              >
                <confirm-modal
                  v-if="isAdmin"
                  :confirm-callback="onCopyServiceOrder"
                  :message="$t('general.copyServiceOrdersConfirm', 1)"
                  #="{ open }"
                >
                  <button
                    type="button"
                    class="button is-info is-outlined"
                    data-testid="copyButton"
                    @click.stop="open"
                  >
                    <iconify-icon icon="mdi:content-copy"></iconify-icon>
                    <span class="ml-2">
                      {{ $t('general.copy') }}
                    </span>
                  </button>
                  <button
                    type="button"
                    class="button is-info is-outlined"
                    data-testid="printButton"
                    :class="loadingClass"
                    @click="onClickPrint"
                  >
                    <iconify-icon icon="mdi:printer-outline"></iconify-icon>
                    <span class="ml-2">
                      {{ $t('general.print') }}
                    </span>
                  </button>
                </confirm-modal>
                <service-order-update-modal
                  v-if="isInProgress || isAdmin"
                  v-slot="{ open }"
                  :service-order="serviceOrder"
                  @update="onUpdateServiceOrder"
                >
                  <button class="button is-link" data-testid="updateButton" @click="open">
                    <iconify-icon icon="mdi:edit"></iconify-icon>
                    <span class="ml-2">{{ $t('general.edit') }}</span>
                  </button>
                </service-order-update-modal>

                <service-order-update-texts-modal
                  v-if="isInProgress || isAdmin"
                  v-slot="{ open }"
                  :service-order="serviceOrder"
                  @update="onUpdateServiceOrder"
                >
                  <button class="button is-link" data-testid="updateTextsButton" @click="open">
                    <iconify-icon icon="mdi:edit"></iconify-icon>
                    <span class="ml-2">
                      {{ $t('general.customerInformation') }} /
                      {{ $t('general.internalNote') }}</span
                    >
                  </button>
                </service-order-update-texts-modal>

                <service-order-update-labels-modal
                  v-if="isInProgress || isAdmin"
                  v-slot="{ open }"
                  :service-order="serviceOrder"
                  @update="onUpdateServiceOrder"
                >
                  <button class="button is-link" data-testid="updateLabelsButton" @click="open">
                    <iconify-icon icon="mdi:edit"></iconify-icon>
                    <span class="ml-2">{{ $t('general.labels') }}</span>
                  </button>
                </service-order-update-labels-modal>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <p class="title is-4">{{ $t('general.customer') }}</p>
            <div class="box">
              <customer-title-values :customer="customer"></customer-title-values>
            </div>
          </div>

          <div class="column">
            <p class="title is-4">{{ $t('general.employee') }}</p>

            <div class="box">
              <employee-title-values :employee="employee"></employee-title-values>

              <div v-if="isAdmin" class="buttons is-right">
                <service-order-update-employee-modal
                  v-if="isAdmin"
                  v-slot="{ open }"
                  :service-order="utils.type.asType<models.ServiceOrder>(serviceOrder)"
                  @update="onUpdateServiceOrder"
                >
                  <button class="button is-link" @click="open">
                    <iconify-icon icon="mdi:edit"></iconify-icon>
                    <span class="ml-2">{{ $t('general.edit') }}</span>
                  </button>
                </service-order-update-employee-modal>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>

    <template v-else-if="isPageTabActive(PageTab.ServiceOrderRows)">
      <section class="section pt-0">
        <div class="columns is-multiline">
          <div v-if="isPageTabActive(PageTab.ServiceOrderRows)" class="column is-narrow-desktop">
            <div class="tabs is-toggle is-medium is-fullwidth">
              <li :class="{ 'is-active': isRowTabActive(ServiceOrderRowStatus.InProgress) }">
                <a @click="onClickRowTab(ServiceOrderRowStatus.InProgress)">
                  <iconify-icon icon="mdi:progress-check"></iconify-icon>
                  <span class="ml-2">{{ $t('general.inProgress') }}</span>
                </a>
              </li>
              <li :class="{ 'is-active': isRowTabActive(ServiceOrderRowStatus.Finished) }">
                <a @click="onClickRowTab(ServiceOrderRowStatus.Finished)">
                  <iconify-icon icon="mdi:check"></iconify-icon>
                  <span class="ml-2">{{ $t('general.finished') }}</span>
                </a>
              </li>
            </div>
          </div>

          <div class="column spacer"></div>

          <div class="column is-narrow-desktop">
            <service-order-row-upcreate-modal
              v-if="isAllowedToUpcreateRows"
              #="{ open }"
              :rows="rows"
              :service-order-id="serviceOrder.id"
              @upcreate="onUpdateServiceOrder"
            >
              <button class="button is-success" data-testid="createButton" @click.stop="open">
                <iconify-icon icon="mdi:add-circle"></iconify-icon>
                <span class="ml-2">{{ $t('general.serviceOrderRowCreate') }}</span>
              </button>
            </service-order-row-upcreate-modal>
          </div>
          <div class="column is-narrow-desktop">
            <service-order-update-status-buttons
              :service-order="serviceOrder"
              @update="onUpdateServiceOrder"
            ></service-order-update-status-buttons>
          </div>
        </div>
      </section>

      <section class="section">
        <div class="box">
          <service-order-row-table
            :service-order="serviceOrder"
            :rows="rows"
            @update="onUpdateServiceOrder"
          ></service-order-row-table>
        </div>
      </section>
    </template>

    <section class="section">
      <div class="columns">
        <div class="column spacer"></div>

        <div class="column is-narrow-desktop">
          <service-order-row-upcreate-modal
            v-if="isAllowedToUpcreateRows && isPageTabActive(PageTab.ServiceOrderRows)"
            #="{ open }"
            :rows="rows"
            :service-order-id="serviceOrder.id"
            @upcreate="onUpdateServiceOrder"
          >
            <button class="button is-success" data-testid="createButton" @click.stop="open">
              <iconify-icon icon="mdi:add-circle"></iconify-icon>
              <span class="ml-2">{{ $t('general.serviceOrderRowCreate') }}</span>
            </button>
          </service-order-row-upcreate-modal>
        </div>
        <div class="column is-narrow-desktop">
          <service-order-update-status-buttons
            :service-order="serviceOrder"
            @update="onUpdateServiceOrder"
          ></service-order-update-status-buttons>
        </div>
      </div>
    </section>
  </template>
</template>
<style scoped lang="scss">
.information-and-note {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
