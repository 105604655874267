export function isNumber(value) {
    return !isNaN(Number(value));
}
export function isString(value) {
    return typeof value === 'string';
}
export function isObject(value) {
    return typeof value === 'object';
}
export function isArray(value) {
    return Array.isArray(value);
}
export function isDate(value) {
    return value instanceof Date;
}
export function asType(value) {
    return value;
}
