<script setup lang="ts">
import PrintCertification from '@/components/print/certification/print-certification.vue';
import { PrintTypeQuery } from '@/models/print-type-query';
import { BafError, models, utils } from 'baf-shared';

import { computed, nextTick, onBeforeUnmount, onMounted, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { certificationService } from '@/services/certification/certification-service';
import PrintReoccurringControl from '@/components/print/pressure-test/print-reoccurring-control.vue';
import PrintInspectionCertificate from '@/components/print/pressure-test/print-inspection-certificate.vue';
import { PrintOrientation } from '@/models/print-orientation';
import { pressureTestService } from '@/services/pressure-test/pressure-test-service';
import BafSelectField from '@/components/standard/form/baf-select-field.vue';
import { serviceOrderService } from '@/services/service-order/service-order-service';
import PrintServiceOrder from '@/components/print/service-order/print-service-order.vue';

const { t, locale } = useI18n();
const route = useRoute();
const { query } = toRefs(route);

const LANGUAGES = Object.values(models.Languages).map((language) => ({
  text: t(`languages.text.${language}`),
  value: t(`languages.i18n.${language}`),
}));

const languages = computed(() => LANGUAGES);

const type = computed(() => utils.type.asType<PrintTypeQuery>(query.value.type));
const id = computed(() => utils.type.asType<string>(query.value.id));
const customerId = computed(() => utils.type.asType<string>(query.value.customerId));
const data = ref<unknown>();

const orientation = computed(() => {
  switch (type.value) {
    case PrintTypeQuery.ReoccurringControl:
      return PrintOrientation.Landscape;
    case PrintTypeQuery.Certification:
    case PrintTypeQuery.InspectionCertificate:
    case PrintTypeQuery.ServiceOrder:
    default:
      return PrintOrientation.Portrait;
  }
});

function injectCssToMakeItPossibleToToggleBetweenPrintOrientations() {
  const css = `@page { size: ${orientation.value}; }`,
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

  style.type = 'text/css';
  style.media = 'print';
  style.appendChild(document.createTextNode(css));

  head.appendChild(style);
}

function onClickPrint() {
  injectCssToMakeItPossibleToToggleBetweenPrintOrientations();
  window.print();
}

async function getInspectionCertificatePressureTests(): Promise<models.PressureTest[]> {
  const pressureTests = await pressureTestService.getAll({ sequentialNumberId: id.value });

  const filteredPressureTests = pressureTests.filter(
    (pressureTest) => pressureTest.customer.id === customerId.value,
  );

  return filteredPressureTests;
}

onBeforeUnmount(() => {
  locale.value = 'sv';
});

onMounted(async () => {
  await nextTick();
  const now = utils.date.nowInLocale().replace(/(\s|:)/g, '-');
  const translatedType = t(`general.enums.printTypeQuery.${type.value}`);

  switch (type.value) {
    case PrintTypeQuery.Certification:
      data.value = await certificationService.get(id.value);
      break;
    case PrintTypeQuery.ReoccurringControl:
      data.value = await pressureTestService.getAll({ sequentialNumberId: id.value });
      break;
    case PrintTypeQuery.InspectionCertificate:
      data.value = await getInspectionCertificatePressureTests();
      break;
    case PrintTypeQuery.ServiceOrder:
      data.value = await serviceOrderService.get(id.value);
      break;
    default:
      throw new BafError(`Unknown type ${type.value}`);
  }

  await utils.wait(500);

  document.title = `${now}-${translatedType}`;
});
</script>

<template>
  <section class="section px-4 is-hidden-print" style="width: 10%">
    <button class="button is-primary" @click="onClickPrint">
      {{ $t('general.print') }}
    </button>
    <baf-select-field
      id="translationLanguage"
      v-model="locale"
      label=""
      data-testid="translationLanguageSelect"
    >
      <option v-for="(language, index) in languages" :key="index" :value="language.value">
        {{ language.text }}
      </option>
    </baf-select-field>
  </section>

  <section v-if="data" class="section p-0">
    <print-certification
      v-if="type === PrintTypeQuery.Certification"
      :certification="utils.type.asType<models.Certification>(data)"
      :orientation="orientation"
    ></print-certification>
    <print-reoccurring-control
      v-if="type === PrintTypeQuery.ReoccurringControl"
      :pressure-tests="utils.type.asType<models.PressureTest[]>(data)"
      :orientation="orientation"
    ></print-reoccurring-control>
    <print-inspection-certificate
      v-if="type === PrintTypeQuery.InspectionCertificate"
      :pressure-tests="utils.type.asType<models.PressureTest[]>(data)"
      :orientation="orientation"
    ></print-inspection-certificate>
    <print-service-order
      v-if="type === PrintTypeQuery.ServiceOrder"
      :orientation="orientation"
      :service-order="utils.type.asType<models.ServiceOrder>(data)"
    >
    </print-service-order>
  </section>
</template>

<style lang="scss">
@media print {
  nav {
    display: none !important;
  }

  .is-hidden-print {
    display: none !important;
  }
}

.print-spacer {
  height: 2em;
}
</style>
