<script setup lang="ts">
import { useOpenable } from '@/composeables/use-openable';
import { computed, toRefs, watch } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  title: string;
  tag: string;
  large?: boolean;
}>();

const { title, tag, large } = toRefs(props);

const { openValue, open, close, toggleOpen, elementClass } = useOpenable();

function toggleHtmlStopScrollClass() {
  const html = document.querySelector('html');
  const STOP_SCROLL_CLASS = 'is-clipped';

  if (openValue.value) {
    html?.classList.add(STOP_SCROLL_CLASS);
  } else {
    html?.classList.remove(STOP_SCROLL_CLASS);
  }
}
const contentClass = computed(() => ({
  'is-large': large.value,
}));

watch(
  openValue,
  () => {
    toggleHtmlStopScrollClass();
  },
  { flush: 'sync' },
);

const slotBinds = computed(() => ({
  openValue,
  open,
  close,
  toggleOpen,
  elementClass,
}));

defineExpose({
  openValue,
  open,
  close,
  toggleOpen,
});
</script>

<template>
  <slot v-bind="slotBinds" name="trigger"></slot>

  <teleport v-if="openValue" to="body">
    <div v-if="openValue" class="modal" :class="elementClass">
      <div class="modal-background"></div>
      <div class="modal-content" :class="contentClass">
        <component :is="tag" class="modal-card" v-bind="$attrs">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
          </header>

          <section class="modal-card-body">
            <slot name="body" v-bind="slotBinds"></slot>
          </section>

          <footer class="modal-card-foot is-justify-content-flex-end">
            <slot name="footer" v-bind="slotBinds"></slot>
          </footer>
        </component>
      </div>
    </div>
  </teleport>
</template>

<style lang="scss" scoped>
.modal-content {
  .modal-card {
    margin: 0;
    overflow-y: auto;
  }
}
.modal-content.is-large {
  width: 1080px;
  max-width: 100vw;

  .modal-card {
    width: inherit;
    max-width: inherit;
  }
}
</style>
