export function isEqual(value, other) {
    return JSON.stringify(value) === JSON.stringify(other);
}
export const randomString = () => Math.random().toString(36).slice(2);
/**
 * Join an array of values.
 *
 * For example: `['John', 'Doe']` to `'John Doe'`.
 *
 * @param values - the values to join.
 * @param seperator - value seperator, defaults to ' '.
 * @returns
 */
export function joinValues(values, seperator = ' ') {
    return [...values].filter(Boolean).join(seperator);
}
export function uniqueArray(values) {
    return Array.from(new Set(values));
}
export function uniqueArrayByKey(values, key) {
    const arrayUniqueByKey = [...new Map(values.map((item) => [item[key], item])).values()];
    return arrayUniqueByKey;
}
export function wait(waitInMs) {
    return new Promise((resolve) => setTimeout(resolve, waitInMs));
}
