<script setup lang="ts">
import { useLoading } from '@/composeables/use-loading';
import { labelService } from '@/services/label/label-service';
import type { Nullish, models } from 'baf-shared';
import { ref, toRefs } from 'vue';
import BafModal from '../standard/modal/baf-modal.vue';

type FormData = {
  description: string;
};

function createFormData(label?: models.Label): FormData {
  return {
    description: label?.description ?? '',
  };
}

const { startLoading, stopLoading, loadingClass } = useLoading();
const modal = ref<InstanceType<typeof BafModal>>();

const props = defineProps<{
  label?: models.Label;
}>();

const { label } = toRefs(props);

const isUpdating = !!label.value;

const formData = ref(createFormData(label.value));

const emit = defineEmits<{
  (e: 'upcreate'): void;
}>();

async function onCancel() {
  formData.value = createFormData(label.value);
  modal.value?.close();
}

async function onSubmit() {
  try {
    startLoading();

    let updatedLabel: Nullish<models.Label> = undefined;
    if (isUpdating) {
      updatedLabel = await labelService.update(label.value!.id, formData.value.description);
    } else {
      await labelService.create(formData.value.description);
    }

    formData.value = createFormData(updatedLabel);
    emit('upcreate');
    modal.value?.close();
  } finally {
    stopLoading();
  }
}
</script>

<template>
  <baf-modal
    ref="modal"
    :title="isUpdating ? $t('general.labelUpdate') : $t('general.labelCreate')"
    tag="form"
    @submit.prevent="onSubmit"
  >
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <div class="field">
        <label class="label" for="label-description">{{ $t('general.description') }}</label>
        <div class="control">
          <input
            id="label-description"
            v-model="formData.description"
            data-testid="labelDescriptionInput"
            required
            class="input"
            type="text"
            pattern="^.+$"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="button"
        data-testid="cancelButton"
        :class="loadingClass"
        @click="onCancel()"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        type="submit"
        class="button is-primary"
        data-testid="upcreateButton"
        :class="loadingClass"
      >
        {{ isUpdating ? $t('general.update') : $t('general.create') }}
      </button>
    </template>
  </baf-modal>
</template>
