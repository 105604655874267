<script setup lang="ts">
import ExtinguishingSystemTable from '@/components/certification-row/extinguishing-system/extinguishing-system-table.vue';
import FireExtinguisherTable from '@/components/certification-row/fire-extinguisher/fire-extinguisher-table.vue';
import CertificationVerificationTable from '@/components/certification-verification/certification-verification-table.vue';
import BafLoader from '@/components/standard/loader/baf-loader.vue';
import BafSearchSelect from '@/components/standard/search-select/baf-search-select.vue';
import { useLoading } from '@/composeables/use-loading';
import { RouteName } from '@/router/models/route-name';
import { router } from '@/router/router';
import { certificationAssetService } from '@/services/certification-asset/certification-asset-service';
import { certificationService } from '@/services/certification/certification-service';
import { customerService } from '@/services/customer/customer-service';
import { employeeService } from '@/services/employee/employee-service';
import { models, sortBy, utils } from 'baf-shared';
import { CertificationType } from 'baf-shared/dist/models';
import { computed, onBeforeMount, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import CertificationRenewModal from '@/components/certification/certification-renew-modal.vue';
import LifeVestTable from '@/components/certification-row/life-vest/life-vest-table.vue';
import SuitTable from '@/components/certification-row/suit/suit-table.vue';

const { t } = useI18n();

const customers = ref<models.Customer[]>([]);
const employees = ref<models.ListEmployee[]>([]);
const assets = ref<models.CertificationAsset[]>([]);
const types = ref(Object.values(models.CertificationType));

const { loading, startLoading, stopLoading } = useLoading();
const {
  loadingClass: submitLoadingClass,
  startLoading: startSubmitLoading,
  stopLoading: stopSubmitLoading,
} = useLoading();

const props = defineProps<{
  id?: string;
}>();
const { id } = toRefs(props);
const isUpdating = computed(() => !!id.value);
const formData = ref(createFormData());

type FormData = Omit<
  models.UpcreateCertification,
  'assetId' | 'customerId' | 'employeeId' | 'type'
> & {
  customer: models.Customer;
  employee: models.Employee | models.ListEmployee;
  asset: models.CertificationAsset;
  type?: models.CertificationType;
  text?: string;
  note?: string;
  extinguishingSystemType?: string;
  extinguishingSystemProtectedArea?: string;
};

function createFormData(certification?: models.Certification): FormData {
  return {
    type: certification?.type ?? undefined,
    service: certification?.service ?? '',
    text: certification?.text ?? '',
    note: certification?.note ?? '',
    city: certification?.city ?? 'Karlskrona',
    validFrom: utils.date.formatDateTimeLocal(certification?.validFrom ?? null)!,
    extinguishingSystemType: certification?.extinguishingSystemType ?? '',
    extinguishingSystemProtectedArea: certification?.extinguishingSystemProtectedArea ?? '',
    fireAlarmName: certification?.fireAlarmName ?? '',
    fireAlarmCentral: certification?.fireAlarmCentral ?? '',
    fireAlarmAlarmedArea: certification?.fireAlarmAlarmedArea ?? '',
    customer: certification?.customer ?? utils.type.asType<models.Customer>(null),
    employee: certification?.employee ?? utils.type.asType<models.Employee>(null),
    asset: certification?.asset ?? utils.type.asType<models.CertificationAsset>(null),
    rows: certification?.rows ? sortBy(certification?.rows, 'rowNumber') : [],
    verifications: certification?.verifications
      ? sortBy(certification?.verifications, 'rowNumber')
      : [],
  };
}

function onUpdateType() {
  formData.value.service = t(`general.enums.certificationType.${formData.value.type}`);
  formData.value.rows = [];

  formData.value.extinguishingSystemType = '';
  formData.value.extinguishingSystemProtectedArea = '';

  formData.value.fireAlarmName = '';
  formData.value.fireAlarmCentral = '';
  formData.value.fireAlarmAlarmedArea = '';
}

async function onUpdateCustomer() {
  assets.value = await certificationAssetService.getAll({
    customerId: formData.value.customer?.id,
  });
  formData.value.asset = utils.type.asType<models.CertificationAsset>(null);
}

async function onUpdateAsset() {
  if (formData.value.asset) {
    formData.value.customer = formData.value.asset.customer;
    assets.value = await certificationAssetService.getAll({
      customerId: formData.value.customer?.id,
    });
  }
}

async function onRenewCertification(renewedCertificationId: string) {
  await router.push({
    name: RouteName.CertificationsUpdate,
    params: {
      id: renewedCertificationId,
    },
  });
}

async function onCancel() {
  formData.value = createFormData();
  await router.push({
    name: RouteName.CertificationsStart,
  });
}

async function onSubmit() {
  try {
    startSubmitLoading();
    await upcreateCertification();
    if (!isUpdating.value) {
      await router.push({
        name: RouteName.CertificationsStart,
      });
    }
  } finally {
    stopSubmitLoading();
  }
}

function upcreateCertification(): Promise<models.Certification> {
  const upcreateCertification: models.UpcreateCertification = {
    type: formData.value.type!,
    service: formData.value.service,
    text: formData.value.text,
    note: formData.value.note,
    city: formData.value.city,
    validFrom: utils.date.formatDateTimeISO(formData.value.validFrom)!,
    employeeId: formData.value.employee.id,
    customerId: formData.value.customer.id,
    assetId: formData.value.asset.id,
    extinguishingSystemType: formData.value.extinguishingSystemType,
    extinguishingSystemProtectedArea: formData.value.extinguishingSystemProtectedArea,
    fireAlarmName: formData.value.fireAlarmName,
    fireAlarmCentral: formData.value.fireAlarmCentral,
    fireAlarmAlarmedArea: formData.value.fireAlarmAlarmedArea,
    rows: formData.value.rows,
    verifications: formData.value.verifications,
  };

  if (isUpdating.value) {
    return certificationService.update(id.value!, upcreateCertification);
  } else {
    return certificationService.create(upcreateCertification);
  }
}

onBeforeMount(async () => {
  try {
    startLoading();
    customers.value = await customerService.getAll();
    employees.value = await employeeService.getAll({ access: models.Access.Certificates });
    assets.value = await certificationAssetService.getAll();
    if (isUpdating.value) {
      const certification = await certificationService.get(id.value!);
      formData.value = createFormData(certification);
    } else {
      formData.value = createFormData();
    }
  } finally {
    stopLoading();
  }
});
</script>

<template>
  <baf-loader v-if="loading"></baf-loader>

  <template v-else>
    <div class="mt-4 py-4">
      <p class="title is-3 p-0">
        {{ isUpdating ? $t('general.certificationUpdate') : $t('general.certificationCreate') }}
      </p>
    </div>
    <div v-if="isUpdating" class="buttons is-right">
      <certification-renew-modal :id="id!" v-slot="{ open }" @renew="onRenewCertification">
        <button type="button" class="button is-primary" @click="open">
          <iconify-icon icon="mdi:autorenew"></iconify-icon>
          <span class="ml-2">
            {{ $t('general.renew') }}
          </span>
        </button>
      </certification-renew-modal>
    </div>

    <form @submit.prevent="onSubmit">
      <div class="box">
        <div class="columns is-multiline">
          <div class="column">
            <div class="field">
              <label class="label" for="certificationCustomer"> {{ $t('general.customer') }}</label>
              <div class="control">
                <baf-search-select
                  id="certificationCustomer"
                  v-model="formData.customer"
                  data-testid="certificationCustomerBafSearchSelect"
                  :values="customers"
                  value-key="id"
                  text-key="name"
                  required
                  @update:model-value="onUpdateCustomer"
                ></baf-search-select>
              </div>
            </div>

            <div class="field">
              <label class="label" for="certificationObject"> {{ $t('general.object') }}</label>
              <div class="control">
                <baf-search-select
                  id="certificationObject"
                  v-model="formData.asset"
                  data-testid="certificationObjectBafSearchSelect"
                  :values="assets"
                  value-key="id"
                  text-key="name"
                  required
                  @update:model-value="onUpdateAsset"
                ></baf-search-select>
              </div>
            </div>

            <div class="field">
              <label class="label" for="certificationEmployee">
                {{ $t('general.inspector') }}</label
              >
              <div class="control">
                <baf-search-select
                  id="certificationEmployee"
                  v-model="formData.employee"
                  data-testid="certificationEmployeeBafSearchSelect"
                  :values="employees"
                  value-key="id"
                  text-key="fullName"
                  required
                ></baf-search-select>
              </div>
            </div>

            <div class="field">
              <label class="label" for="certificationType">
                {{ $t('general.certificationType') }}</label
              >
              <div class="control">
                <div class="select is-fullwidth">
                  <select
                    id="certificationType"
                    v-model="formData.type"
                    required
                    data-testid="certificationTypeSelect"
                    @update:model-value="onUpdateType"
                  >
                    <option :value="undefined" disabled>-</option>
                    <option
                      v-for="certificationType in types"
                      :key="certificationType.toString()"
                      :value="certificationType"
                    >
                      {{ $t(`general.enums.certificationType.${certificationType}`) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="field">
              <label class="label" for="certificationService">{{
                $t('general.certificationService')
              }}</label>
              <div class="control">
                <input
                  id="certificationService"
                  v-model="formData.service"
                  data-testid="certificationServiceInput"
                  required
                  class="input"
                  type="text"
                />
              </div>
            </div>

            <div class="field">
              <label class="label" for="certificationCity">{{
                $t('general.certificationCity')
              }}</label>
              <div class="control">
                <input
                  id="certificationCity"
                  v-model="formData.city"
                  data-testid="certificationCityInput"
                  required
                  class="input"
                  type="text"
                />
              </div>
            </div>

            <div class="field">
              <label class="label" for="certificationValidFrom">{{ $t('general.date') }}</label>
              <div class="control">
                <input
                  id="certificationValidFrom"
                  v-model="formData.validFrom"
                  data-testid="certificationValidFromDateTimeInput"
                  required
                  type="datetime-local"
                  class="input"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label" for="certificationText">
                {{ $t('general.certificationText') }}
              </label>
              <div class="control">
                <textarea
                  id="certificationText"
                  v-model="formData.text"
                  data-testid="certificationTextTextArea"
                  rows="10"
                  class="textarea"
                ></textarea>
              </div>
            </div>
            <div class="field">
              <label class="label" for="certificationNote">{{
                $t('general.customerInformation')
              }}</label>
              <div class="control">
                <textarea
                  id="certificationNote"
                  v-model="formData.note"
                  data-testid="certificationNoteTextArea"
                  rows="10"
                  class="textarea"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="formData.type === models.CertificationType.ExtinguishingSystem" class="box pb-4">
        <div class="columns">
          <div class="column is-6-desktop">
            <p class="title is-5">
              {{ $t(`general.enums.certificationType.ExtinguishingSystem`) }}
            </p>

            <div class="field">
              <label class="label" for="extinguishingSystemType">{{
                $t('general.extinguishingSystemType')
              }}</label>
              <div class="control">
                <input
                  id="extinguishingSystemType"
                  v-model="formData.extinguishingSystemType"
                  data-testid="extinguishingSystemType"
                  class="input"
                />
              </div>
            </div>

            <div class="field">
              <label class="label" for="extinguishingSystemProtectedArea">{{
                $t('general.protectedArea')
              }}</label>
              <div class="control">
                <input
                  id="extinguishingSystemProtectedArea"
                  v-model="formData.extinguishingSystemProtectedArea"
                  data-testid="extinguishingSystemProtectedAreaInput"
                  class="input"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="formData.type === models.CertificationType.FireAlarm" class="box pb-4">
        <div class="columns">
          <div class="column is-6-desktop">
            <p class="title is-5">
              {{ $t(`general.enums.certificationType.FireAlarm`) }}
            </p>

            <div class="field">
              <label class="label" for="fireAlarmName">{{ $t('general.name') }}</label>
              <div class="control">
                <input
                  id="fireAlarmName"
                  v-model="formData.fireAlarmName"
                  data-testid="fireAlarmNameInput"
                  class="input"
                />
              </div>
            </div>

            <div class="field">
              <label class="label" for="fireAlarmCentral">{{ $t('general.central') }}</label>
              <div class="control">
                <input
                  id="fireAlarmCentral"
                  v-model="formData.fireAlarmCentral"
                  data-testid="fireAlarmCentralInput"
                  class="input"
                />
              </div>
            </div>

            <div class="field">
              <label class="label" for="fireAlarmAlarmedArea">{{
                $t('general.alarmedArea')
              }}</label>
              <div class="control">
                <input
                  id="fireAlarmAlarmedArea"
                  v-model="formData.fireAlarmAlarmedArea"
                  data-testid="fireAlarmAlarmedAreaInput"
                  class="input"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="formData.type !== CertificationType.FireAlarm" class="box pb-4">
        <div class="columns">
          <div class="column">
            <p v-if="formData.type" class="title is-5 ml-4 mb-0">
              {{ $t(`general.certificationRows`) }} -
              {{ $t(`general.enums.certificationType.${formData.type}`) }}
            </p>
            <p v-else class="title is-5">
              {{ $t(`general.certificationRows`) }} - {{ $t('general.chooseCertificationType') }}
            </p>
            <fire-extinguisher-table
              v-if="formData.type === models.CertificationType.FireExtinguisher"
              :rows="formData.rows"
            ></fire-extinguisher-table>
            <extinguishing-system-table
              v-if="formData.type === models.CertificationType.ExtinguishingSystem"
              :rows="formData.rows"
            ></extinguishing-system-table>
            <life-vest-table
              v-if="formData.type === models.CertificationType.LifeVest"
              :rows="formData.rows"
            ></life-vest-table>
            <suit-table
              v-if="formData.type === models.CertificationType.Suit"
              :rows="formData.rows"
            ></suit-table>
          </div>
        </div>
      </div>

      <div v-if="formData.type !== models.CertificationType.FireExtinguisher" class="box pb-4">
        <div class="columns">
          <div class="column">
            <p class="title is-5 ml-4 mb-0">{{ $t(`general.checkpoints`) }}</p>
            <certification-verification-table
              :verifications="formData.verifications"
            ></certification-verification-table>
          </div>
        </div>
      </div>

      <div class="buttons is-right">
        <button
          type="button"
          class="button"
          data-testid="cancelButton"
          :class="submitLoadingClass"
          @click="onCancel"
        >
          {{ $t('general.cancel') }}
        </button>
        <button
          type="submit"
          class="button is-primary"
          data-testid="upcreateButton"
          :class="submitLoadingClass"
        >
          {{ isUpdating ? $t('general.update') : $t('general.create') }}
        </button>
      </div>
    </form>
  </template>
</template>
