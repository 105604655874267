<script setup lang="ts">
import CertificationTable from '@/components/certification/certification-table.vue';
import { RouteName } from '@/router/models/route-name';
import BafTextField from '@/components/standard/form/baf-text-field.vue';
import { ref } from 'vue';
import { certificationService } from '@/services/certification/certification-service';

const certificationTable = ref<InstanceType<typeof CertificationTable>>();
const search = ref('');
let timeout = 0;

const onSearch = async () => {
  certificationTable.value?.startLoading();
  window.clearTimeout(timeout);
  timeout = window.setTimeout(async () => {
    const searchCertificationsProvider = () => certificationService.search(search.value);
    await certificationTable.value?.loadCertifications(searchCertificationsProvider);
  }, 500);
};
</script>

<template>
  <section class="section">
    <div class="columns">
      <div class="column is-narrow">
        <baf-text-field
          id="test"
          v-model="search"
          label=""
          placeholder="Sök"
          @update:model-value="onSearch"
        ></baf-text-field>
      </div>
      <div class="column spacer"></div>
      <div class="column is-narrow">
        <div class="buttons is-right">
          <router-link :to="{ name: RouteName.CertificationsCreate }" class="button is-success">
            <iconify-icon icon="mdi:add-circle"></iconify-icon>
            <span class="ml-2">{{ $t('general.certificationCreate') }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <section class="section">
    <div class="box">
      <certification-table ref="certificationTable"></certification-table>
    </div>
  </section>
</template>
