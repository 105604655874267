import { config } from '@/config';
import { appHttpService, authHttpService } from '@/services/standard/http-service';
import {
  getLocationOrigin,
  getLocationSearch,
  setLocationHref,
  setLocationSearch,
} from '@/utils/location-utils';
import { models, type Nullish, utils } from 'baf-shared';
import type { GetMeResponse } from 'baf-shared/dist/models';
import { LocalStorageService } from '../storage/local-storage-service';

interface RefreshAccessTokenParams {
  forceRefresh?: boolean;
}

interface AuthState {
  authenticated: boolean;
  me?: GetMeResponse;
  loading: boolean;
  refreshing?: boolean;
  verificationState?: string;
  accessToken?: string;
  refreshToken?: string;
  refreshTokenExpiresIn?: string;
}

const createAuthState = (): AuthState => ({
  authenticated: false,
  me: undefined,
  loading: false,
  refreshing: false,
  verificationState: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  refreshTokenExpiresIn: undefined,
});

class AuthService extends LocalStorageService<AuthState> {
  private refreshTokenInterval?: ReturnType<typeof setTimeout>;

  constructor() {
    super('auth', createAuthState);
  }

  login(): void {
    setLocationHref(this.authUrl());
  }

  logout(): void {
    this.setState(createAuthState());
    clearInterval(this.refreshTokenInterval);
    setLocationHref('/');
  }

  async handleRedirect(): Promise<void> {
    this.setState({ loading: false });

    const queriesString = getLocationSearch().slice(1);
    const queries = new URLSearchParams(queriesString);

    const authorizationCode = queries.get('code');
    const verificationState = queries.get('state');
    const { verificationState: savedVerificationState } = this.getState();

    if (authorizationCode && verificationState === savedVerificationState) {
      this.setState({ verificationState, loading: true });

      try {
        const response = await this.getAccessTokenFromAuthorizationCode({
          authorizationCode,
          redirectUri: this.redirectUri(),
        });

        const refreshTokenExpiresIn = utils.date.changeDate({
          date: new Date(),
          unit: 'seconds',
          delta: response.expiresIn,
        });

        const me = await this.getMe(response.accessToken);
        this.setState({
          authenticated: true,
          me,
          loading: false,
          accessToken: response.accessToken,
          refreshToken: response.refreshToken,
          refreshTokenExpiresIn: refreshTokenExpiresIn?.toISOString(),
        });
        setLocationSearch('');
      } catch (error) {
        console.error('Failed to handle authentication redirect.');
        this.logout();
      }
    }
  }

  async handleRefreshToken(): Promise<void> {
    const REFRESH_INTERVAL = 2 * 1000;

    this.refreshTokenInterval = setInterval(() => this.refreshAccessToken(), REFRESH_INTERVAL);
  }

  async handleMeState(): Promise<void> {
    if (!this.accessToken) {
      return;
    }

    await this.getMeAndUpdateState(this.accessToken);
  }

  async refreshAccessToken({ forceRefresh = false }: RefreshAccessTokenParams = {}) {
    if (!this.authenticated || this.refreshing) {
      return;
    }

    const { refreshToken, refreshTokenExpiresIn } = this.state().value ?? {};
    const now = new Date();
    const refreshTokenExpiresInMinus15Minutes = utils.date.changeDate({
      date: refreshTokenExpiresIn,
      unit: 'minutes',
      delta: -15,
    });

    if (
      (refreshToken &&
        refreshTokenExpiresInMinus15Minutes &&
        now >= refreshTokenExpiresInMinus15Minutes) ||
      (refreshToken && forceRefresh)
    ) {
      this.setState({ refreshing: true });

      try {
        const response = await this.getAccessTokenFromRefreshToken({ refreshToken });
        const refreshTokenExpiresIn = utils.date.changeDate({
          date: new Date(),
          unit: 'seconds',
          delta: response.expiresIn,
        });
        this.setState({
          accessToken: response.accessToken,
          refreshToken: response.refreshToken,
          refreshTokenExpiresIn: refreshTokenExpiresIn?.toISOString(),
        });
      } finally {
        this.setState({
          loading: false,
          refreshing: false,
        });
      }
    }
  }

  getAccessTokenFromAuthorizationCode({
    authorizationCode,
    redirectUri,
  }: models.GetAccessTokenFromAuthorizationCodeRequest): Promise<models.GetAccessTokenResponse> {
    return authHttpService.request
      .url('/auth/access-token')
      .post({
        authorizationCode,
        redirectUri,
      })
      .json<models.GetAccessTokenResponse>();
  }

  getAccessTokenFromRefreshToken({
    refreshToken,
  }: models.GetAccessTokenFromRefreshTokenRequest): Promise<models.GetAccessTokenResponse> {
    return authHttpService.request
      .url('/auth/refresh-token')
      .headers({
        authorization: `Bearer ${this.accessToken}`,
      })
      .post({
        refreshToken,
      })
      .json<models.GetAccessTokenResponse>();
  }

  /**
   * Visible for testing
   */
  getMe(accessToken?: string): Promise<models.GetMeResponse> {
    const me = appHttpService.request
      .url('/auth/me')
      .headers({
        authorization: `Bearer ${accessToken ?? this.accessToken}`,
      })
      .get()
      .json<models.GetMeResponse>();
    return me;
  }

  async getMeAndUpdateState(accessToken?: string): Promise<models.GetMeResponse> {
    const me = await this.getMe(accessToken);

    this.setState({
      me,
    });

    return me;
  }

  get accessToken(): Nullish<string> {
    return this.state().value?.accessToken;
  }

  get refreshToken(): Nullish<string> {
    return this.state().value?.refreshToken;
  }

  get refreshing(): boolean {
    return this.state().value?.refreshing === true;
  }

  get authenticated(): boolean {
    return this.state().value?.authenticated ?? false;
  }

  private redirectUri() {
    return getLocationOrigin();
  }

  private authUrl(): string {
    const { verificationState } = this.setState({
      verificationState: utils.randomString(),
    });

    const queries = new URLSearchParams({
      access_type: 'offline',
      client_id: config.FORTNOX_CLIENT_ID,
      redirect_uri: this.redirectUri(),
      response_type: 'code',
      state: verificationState!,
      scope:
        'profile customer connectfile article settings currency order invoice project supplier salary assets',
    });

    return `${config.FORTNOX_AUTH_URL}?${queries}`;
  }
}

export const authService = new AuthService();
