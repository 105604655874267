import type { Nullish } from 'baf-shared';
import type { Ref } from 'vue';

export interface StorageService<State extends object> {
  state(): Ref<Nullish<State>>;

  setState(state?: Partial<State>): State;

  getState(): State;

  resetState(): void;
}

export function buildStorageKey(key: string) {
  const PREFIX = 'brandabFusion';
  return [PREFIX, key].join(':').toLowerCase();
}
