<script setup lang="ts">
import BafLoader from '@/components/standard/loader/baf-loader.vue';
import BafSearchSelect from '@/components/standard/search-select/baf-search-select.vue';
import { useLoading } from '@/composeables/use-loading';
import { RouteName } from '@/router/models/route-name';
import { router } from '@/router/router';
import { customerService } from '@/services/customer/customer-service';
import { employeeService } from '@/services/employee/employee-service';
import { labelService } from '@/services/label/label-service';
import { serviceOrderService } from '@/services/service-order/service-order-service';
import { models, utils } from 'baf-shared';
import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

type MinimalLabel = {
  id: string;
  description: string;
};

function toMinimalLabel(label: models.Label): MinimalLabel {
  return {
    id: label.id,
    description: label.description,
  };
}

const WAREHOUSES = Object.values(models.ServiceOrderWarehouse).map((warehouse) => ({
  text: t(`general.enums.warehouse.${warehouse}`),
  value: warehouse,
}));
const warehouses = computed(() => WAREHOUSES);

const { loading, startLoading, stopLoading } = useLoading();
const {
  loadingClass: submitLoadingClass,
  startLoading: startSubmitLoading,
  stopLoading: stopSubmitLoading,
} = useLoading();

type FormData = Omit<models.CreateServiceOrder, 'labelIds' | 'customerId' | 'employeeId'> & {
  customer: models.Customer;
  employee: models.Employee | models.ListEmployee;
  labels: MinimalLabel[];
};

function createFormData(): FormData {
  return {
    customer: utils.type.asType<models.Customer>(null),
    employee: utils.type.asType<models.Employee>(null),
    labels: utils.type.asType<models.Label[]>([]),
    warehouse: models.ServiceOrderWarehouse.Karlskrona,
    contactName: null,
    contactPhone: null,
    contactEmail: null,
    deliveryAddress1: null,
    deliveryPostCode: null,
    deliveryCity: null,
    deliveryCountry: null,
    note: null,
    information: null,
  };
}

const customers = ref<models.Customer[]>([]);
const employees = ref<models.ListEmployee[]>([]);
const labels = ref<MinimalLabel[]>([]);
const formData = ref(createFormData());

const createButtonId = 'createButton';
const createAndOpenButtonId = 'createAndOpenButton';

async function onCancel() {
  formData.value = createFormData();
  await router.push({
    name: RouteName.ServiceOrdersStart,
  });
}

async function onSubmit(e: Event) {
  try {
    startSubmitLoading();
    const event = e as SubmitEvent;
    const createdServiceOrder = await createServiceOrder();
    if (event.submitter!.id === createAndOpenButtonId)
      await router.push({
        name: RouteName.ServiceOrdersServiceOrder,
        params: {
          id: createdServiceOrder.id,
        },
      });
    else {
      await router.push({
        name: RouteName.ServiceOrdersStart,
      });
    }
  } finally {
    stopSubmitLoading();
  }
}

function createServiceOrder(): Promise<models.ServiceOrder> {
  const createServiceOrder: models.CreateServiceOrder = {
    customerId: formData.value.customer.id,
    employeeId: formData.value.employee?.id,
    labelIds: formData.value.labels.map((l) => l.id),
    warehouse: formData.value.warehouse,
    contactName: formData.value.contactName,
    contactPhone: formData.value.contactPhone,
    contactEmail: formData.value.contactEmail,
    deliveryAddress1: formData.value.deliveryAddress1,
    deliveryPostCode: formData.value.deliveryPostCode,
    deliveryCity: formData.value.deliveryCity,
    deliveryCountry: formData.value.deliveryCountry,
    note: formData.value.note,
    information: formData.value.information,
  };

  return serviceOrderService.createServiceOrder(createServiceOrder);
}

onBeforeMount(async () => {
  try {
    startLoading();
    customers.value = await customerService.getAll();
    employees.value = await employeeService.getAll({ access: models.Access.ServiceOrders });
    const _labels = await labelService.getAll();
    labels.value = _labels?.map(toMinimalLabel);
    formData.value = createFormData();
  } finally {
    stopLoading();
  }
});
</script>

<template>
  <baf-loader v-if="loading"></baf-loader>
  <template v-else>
    <div class="mt-4 py-4">
      <p class="title is-3 p-0">
        {{ $t('general.serviceOrderCreate') }}
      </p>
    </div>
    <form @submit.prevent="onSubmit($event)">
      <div class="box">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label" for="serviceOrderCustomer"> {{ $t('general.customer') }}</label>
              <div class="control">
                <baf-search-select
                  id="serviceOrderCustomer"
                  v-model="formData.customer"
                  data-testid="serviceOrderCustomerBafSearchSelect"
                  :values="customers"
                  value-key="id"
                  text-key="name"
                  required
                ></baf-search-select>
              </div>
            </div>

            <div class="field">
              <label class="label" for="employee">{{ $t('general.employee') }}</label>
              <div class="control">
                <baf-search-select
                  id="employee"
                  v-model="formData.employee"
                  :values="employees"
                  text-key="fullName"
                  value-key="id"
                  data-testid="serviceOrderEmployeeBafSearchSelect"
                ></baf-search-select>
              </div>
            </div>

            <div class="field">
              <label class="label" for="serviceOrderContactName">
                {{ $t('general.contactName') }}</label
              >
              <div class="control">
                <input
                  id="serviceOrderContactName"
                  v-model="formData.contactName"
                  data-testid="serviceOrderContactNameInput"
                  class="input"
                />
              </div>
            </div>

            <div class="field">
              <label class="label" for="serviceOrderContactPhone">
                {{ $t('general.phoneNumber') }}
              </label>
              <div class="control">
                <input
                  id="serviceOrderContactPhone"
                  v-model="formData.contactPhone"
                  data-testid="serviceOrderContactPhoneInput"
                  class="input"
                />
              </div>
            </div>

            <div class="field">
              <label class="label" for="serviceOrderContactEmail">
                {{ $t('general.email') }}
              </label>
              <div class="control">
                <input
                  id="serviceOrderContactEmail"
                  v-model="formData.contactEmail"
                  data-testid="serviceOrderContactEmailInput"
                  class="input"
                  type="email"
                />
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label" for="serviceOrderWarehouse">{{ $t('general.warehouse') }}</label>
              <div class="control">
                <div class="select is-fullwidth">
                  <select
                    id="serviceOrderWarehouse"
                    v-model="formData.warehouse"
                    required
                    data-testid="serviceOrderWarehouseSelect"
                  >
                    <option
                      v-for="(warehouse, index) in warehouses"
                      :key="index"
                      :value="warehouse.value"
                    >
                      {{ warehouse.text }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="field">
              <label class="label" for="serviceOrderDeliveryAddress">
                {{ $t('general.deliveryAddress') }}
              </label>
              <div class="control">
                <input
                  id="serviceOrderDeliveryAddress"
                  v-model="formData.deliveryAddress1"
                  data-testid="serviceOrderDeliveryAddressInput"
                  class="input"
                />
              </div>
            </div>

            <div class="field">
              <label class="label" for="serviceOrderDeliveryPostCode">
                {{ $t('general.deliveryPostCode') }}
              </label>
              <div class="control">
                <input
                  id="serviceOrderDeliveryPostCode"
                  v-model="formData.deliveryPostCode"
                  data-testid="serviceOrderDeliveryPostCodeInput"
                  class="input"
                />
              </div>
            </div>

            <div class="field">
              <label class="label" for="serviceOrderDeliveryCity">
                {{ $t('general.deliveryCity') }}
              </label>
              <div class="control">
                <input
                  id="serviceOrderDeliveryCity"
                  v-model="formData.deliveryCity"
                  data-testid="serviceOrderDeliveryCityInput"
                  class="input"
                />
              </div>
            </div>

            <div class="field">
              <label class="label" for="serviceOrderDeliveryCountry">
                {{ $t('general.deliveryCountry') }}
              </label>
              <div class="control">
                <input
                  id="serviceOrderDeliveryCountry"
                  v-model="formData.deliveryCountry"
                  data-testid="serviceOrderDeliveryCountryInput"
                  class="input"
                />
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label" for="serviceOrderLabels">{{ $t('general.labels') }}</label>
              <div class="control">
                <baf-search-select
                  id="serviceOrderLabels"
                  v-model="formData.labels"
                  :values="labels"
                  value-key="id"
                  text-key="description"
                  data-testid="serviceOrderLabelBafSearchSelect"
                ></baf-search-select>
              </div>
            </div>
            <div class="field">
              <label class="label" for="serviceOrderInformation">
                {{ $t('general.customerInformation') }}
              </label>
              <div class="control">
                <textarea
                  id="serviceOrderInformation"
                  v-model="formData.information"
                  data-testid="serviceOrderInformationInput"
                  rows="4"
                  class="textarea"
                ></textarea>
              </div>
            </div>

            <div class="field">
              <label class="label" for="serviceOrderNote">{{ $t('general.internalNote') }}</label>
              <div class="control">
                <textarea
                  id="serviceOrderNote"
                  v-model="formData.note"
                  data-testid="serviceOrderNoteInput"
                  rows="4"
                  class="textarea"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="buttons is-right">
        <button
          type="button"
          class="button"
          data-testid="cancelButton"
          :class="submitLoadingClass"
          @click="onCancel"
        >
          {{ $t('general.cancel') }}
        </button>
        <button
          :id="createButtonId"
          type="submit"
          class="button"
          data-testid="createButton"
          :class="submitLoadingClass"
        >
          {{ $t('general.create') }}
        </button>
        <button
          :id="createAndOpenButtonId"
          type="submit"
          class="button is-primary"
          data-testid="createAndOpenButton"
          :class="submitLoadingClass"
        >
          {{ $t('general.createAndOpen') }}
        </button>
      </div>
    </form>
  </template>
</template>

<style scoped lang="scss"></style>
