<script setup lang="ts">
import { useLoading } from '@/composeables/use-loading';
import { PrintTypeQuery } from '@/models/print-type-query';
import { RouteName } from '@/router/models/route-name';
import { certificationService } from '@/services/certification/certification-service';
import { printData } from '@/utils/print-utils';
import type { models } from 'baf-shared';
import { utils } from 'baf-shared';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import BafTable from '../standard/table/baf-table.vue';
import { TableColumn, type TableRow } from '../standard/table/models/table';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { loading, startLoading, stopLoading } = useLoading();

const columns = [
  TableColumn.for('userActions', '', {
    charactersWidth: 1,
    search: false,
    sort: false,
  }),
  TableColumn.for('customer', t('general.customer'), {
    renderer: (value) => (value as models.ListCertification['customer']).name,
  }),
  TableColumn.for('asset', t('general.object'), {
    renderer: (value) => (value as models.ListCertification['asset']).name,
  }),
  TableColumn.for('type', t('general.type'), {
    renderer: (value) => {
      const type = value as models.CertificationType;
      return t(`general.enums.certificationType.${type}`);
    },
  }),
  TableColumn.for('validFrom', t('general.date'), {
    renderer: (value) => utils.date.formatDate(value as string),
  }),
  TableColumn.for('employee', t('general.inspector'), {
    renderer: (value) => {
      const employee = value as models.ListCertification['employee'];
      return utils.joinValues([employee?.firstName, employee?.lastName]);
    },
  }),
];

const data = ref<models.ListCertification[]>([]);

async function onClickRow(row: models.ListCertification | TableRow) {
  const certification = row as models.ListCertification;

  await router.push({
    name: RouteName.CertificationsUpdate,
    params: {
      id: certification.id,
    },
  });
}

function onClickPrint(certification: models.ListCertification) {
  printData(PrintTypeQuery.Certification, certification.id);
}

async function loadCertifications(
  certificationsProvider: () => Promise<models.ListCertification[]> = certificationService.getAll,
) {
  try {
    startLoading();
    data.value = await certificationsProvider();
  } finally {
    stopLoading();
  }
}

defineExpose({
  loadCertifications,
  startLoading,
});

onBeforeMount(async () => {
  await loadCertifications();
});
</script>

<template>
  <baf-table
    :columns="columns"
    :rows="data"
    :loading="loading"
    :sort-default="{ key: 'updated', asc: false }"
    :search-query="route.query"
    search
    paginate
    hover
    @click-row="onClickRow"
  >
    <template #column-userActions="{ row }">
      <div class="tags">
        <a class="tag is-info is-medium" data-testid="printButton" @click.stop="onClickPrint(row)">
          <iconify-icon icon="mdi:printer"></iconify-icon>
          <span class="ml-2">{{ $t('general.print') }}</span>
        </a>
      </div>
    </template>
  </baf-table>
</template>
