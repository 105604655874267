<script setup lang="ts">
import PressureTestAssetTable from '@/components/pressure-test-asset/pressure-test-asset-table.vue';
import PressureTestAssetUpcreateModal from '@/components/pressure-test-asset/pressure-test-asset-upcreate-modal.vue';
import { ref } from 'vue';

const assetTable = ref<InstanceType<typeof PressureTestAssetTable>>();
</script>

<template>
  <section class="section">
    <div class="buttons is-right">
      <pressure-test-asset-upcreate-modal #="{ open }" @upcreate="assetTable?.loadAssets">
        <button class="button is-success" data-testid="createButton" @click.stop="open">
          <iconify-icon icon="mdi:add-circle"></iconify-icon>
          <span class="ml-2">{{ $t('general.assetCreate') }}</span>
        </button>
      </pressure-test-asset-upcreate-modal>
    </div>
  </section>

  <section class="section">
    <div class="box">
      <pressure-test-asset-table ref="assetTable"></pressure-test-asset-table>
    </div>
  </section>
</template>
