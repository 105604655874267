import { createDataFunction } from '../../functions/create-data-function.js';
import { CategoryValueCategory, } from '../../models/category-value.js';
export const createCategoryValueGas = createDataFunction({
    id: 'd49442e1-93d2-4b14-b865-6b2d107b4e72',
    category: CategoryValueCategory.Gas,
    value: 'Luft',
    created: new Date('2024-05-06T11:07:11.104Z'),
    updated: new Date('2024-05-06T11:07:11.104Z'),
});
export const createCategoryValueManufacturer = createDataFunction({
    id: '116cb493-04cb-4915-b81d-d4d1ca55f3bd',
    category: CategoryValueCategory.Manufacturer,
    value: 'Ctco',
    created: new Date('2024-05-06T11:07:11.104Z'),
    updated: new Date('2024-05-06T11:07:11.104Z'),
});
export const upcreateCategoryValue = createDataFunction({
    category: CategoryValueCategory.Gas,
    value: 'Luft',
});
