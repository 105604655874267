<script setup lang="ts">
import PressureTestUpcreateForm from '@/components/pressure-test/pressure-test-upcreate-form.vue';
import { onBeforeMount, ref, toRefs } from 'vue';
import PressureTestTable from '@/components/pressure-test/pressure-test-table.vue';
import { pressureTestSequentialNumberService } from '@/services/pressure-test-sequential-number/pressure-test-sequential-number-service';
import { models } from 'baf-shared';

const pressureTestTable = ref<InstanceType<typeof PressureTestTable>>();

const props = defineProps<{
  sequentialNumberId: string;
}>();
const { sequentialNumberId } = toRefs(props);
const sequentialNumber = ref<models.PressureTestSequentialNumber>();

const pressureTestToEdit = ref<models.PressureTest>();

const onClickEdit = (row: models.PressureTest) => {
  pressureTestToEdit.value = row;
  window.scrollTo(0, 0);
};

onBeforeMount(async () => {
  sequentialNumber.value = await pressureTestSequentialNumberService.get(sequentialNumberId.value);
});
</script>

<template>
  <section class="section">
    <pressure-test-upcreate-form
      :sequential-number-id="sequentialNumberId"
      :pressure-test="pressureTestToEdit"
      @cancel="pressureTestToEdit = undefined"
      @upcreate="
        pressureTestTable?.loadPressureTests();
        pressureTestToEdit = undefined;
      "
    />
  </section>

  <section v-if="sequentialNumber" class="section">
    <div class="columns is-multiline">
      <div class="column is-narrow">
        <p class="title is-3 p-0">
          {{
            $t('general.pressureTestsForSequentialNumber', {
              sequentialNumber: sequentialNumber.sequentialNumber,
            })
          }}
        </p>
      </div>
    </div>
  </section>

  <section v-if="sequentialNumber" class="section">
    <div class="box">
      <pressure-test-table
        ref="pressureTestTable"
        :sequential-number="sequentialNumber"
        @on-click-edit="onClickEdit"
      >
      </pressure-test-table>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.pressure-test-table {
  padding-top: 2rem;
  padding-bottom: 10rem;
}
</style>
