<script setup lang="ts">
import CertificationVerificationUpcreateModal from '@/components/certification-verification/certification-verification-upcreate-modal.vue';
import ConfirmModal from '@/components/modal/confirm-modal.vue';
import { type models, sortOriginalBy } from 'baf-shared';
import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import BafTable from '../standard/table/baf-table.vue';
import { TableColumn } from '../standard/table/models/table';

const { t } = useI18n();
const props = defineProps<{
  verifications: models.UpcreateCertificationVerification[];
}>();
const { verifications } = toRefs(props);
const columns = [
  TableColumn.for('userActions', '', {
    charactersWidth: 1,
    search: false,
    sort: false,
  }),
  TableColumn.for('rowNumber', t('general.rowNumber'), { charactersWidth: 16 }),
  TableColumn.for('name', t('general.name')),
  TableColumn.for('status', t('general.status'), {
    renderer: (value) => {
      const status = value as models.CertificationVerificationStatus;
      return t(`general.enums.addition.${status}`);
    },
  }),
  TableColumn.for('note', t('general.internalNote')),
];
function onDeleteVerificationCallback(verification: models.UpcreateCertificationVerification) {
  return () =>
    new Promise<void>((resolve) => {
      const index = verifications.value.indexOf(verification);
      verifications.value.splice(index, 1);
      sortOriginalBy(verifications.value, 'rowNumber');
      resolve();
    });
}

function onAddVerification(row: models.UpcreateCertificationVerification) {
  row.rowNumber = row?.rowNumber ?? (verifications.value.length + 1) * 10;
  verifications.value.push(row);
  sortOriginalBy(verifications.value, 'rowNumber');
}

function onUpdateVerification(rowIndex: number, row: models.UpcreateCertificationVerification) {
  verifications.value.splice(rowIndex, 1, row);
  sortOriginalBy(verifications.value, 'rowNumber');
}
</script>
<template>
  <baf-table :columns="columns" :rows="verifications" :sort="false" hover>
    <template #column-userActions="{ row, rowIndex }">
      <div class="tags">
        <certification-verification-upcreate-modal
          v-slot="{ open }"
          :verifications="verifications"
          :certification-verification="row"
          @upcreate="onUpdateVerification(rowIndex, $event)"
        >
          <a class="tag is-link is-medium" data-testid="editButton" @click.stop="open">
            <iconify-icon icon="mdi:edit"></iconify-icon>
            <span class="ml-2">{{ $t('general.edit') }}</span>
          </a>
        </certification-verification-upcreate-modal>
        <confirm-modal
          :message="$t('general.deleteConfirm', { value: row.name })"
          :confirm-callback="onDeleteVerificationCallback(row)"
          #="{ open }"
        >
          <a class="tag is-danger is-medium" data-testid="deleteButton" @click.stop="open">
            <iconify-icon icon="material-symbols:delete-outline"></iconify-icon>
            <span class="ml-2">{{ $t('general.delete') }}</span>
          </a>
        </confirm-modal>
      </div>
    </template>
  </baf-table>
  <div class="buttons is-right mr-3">
    <certification-verification-upcreate-modal
      v-slot="{ open }"
      :verifications="verifications"
      @upcreate="onAddVerification"
    >
      <button type="button" class="button is-success" @click="open">
        <iconify-icon icon="mdi:add-circle"></iconify-icon>
        <span class="ml-2">
          {{ $t('general.add') }}
        </span>
      </button>
    </certification-verification-upcreate-modal>
  </div>
</template>
