import { sortBy } from '../../functions/sort-by.js';
import { getNext10thIncrement } from '../number-utils.js';
export function nextRowNumber(rows, minimumRowNumber = 0) {
    const [serviceOrderRow] = sortBy(rows, 'rowNumber').reverse();
    let highestRowNumber = serviceOrderRow?.rowNumber ?? 0;
    if (highestRowNumber <= minimumRowNumber) {
        highestRowNumber = minimumRowNumber;
    }
    return getNext10thIncrement(highestRowNumber);
}
