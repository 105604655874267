import type { Nullish, Primitive } from 'baf-shared';

export type TableValue =
  | Primitive
  | Nullish<Primitive>
  | Primitive[]
  | Nullish<Primitive>[]
  | TableRow
  | Nullish<TableRow>
  | TableRow[]
  | Nullish<TableRow>[];

export type TableRow = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type TableColumnSelectOption = { text: string; value: string };
type TableColumnRenderer = (value: TableValue, row: TableRow) => string | string[];
interface TableColumnOptions {
  renderer: TableColumnRenderer;
  right?: boolean;
  search?: boolean;
  sort?: boolean;
  select?: boolean;
  selectOptions?: TableColumnSelectOption[];
  charactersWidth?: number;
}

interface TableColumnInput {
  key: string;
  text: string;
  options?: Partial<TableColumnOptions>;
}

export class TableColumn implements TableColumnInput {
  key!: string;
  text!: string;
  options: TableColumnOptions;

  constructor(props: TableColumnInput) {
    this.key = props.key;
    this.text = props.text;

    const options = props.options;
    this.options = {
      renderer: options?.renderer ?? this.stringifyValue,
      right: options?.right ?? false,
      search: options?.search ?? true,
      sort: options?.sort ?? true,
      select: options?.select ?? false,
      selectOptions: options?.selectOptions ?? undefined,
      charactersWidth: options?.charactersWidth,
    };
  }

  static for(key: string, text: string, options?: Partial<TableColumnOptions>) {
    return new TableColumn({
      key,
      text,
      options,
    });
  }

  private stringifyValue(value: TableValue) {
    if (Array.isArray(value)) {
      return value.join(', ');
    } else if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    } else {
      return String(value ?? '');
    }
  }
}
