<script setup lang="ts">
import { computed, toRefs } from 'vue';

const props = defineProps<{
  fullwidth?: boolean;
  narrow?: boolean;
}>();
const { fullwidth, narrow } = toRefs(props);

const rootClass = computed(() => ({
  'title-value-root-fullwidth': fullwidth.value,
  'title-value-root-narrow': narrow.value,
}));
</script>

<template>
  <dl class="title-value-root" :class="rootClass">
    <slot></slot>
  </dl>
</template>

<style lang="scss" scoped>
.title-value-root {
  max-width: fit-content;

  &-fullwidth {
    max-width: 100% !important;
  }

  &-narrow {
    :deep(.title-value) {
      padding: 0.375em 0 !important;
    }
  }
}
</style>
