import { createDataFunction } from '../../../functions/index.js';
export const createServiceOrderProject = createDataFunction({
    '@url': 'https://api.fortnox.se/3/projects/3',
    Description: 'Serviceärende',
    EndDate: '2027-03-31',
    ProjectLeader: null,
    ProjectNumber: '3',
    Status: 'ONGOING',
    StartDate: null,
    Comments: '',
    ContactPerson: '',
});
export const createServiceOrderActionProject = createDataFunction({
    '@url': 'https://api.fortnox.se/3/projects/6',
    Description: 'ServiceärenderadÅtgärd',
    EndDate: null,
    ProjectLeader: null,
    ProjectNumber: '6',
    Status: 'ONGOING',
    StartDate: null,
    Comments: '',
    ContactPerson: '',
});
export const createCertificationProject = createDataFunction({
    '@url': 'https://api.fortnox.se/3/projects/4',
    Description: 'Certifikat',
    EndDate: null,
    ProjectLeader: null,
    ProjectNumber: '4',
    Status: 'ONGOING',
    StartDate: null,
    Comments: '',
    ContactPerson: '',
});
export const createPressureTestsProject = createDataFunction({
    '@url': 'https://api.fortnox.se/3/projects/5',
    Description: 'Provtryckningar',
    EndDate: null,
    ProjectLeader: null,
    ProjectNumber: '5',
    Status: 'ONGOING',
    StartDate: null,
    Comments: '',
    ContactPerson: '',
});
export const createProjects = createDataFunction([
    createServiceOrderProject(),
    createServiceOrderActionProject(),
    createCertificationProject(),
    createPressureTestsProject(),
]);
