import { createDataFunction } from '../../functions/index.js';
import { ArticleType } from '../../models/article.js';
import { Access } from '../../models/employee.js';
import { ServiceOrderRowAddition, ServiceOrderRowStatus, ServiceOrderStatus, ServiceOrderWarehouse, } from '../../models/index.js';
import { createCustomerLundstrom, createCustomerMattson } from './customer.js';
import { createEmployeeClark, createEmployeeSkopis } from './employee.js';
import { createLabelHassleholm, createLabelKarlskrona } from './label.js';
export const createListServiceOrderReceived = createDataFunction({
    id: '00ca49af-4f91-4727-aaef-9b9740bf987f',
    fortnoxId: '305758',
    customer: createCustomerMattson(),
    employee: createEmployeeSkopis(),
    labels: [createLabelHassleholm()],
    status: ServiceOrderStatus.Received,
    statusReason: null,
    address: 'Olofs Gata 18',
    postCode: '81096 Norrås',
    deliveryAddress: 'Undre Ekgärdet 3 Norra Parkgatan 35',
    deliveryPostCode: '65709 Österfred Irland',
    warehouse: ServiceOrderWarehouse.Oskarshamn,
    lastService: '2024-04-21T14:31:32.447Z',
    created: '2023-07-04T18:38:09.176Z',
    updated: '2024-04-21T19:15:41.188Z',
    connectedServiceOrderRows: 1,
});
export const createListServiceOrderInProgress = createDataFunction({
    id: '0103208e-989d-47b1-8027-c7c843a02984',
    fortnoxId: '735789',
    customer: createCustomerLundstrom(),
    employee: createEmployeeClark(),
    labels: [createLabelKarlskrona()],
    status: ServiceOrderStatus.InProgress,
    statusReason: null,
    address: 'Södra Aspvägen 673',
    postCode: '89314 Enhult',
    deliveryAddress: 'Undre Genvägen 548 Södra Ekgärdet 4',
    deliveryPostCode: '09752 Enköping Elfenbenskusten',
    warehouse: ServiceOrderWarehouse.Vaxjo,
    lastService: '2024-04-22T12:05:03.712Z',
    created: '2024-01-06T01:52:34.459Z',
    updated: '2024-04-22T09:45:42.795Z',
    connectedServiceOrderRows: 2,
});
export const createListServiceOrders = () => [
    createListServiceOrderReceived(),
    createListServiceOrderInProgress(),
];
export const createServiceOrder = createDataFunction({
    id: '45cd69c1-7167-4cd9-bda2-0aad80928b35',
    fortnoxId: '860628',
    customer: createCustomerMattson(),
    employee: {
        id: 'bc196860-9e34-43e2-bf67-bfa72fdbde26',
        fortnoxId: '367803',
        email: 'Inger56@hotmail.com',
        inactive: false,
        firstName: 'Inger',
        lastName: 'Johansson',
        fullName: 'Inger Johansson',
        access: [Access.ServiceOrders],
        created: '2023-12-14T14:56:32.460Z',
        updated: '2024-02-20T02:33:44.300Z',
    },
    rows: [
        {
            id: 'e0734aa7-a83f-4cdb-a9e4-b6d10510db86',
            fortnoxId: '729047',
            rowNumber: 10,
            article: {
                id: 'cbc89ee7-c5b6-4ee8-9e6f-35b67211e239',
                fortnoxId: '362168',
                description: 'Rosa liten slimmad metall handskar.',
                note: 'Lila liten ergonomisk plast bord.\nRöd fantastisk liten gummi bil.\nBlå intelligent liten bomull bil.',
                ean: '1505148881115',
                certifications: ['Praktisk'],
                categories: ['Robust'],
                type: ArticleType.Article,
            },
            status: ServiceOrderRowStatus.InProgress,
            marking: '10: XYZ',
            note: 'Rosa robust liten bomull bil.\nBrun otrolig liten betong byxor.\nGul slimmad fantastisk trä bil.',
            placement: 'Southwest Hem',
            actions: [],
            marked: false,
            important: false,
            highlighted: false,
            deleted: false,
            lastService: undefined,
            nextService: '2024-12-12T22:19:18.402Z',
            created: '2024-02-10T08:10:02.039Z',
            updated: '2024-02-19T15:30:39.158Z',
        },
        {
            id: '708affd8-3069-451e-bfa3-70f6b80e3eb0',
            fortnoxId: '775074',
            rowNumber: 20,
            article: {
                id: 'f6560c05-7844-4845-8f1c-f21ec69781c5',
                fortnoxId: '384959',
                description: 'Guld fantastisk grym stål byxor.',
                note: 'Lila praktisk slimmad stål hatt.\nLila praktisk ergonomisk bomull handskar.\nKorall robust söt latex skor.',
                ean: '1373051208211',
                certifications: ['Praktisk'],
                categories: ['Slimmad'],
                type: ArticleType.Article,
            },
            status: ServiceOrderRowStatus.InProgress,
            marking: '20: ABC',
            note: 'Korall söt grym gummi byxor.\nLila otrolig fantastisk stål stol.\nBlå intelligent otrolig trä byxor.',
            placement: 'Northeast Livsmedel',
            actions: [],
            marked: true,
            important: false,
            highlighted: false,
            deleted: false,
            lastService: undefined,
            nextService: '2024-04-14T10:15:58.523Z',
            created: '2023-03-10T15:22:28.027Z',
            updated: '2024-02-19T07:39:13.995Z',
        },
        {
            id: 'd98044fe-ace3-4220-9bcb-1c88a518e0ef',
            fortnoxId: '86772',
            rowNumber: 30,
            article: {
                id: '958dbf8b-1763-4d50-9647-0352a1a4a88e',
                fortnoxId: '484935',
                description: 'Korall grym otrolig betong stol.',
                note: 'Purpur liten grym granit byxor.\nSilver praktisk grym trä bord.\nBrun slimmad fantastisk stål skor.',
                ean: '9393949531717',
                certifications: ['Praktisk'],
                categories: ['Liten'],
                type: ArticleType.Article,
            },
            status: ServiceOrderRowStatus.Finished,
            marking: undefined,
            note: 'Brun intelligent slimmad plast dator.\nBrun slimmad otrolig plast bil.\nVit fantastisk fantastisk trä bil.',
            placement: 'Northwest Skönhet',
            actions: [],
            marked: false,
            important: true,
            highlighted: true,
            deleted: true,
            lastService: undefined,
            nextService: '2024-05-27T02:25:39.814Z',
            created: '2024-02-10T16:52:37.539Z',
            updated: '2024-02-21T03:16:29.853Z',
        },
    ],
    labels: [
        {
            id: 'b197a3af-9f15-4ff5-bfa6-06d78a25d1f3',
            fortnoxId: 70,
            description: 'Sillhövda',
            fortnoxDescription: 'Sillhövda',
            created: '2024-01-11T06:43:57.231Z',
            updated: '2024-02-20T07:08:32.048Z',
        },
        {
            id: 'bb61cf05-5d5c-4679-ae2f-09465ff17ceb',
            fortnoxId: 84,
            description: 'Nättrabystrand',
            fortnoxDescription: 'Nättrabystrand',
            created: '2023-08-28T20:05:34.337Z',
            updated: '2024-02-20T07:08:31.998Z',
        },
    ],
    status: ServiceOrderStatus.InProgress,
    inspectionNumber: '99679',
    contactName: 'Katarina Mohamed',
    contactPhone: '0736777357',
    contactEmail: 'Katarina.Mohamed83@hotmail.com',
    address: 'Södra Gengränden 84',
    postCode: '14657 Båberg',
    deliveryAddress1: 'Britt-Maries Väg 64',
    deliveryAddress2: 'Jessicas Gata 851',
    deliveryPostCode: '23521',
    deliveryCity: 'Kramtorp',
    deliveryCountry: 'Italien',
    information: 'Grön intelligent liten metall skjorta.',
    note: 'Purpur grym grym metall bord.\nGuld otrolig söt gummi byxor.\nBlå söt fantastisk stål bord.',
    informationSignature: false,
    noteSignature: false,
    warehouse: ServiceOrderWarehouse.Karlskrona,
    lastService: '2024-02-19T08:04:59.774Z',
    nextService: '2024-12-19T02:51:39.203Z',
    created: '2023-08-14T04:13:11.472Z',
    updated: '2024-02-20T07:05:46.776Z',
});
export const createCopiedServiceOrder = (copiedFromId) => createDataFunction({
    id: '2529f96b-d8af-403c-9de3-afadfde59df9',
    fortnoxId: '860628',
    customer: createCustomerLundstrom(),
    employee: {
        id: '282d6db9-fe08-4c95-84bc-6136ca5bd6a3',
        fortnoxId: '431899',
        email: 'Rudolf42@gmail.com',
        inactive: false,
        firstName: 'Rudolf',
        lastName: 'Andersson',
        fullName: 'Rudolf Andersson',
        access: [Access.ServiceOrders],
        created: '2023-12-14T14:56:32.460Z',
        updated: '2024-02-20T02:33:44.300Z',
    },
    rows: [
        {
            id: 'e0734aa7-a83f-4cdb-a9e4-b6d10510db86',
            fortnoxId: '729047',
            rowNumber: 10,
            article: {
                id: 'cbc89ee7-c5b6-4ee8-9e6f-35b67211e239',
                fortnoxId: '362168',
                description: 'Rosa liten slimmad metall handskar.',
                note: 'Lila liten ergonomisk plast bord.\nRöd fantastisk liten gummi bil.\nBlå intelligent liten bomull bil.',
                ean: '1505148881115',
                certifications: ['Praktisk'],
                categories: ['Robust'],
                type: ArticleType.Article,
            },
            status: ServiceOrderRowStatus.InProgress,
            marking: '10: XYZ',
            note: 'Rosa robust liten bomull bil.\nBrun otrolig liten betong byxor.\nGul slimmad fantastisk trä bil.',
            placement: 'Southwest Hem',
            actions: [],
            marked: false,
            important: false,
            highlighted: false,
            deleted: false,
            lastService: undefined,
            nextService: '2024-12-12T22:19:18.402Z',
            created: '2024-02-10T08:10:02.039Z',
            updated: '2024-02-19T15:30:39.158Z',
        },
        {
            id: '708affd8-3069-451e-bfa3-70f6b80e3eb0',
            fortnoxId: '775074',
            rowNumber: 20,
            article: {
                id: 'f6560c05-7844-4845-8f1c-f21ec69781c5',
                fortnoxId: '384959',
                description: 'Guld fantastisk grym stål byxor.',
                note: 'Lila praktisk slimmad stål hatt.\nLila praktisk ergonomisk bomull handskar.\nKorall robust söt latex skor.',
                ean: '1373051208211',
                certifications: ['Praktisk'],
                categories: ['Slimmad'],
                type: ArticleType.Article,
            },
            status: ServiceOrderRowStatus.InProgress,
            marking: '20: ABC',
            note: 'Korall söt grym gummi byxor.\nLila otrolig fantastisk stål stol.\nBlå intelligent otrolig trä byxor.',
            placement: 'Northeast Livsmedel',
            actions: [],
            marked: true,
            important: false,
            highlighted: false,
            deleted: false,
            lastService: undefined,
            nextService: '2024-04-14T10:15:58.523Z',
            created: '2023-03-10T15:22:28.027Z',
            updated: '2024-02-19T07:39:13.995Z',
        },
        {
            id: 'd98044fe-ace3-4220-9bcb-1c88a518e0ef',
            fortnoxId: '86772',
            rowNumber: 30,
            article: {
                id: '958dbf8b-1763-4d50-9647-0352a1a4a88e',
                fortnoxId: '484935',
                description: 'Korall grym otrolig betong stol.',
                note: 'Purpur liten grym granit byxor.\nSilver praktisk grym trä bord.\nBrun slimmad fantastisk stål skor.',
                ean: '9393949531717',
                certifications: ['Praktisk'],
                categories: ['Liten'],
                type: ArticleType.Article,
            },
            status: ServiceOrderRowStatus.Finished,
            marking: undefined,
            note: 'Brun intelligent slimmad plast dator.\nBrun slimmad otrolig plast bil.\nVit fantastisk fantastisk trä bil.',
            placement: 'Northwest Skönhet',
            actions: [],
            marked: false,
            important: true,
            highlighted: true,
            deleted: true,
            lastService: undefined,
            nextService: '2024-05-27T02:25:39.814Z',
            created: '2024-02-10T16:52:37.539Z',
            updated: '2024-02-21T03:16:29.853Z',
        },
    ],
    labels: [
        {
            id: 'b197a3af-9f15-4ff5-bfa6-06d78a25d1f3',
            fortnoxId: 70,
            description: 'Sillhövda',
            fortnoxDescription: 'Sillhövda',
            created: '2024-01-11T06:43:57.231Z',
            updated: '2024-02-20T07:08:32.048Z',
        },
        {
            id: 'bb61cf05-5d5c-4679-ae2f-09465ff17ceb',
            fortnoxId: 84,
            description: 'Nättrabystrand',
            fortnoxDescription: 'Nättrabystrand',
            created: '2023-08-28T20:05:34.337Z',
            updated: '2024-02-20T07:08:31.998Z',
        },
    ],
    status: ServiceOrderStatus.InProgress,
    inspectionNumber: '99679',
    contactName: 'Katarina Mohamed',
    contactPhone: '0736777357',
    contactEmail: 'Katarina.Mohamed83@hotmail.com',
    address: 'Södra Gengränden 84',
    postCode: '14657 Båberg',
    deliveryAddress1: 'Britt-Maries Väg 64',
    deliveryAddress2: 'Jessicas Gata 851',
    deliveryPostCode: '23521',
    deliveryCity: 'Kramtorp',
    deliveryCountry: 'Italien',
    information: 'Grön intelligent liten metall skjorta.',
    note: 'Purpur grym grym metall bord.\nGuld otrolig söt gummi byxor.\nBlå söt fantastisk stål bord.',
    informationSignature: false,
    noteSignature: false,
    warehouse: ServiceOrderWarehouse.Karlskrona,
    copiedFromId,
    lastService: null,
    nextService: null,
    created: '2023-08-14T04:13:11.472Z',
    updated: '2024-02-20T07:05:46.776Z',
});
export const createUpcreateServiceOrderRow = createDataFunction({
    rowNumber: 30,
    article: {
        id: '958dbf8b-1763-4d50-9647-0352a1a4a88e',
        fortnoxId: '484935',
        description: 'Korall grym otrolig betong stol.',
        note: 'Purpur liten grym granit byxor.\nSilver praktisk grym trä bord.\nBrun slimmad fantastisk stål skor.',
        ean: '9393949531717',
        certifications: ['Praktisk'],
        categories: ['Liten'],
        type: ArticleType.Article,
    },
    marking: undefined,
    note: 'Brun intelligent slimmad plast dator.\nBrun slimmad otrolig plast bil.\nVit fantastisk fantastisk trä bil.',
    placement: 'Northwest Skönhet',
    actions: [],
    nextService: '2024-05-27T02:25:39.814Z',
    addition: ServiceOrderRowAddition.Approved,
    additionText: 'Addition text',
});
export const createUpdateServiceOrder = createDataFunction({
    contactName: 'Sven Larsson',
    contactPhone: '46 455 61 87 00',
    contactEmail: 'test@test.se',
    deliveryAddress1: 'Testvägen 1',
    deliveryPostCode: '37175',
    deliveryCity: 'Karlskrona',
    deliveryCountry: 'Sverige',
    nextService: '2024-12-19T02:51:00.000Z',
    note: 'Röd robust slimmad metall skjorta.\\nBlå grym intelligent metall hatt.\\nGrå liten söt gummi bil.\n Lager: Karlskrona',
    information: 'Ergonomisk korall dator.',
    noteSignature: true,
    informationSignature: true,
    warehouse: ServiceOrderWarehouse.Kalmar,
});
export const createUpdateServiceOrderStatusBody = createDataFunction({
    status: ServiceOrderStatus.Accepted,
    statusReason: undefined,
    lastService: undefined,
});
