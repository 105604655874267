import { IndexedDbService } from './indexed-db-service';
import { type Ref, ref } from 'vue';

class RequestIdbService extends IndexedDbService<'requests'> {
  private _numberOfOfflineChanges: Ref<number> = ref(0);

  setNumberOfOfflineChanges(changes: number) {
    this._numberOfOfflineChanges.value = changes;
  }

  getNumberOfOfflineChanges() {
    return this._numberOfOfflineChanges;
  }
}

export const requestIdbService = new RequestIdbService('requests');
