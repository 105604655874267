<script setup lang="ts">
import { useOpenable } from '@/composeables/use-openable';
import { onClickOutside } from '@vueuse/core';
import { ref, toRefs } from 'vue';

const rootElement = ref<HTMLElement>();

const props = defineProps<{
  triggerText: string;
  triggerClass?: string;
  right?: boolean;
  boxed?: boolean;
}>();
const { triggerText, triggerClass, right, boxed } = toRefs(props);

const { openValue, toggleOpen, close, elementClass } = useOpenable();

onClickOutside(rootElement, () => close());
</script>

<template>
  <div ref="rootElement" class="navbar-item has-dropdown" :class="elementClass">
    <a class="navbar-link" :aria-haspopup="openValue" :class="triggerClass" @click="toggleOpen"
      >{{ triggerText }}
    </a>

    <div class="navbar-dropdown" :class="{ 'is-right': right, 'is-boxed': boxed }">
      <slot></slot>
    </div>
  </div>
</template>
