<script setup lang="ts">
import BafNavbarDropdown from '@/components/standard/navbar/baf-navbar-dropdown.vue';
import { useAuth } from '@/composeables/use-auth';
import { useOpenable } from '@/composeables/use-openable';
import { config } from '@/config';
import { RouteName } from '@/router/models/route-name';
import { appService } from '@/services/app/app-service';
import { authService } from '@/services/auth/auth-service';
import { notificationService } from '@/services/notification/notification-service';
import { isDev } from '@/utils/env-utils';
import { models } from 'baf-shared';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n();
const { authenticated, me, logout, accessToken } = useAuth();
const { openValue, close: closeNavbar, toggleOpen, elementClass: navbarClass } = useOpenable();
const router = useRouter();

const routes = computed(() => [
  {
    name: RouteName.Root,
    text: t('general.routeName.start'),
    requiresAuthentication: false,
  },
  {
    name: RouteName.ServiceOrdersRoot,
    text: t('general.routeName.serviceOrders'),
    requiresAuthentication: true,
    requiresAccess: [models.Access.ServiceOrders],
  },
  {
    name: RouteName.CertificationsRoot,
    text: t('general.routeName.certificates'),
    requiresAuthentication: true,
    requiresAccess: [models.Access.Certificates],
  },
  {
    name: RouteName.PressureTestsRoot,
    text: t('general.routeName.pressureTests'),
    requiresAuthentication: true,
    requiresAccess: [models.Access.PressureTests],
  },
]);
const $routes = computed(() =>
  routes.value.filter((route) => {
    if (route.requiresAuthentication && route.requiresAccess) {
      const hasAccess = route.requiresAccess.every((access) => me.value?.access?.includes(access));
      return authenticated.value && hasAccess;
    }

    return true;
  }),
);

async function copyAccessToken() {
  await navigator.clipboard.writeText(accessToken.value ?? '');
}

async function copyBearerAccessToken() {
  await navigator.clipboard.writeText(`Bearer ${accessToken.value ?? ''}`);
}

function clearAccessToken() {
  authService.setState({ accessToken: undefined });
}

function clearRefreshToken() {
  authService.setState({ refreshToken: undefined });
}

function reload() {
  notificationService.open({
    text: `${t('general.reloading')}...`,
    type: 'info',
    timeout: 1500,
  });
  appService.reload();
}

const logoutAndCloseOpen = () => {
  logout();
  closeNavbar();
};
</script>

<template>
  <nav class="navbar is-primary has-shadow" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item logo" :to="{ name: RouteName.Root }" @click="closeNavbar">
        <figure class="image logo-image">
          <img src="@/assets/images/logo.webp" alt="Logo" />
        </figure>
        <span class="logo-text is-hidden-touch">{{ $t('general.appName') }}</span>
        <span class="logo-text is-hidden-desktop">{{ $t('general.appNameShort') }}</span>
      </router-link>

      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        :aria-expanded="openValue"
        :class="navbarClass"
        @click="toggleOpen"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :class="navbarClass">
      <div class="navbar-start">
        <template v-if="isDev">
          <baf-navbar-dropdown trigger-text="Devtools">
            <a class="navbar-item" @click="copyAccessToken">
              <span>Copy access token</span>
            </a>

            <a class="navbar-item" @click="copyBearerAccessToken">
              <span>Copy bearer access token</span>
            </a>

            <a class="navbar-item" @click="clearAccessToken">
              <span>Clear access token</span>
            </a>

            <a class="navbar-item" @click="clearRefreshToken">
              <span>Clear refresh token</span>
            </a>

            <hr class="navbar-divider" />

            <a
              class="navbar-item"
              :href="`${config.BACKEND_URL}/swagger`"
              target="_blank"
              rel="noopener noreferrer"
              @click="closeNavbar"
            >
              <span>Swagger</span>
            </a>
          </baf-navbar-dropdown>
        </template>

        <a class="navbar-item" @click="router.back()">
          <iconify-icon icon="mdi:arrow-back" width="20"></iconify-icon>
        </a>
        <a class="navbar-item" @click="reload">
          <iconify-icon icon="mdi:reload" width="20"></iconify-icon>
        </a>
      </div>

      <div class="navbar-end">
        <router-link
          v-for="route in $routes"
          :key="route.name"
          :to="{ name: route.name }"
          class="navbar-item"
          active-class="is-active"
          @click="closeNavbar"
        >
          {{ route.text }}
        </router-link>

        <baf-navbar-dropdown
          v-if="authenticated"
          data-testid="navbar-dropdown"
          :trigger-text="$t('general.more')"
          right
        >
          <div class="navbar-item">
            <span>{{ $t('general.loggedInAs') }}&nbsp;</span>
            <strong>{{ me?.name ?? me?.fortnoxId }}</strong>
          </div>

          <template v-if="me?.access?.includes(models.Access.Register)">
            <hr class="navbar-divider" />
            <router-link class="navbar-item" :to="{ name: RouteName.DataArticles }">
              {{ $t('general.routeName.articles') }}
            </router-link>

            <router-link class="navbar-item" :to="{ name: RouteName.DataCustomers }">
              {{ $t('general.routeName.customers') }}
            </router-link>

            <router-link class="navbar-item" :to="{ name: RouteName.DataEmployees }">
              {{ $t('general.routeName.employees') }}
            </router-link>
          </template>

          <hr class="navbar-divider" />

          <a class="navbar-item" @click="logoutAndCloseOpen">
            <span>{{ $t('general.logout') }}</span>
          </a>
        </baf-navbar-dropdown>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.logo {
  padding: 0 1em;
  font-weight: 500;

  &-image {
    margin-right: 0.75em;
    height: 28px;
    width: 28px;
  }

  &-text {
    font-size: 1.25rem;
  }
}
</style>
