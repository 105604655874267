export const setLocationHref = (href: string): void => {
  window.location.href = href;
};

export const setLocationSearch = (search: string): void => {
  window.location.search = search;
};
export const getLocationSearch = () => window.location.search;

export const getLocationOrigin = (): string => window.location.origin;
