<script setup lang="ts">
import PrintPage from '@/components/print/standard/print-page.vue';
import { models } from 'baf-shared';
import { DynamicPrintPages } from '@/components/print/dynamic-print/dynamic-print-pages';
import { toRefs } from 'vue';
import PrintReoccurringControlTable from '@/components/print/pressure-test/print-reoccurring-control-table.vue';
import type { PrintOrientation } from '@/models/print-orientation';

const props = defineProps<{
  pressureTests: models.PressureTest[];
  orientation: PrintOrientation;
}>();
const { pressureTests, orientation } = toRefs(props);

const availableHeightOfOnePageInPx = 500;
const dynamicPrintPages = new DynamicPrintPages(availableHeightOfOnePageInPx);

const pressureTestRowsPerPrintPage = 4;
let counter = 0;

while (pressureTests.value.length !== 0) {
  const pressureTestsForPage = pressureTests.value.splice(0, pressureTestRowsPerPrintPage);

  dynamicPrintPages.addComponent({
    component: PrintReoccurringControlTable,
    props: {
      pressureTests: pressureTestsForPage,
      startRowNumber: counter * pressureTestRowsPerPrintPage + 1,
    },
    height: availableHeightOfOnePageInPx,
  });

  counter += 1;
}
</script>

<template>
  <template v-for="(page, index) in dynamicPrintPages.pages" :key="index">
    <print-page
      :title="$t('general.reoccurringControl')"
      title-element="h2"
      title-text-align="left"
      text-size="0.75rem"
      data-testid="print-page"
      :orientation="orientation"
    >
      <template v-for="(component, componentIndex) in page.components" :key="componentIndex">
        <component :is="component.component" v-bind="component.props"></component>
      </template>

      <template #footer>
        <div class="is-flex">
          <figure class="swedac mr-4">
            <img src="@/assets/images/swedac_placeholder.png" alt="swedac" />
          </figure>

          <p>
            <span>{{ $t('components.printPage.companyName') }}</span>
            <br />
            <span>{{ $t('components.printPage.companyAddress') }}</span>
            <br />
            <a href="tel:+4645526500">0455-265 00</a>&nbsp;
            <a href="mailto:info@brandab.com">info@brandab.com</a>
          </p>
        </div>

        <p>{{ $t('general.reoccurringControlNumber', { number: '2402' }) }}</p>
      </template>
    </print-page>
  </template>
</template>

<style lang="scss">
.swedac {
  width: 45px;
}
</style>
