<script setup lang="ts">
import { onlineService } from '@/services/online/online-service';
import { useOnline } from '@vueuse/core';
import { utils } from 'baf-shared';
import { computed, watch } from 'vue';
import OfflineIcon from './offline-icon.vue';
import { requestIdbService } from '@/services/indexed-db/request-idb-service';

const _online = useOnline();

watch(_online, async (value) => {
  onlineService.setOnline(value);

  if (!value) {
    const changes = await requestIdbService.count();
    requestIdbService.setNumberOfOfflineChanges(changes);
  }
});

const online = computed(() => onlineService.state().value?.online);
const lastOnlineDate = computed(() => onlineService.state().value?.lastOnlineDate);
const numberOfOfflineChanges = computed(() => requestIdbService.getNumberOfOfflineChanges().value);
</script>

<template>
  <div v-if="!online" class="message is-info message-offlinebar">
    <div class="message-header">
      <span class="icon-text">
        <span class="icon mr-2">
          <offline-icon></offline-icon>
        </span>
        <span>
          {{
            $t('components.theOfflinebar.message', {
              date: utils.date.formatDateTime(lastOnlineDate),
            })
          }}
          {{ $t('components.theOfflinebar.changes', numberOfOfflineChanges) }}
        </span>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.message.message-offlinebar {
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 12px;

  .message-header {
    border-radius: 0;
  }
}
</style>
