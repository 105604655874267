import { BafError } from 'baf-shared';
import {
  type IDBPDatabase,
  type IndexNames,
  openDB,
  type StoreKey,
  type StoreNames,
  type StoreValue,
} from 'idb';
import { type BrandabFusionDbSchema, objectStores } from './schema';

const DATABASE_NAME = 'brandabFusion';
const DATABASE_VERSION = 1;

type IndexedDatabase = IDBPDatabase<BrandabFusionDbSchema>;
type ObjectStoreName = StoreNames<BrandabFusionDbSchema>;
type ObjectStoreIndex = IndexNames<BrandabFusionDbSchema, ObjectStoreName>;
type ObjectStoreSpecificValue<T extends ObjectStoreName> = StoreValue<BrandabFusionDbSchema, T>;
type ObjectStoreSpecificKey<T extends ObjectStoreName> = StoreKey<BrandabFusionDbSchema, T>;

export interface ObjectStoreParams {
  name: ObjectStoreName;
  keyPath: string;
  indexes?: {
    keyPath: ObjectStoreIndex;
    unique: boolean;
  }[];
}

export abstract class IndexedDbService<EntityStoreName extends ObjectStoreName> {
  protected _db!: IndexedDatabase;
  protected _storeName!: EntityStoreName;

  protected putAllExecuting = false;
  protected deleteAllExecuting = false;

  constructor(storeName: EntityStoreName) {
    this._storeName = storeName;
  }

  async init() {
    this._db = await openDB(DATABASE_NAME, DATABASE_VERSION, {
      upgrade: (db) => {
        for (const objectStore of objectStores) {
          const store = db.createObjectStore(objectStore.name, {
            keyPath: objectStore.keyPath,
          });

          for (const index of objectStore.indexes ?? []) {
            store.createIndex(index.keyPath, index.keyPath, {
              unique: index.unique,
            });
          }
        }
      },
    });
  }

  get(key: ObjectStoreSpecificKey<EntityStoreName>) {
    return this._db.get(this._storeName, key);
  }

  async getOrThrow(key: ObjectStoreSpecificKey<EntityStoreName>) {
    const item = await this._db.get(this._storeName, key);
    if (!item) {
      throw new BafError(`No item in ${this._storeName} for key ${key}`);
    }
    return item;
  }

  count(): Promise<number> {
    return this._db.count(this._storeName);
  }

  getAll() {
    return this._db.getAll(this._storeName);
  }

  async put(value: ObjectStoreSpecificValue<EntityStoreName>) {
    await this._db.put(this._storeName, value);
  }

  async putAll(values: ObjectStoreSpecificValue<EntityStoreName>[]): Promise<void> {
    if (this.putAllExecuting) {
      return;
    }

    this.putAllExecuting = true;

    const tx = this._db.transaction(this._storeName, 'readwrite');

    const puts = values.map((value) => this.put(value));
    await Promise.all(puts);
    await tx.done;

    this.putAllExecuting = false;
  }

  async delete(key: ObjectStoreSpecificKey<EntityStoreName>) {
    const beforeDelete = this.getOrThrow(key);
    await this._db.delete(this._storeName, key);
    return beforeDelete;
  }

  async deleteAll() {
    if (this.deleteAllExecuting) {
      return;
    }
    this.deleteAllExecuting = true;

    const values = await this.getAll();

    const tx = this._db.transaction(this._storeName, 'readwrite');

    const deletes = values.map((value) => this._db.delete(this._storeName, value.id));
    await Promise.all(deletes);
    await tx.done;

    this.deleteAllExecuting = false;
  }
}
