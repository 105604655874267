import { createDataFunction } from '../../functions/index.js';
import { Access } from '../../models/index.js';
export const createEmployeeClark = createDataFunction({
    id: 'd6cfdd52-68ff-42ca-925b-6f9b4cd3ce56',
    fortnoxId: '555',
    inactive: false,
    email: 'clak@test.se',
    firstName: 'Clark',
    lastName: 'Kent',
    fullName: 'Clark Kent',
    jobTitle: 'cert,se,pt',
    created: '2024-01-03T07:53:48.920Z',
    updated: '2024-01-03T07:56:07.526Z',
    access: [Access.Certificates, Access.ServiceOrders, Access.PressureTests],
});
export const createListEmployeeClark = createDataFunction({
    ...createEmployeeClark(),
    assignedServiceOrders: 12,
    assignedServiceOrderRows: 33,
});
export const createEmployeeSkopis = createDataFunction({
    id: '8f749a4a-216f-4d46-b709-fe5fbed54420',
    fortnoxId: '2',
    inactive: false,
    email: 'skopis.gravlastare@test.se',
    firstName: 'Skopis',
    lastName: 'Grävlastare',
    fullName: 'Skopis Grävlastare',
    jobTitle: '',
    created: '2024-01-03T07:53:48.939Z',
    updated: '2024-01-03T07:56:07.540Z',
    access: [],
});
export const createListEmployeeSkopis = createDataFunction({
    ...createEmployeeSkopis(),
    assignedServiceOrders: 6,
    assignedServiceOrderRows: 22,
});
export const createEmployees = createDataFunction([
    createEmployeeClark(),
    createEmployeeSkopis(),
]);
export const createListEmployees = createDataFunction([
    createListEmployeeClark(),
    createListEmployeeSkopis(),
]);
