<script setup lang="ts">
import PrintPage from '@/components/print/standard/print-page.vue';
import TitleValueRoot from '@/components/standard/title-value/title-value-root.vue';
import TitleValue from '@/components/standard/title-value/title-value.vue';
import { models, utils } from 'baf-shared';
import { computed, toRefs } from 'vue';
import { DynamicPrintPages } from '@/components/print/dynamic-print/dynamic-print-pages';
import PrintInspectionCertificateTable from '@/components/print/pressure-test/print-inspection-certificate-table.vue';
import type { PrintOrientation } from '@/models/print-orientation';

const props = defineProps<{
  orientation: PrintOrientation;
  pressureTests: models.PressureTest[];
}>();
const { orientation, pressureTests } = toRefs(props);

const asset = computed(() => {
  const [firstPressureTest] = pressureTests.value;
  const { asset } = firstPressureTest ?? {};
  return asset;
});

const assetContact = computed(() => {
  return utils.joinValues([asset.value?.contactName, asset.value?.contactPhone], '\n');
});

const customer = computed(() => {
  const [firstPressureTest] = pressureTests.value;
  const { customer } = firstPressureTest ?? {};
  return customer;
});

const customerPostcodeAndCity = computed(() => {
  return utils.joinValues(
    [customer.value?.name, customer.value?.postCode, customer.value?.city],
    ' ',
  );
});

const customerAddress = computed(() => {
  return utils.joinValues(
    [customer.value?.address1, customer.value?.address2, customerPostcodeAndCity.value],
    '\n',
  );
});

const availableHeightOfOnePageInPx = 500;
const dynamicPrintPages = new DynamicPrintPages(availableHeightOfOnePageInPx);

const pressureTestRowsPerPrintPage = 5;
let counter = 0;

const copyOfPressureTests = utils.json.copy(pressureTests.value);
while (copyOfPressureTests.length !== 0) {
  const pressureTestsForPage = copyOfPressureTests.splice(0, pressureTestRowsPerPrintPage);

  dynamicPrintPages.addComponent({
    component: PrintInspectionCertificateTable,
    props: {
      pressureTests: pressureTestsForPage,
      startRowNumber: counter * pressureTestRowsPerPrintPage + 1,
    },
    height: availableHeightOfOnePageInPx,
  });

  counter += 1;
}
</script>

<template>
  <template v-for="(page, index) in dynamicPrintPages.pages" :key="index">
    <print-page
      :title="$t('general.inspectionCertificate')"
      title-element="h2"
      title-text-align="left"
      text-size="0.75rem"
      data-testid="print-page"
      :orientation="orientation"
    >
      <div class="columns is-multiline">
        <div class="column is-4">
          <title-value-root>
            <title-value :title="$t('general.customerNumber')" :value="customer?.fortnoxId" block>
            </title-value>
          </title-value-root>
        </div>

        <div class="column is-4">
          <title-value-root>
            <title-value :title="$t('general.phoneNumber')" :value="customer?.phone" block>
            </title-value>
          </title-value-root>
        </div>

        <div class="column is-4"></div>

        <div class="column is-4">
          <title-value-root>
            <title-value :title="$t('general.customer')" block render-as-is>
              {{ customerAddress }}
            </title-value>
          </title-value-root>
        </div>

        <div class="column is-4">
          <title-value-root>
            <title-value :title="$t('general.contact')" block render-as-is>
              {{ assetContact }}
            </title-value>
          </title-value-root>
        </div>

        <div class="column is-4">
          <title-value-root>
            <title-value :title="$t('general.address')" :value="asset?.name" block> </title-value>
          </title-value-root>
        </div>
      </div>

      <template v-for="(component, componentIndex) in page.components" :key="componentIndex">
        <component :is="component.component" v-bind="component.props"></component>
      </template>

      <template #pre-footer>
        <title-value-root class="is-flex is-justify-content-space-between pb-2">
          <title-value
            :title="`${$t('general.date')}:`"
            default-value=""
            class="pr-4"
          ></title-value>
          <title-value
            :title="`${$t('general.certificationCity')}:`"
            default-value=""
            class="px-4"
          ></title-value>
          <title-value
            :title="`${$t('general.signature')}:`"
            default-value=""
            class="pl-4"
          ></title-value>
        </title-value-root>
      </template>
      <template #footer>
        <div class="is-flex">
          <figure class="swedac mr-4">
            <img src="@/assets/images/swedac_placeholder.png" alt="swedac" />
          </figure>

          <p>
            <span>{{ $t('components.printPage.companyName') }}</span>
            <br />
            <span>{{ $t('components.printPage.companyAddress') }}</span>
            <br />
            <a href="tel:+4645526500">0455-265 00</a>&nbsp;
            <a href="mailto:info@brandab.com">info@brandab.com</a>
          </p>
        </div>
      </template>
    </print-page>
  </template>
</template>

<style lang="scss">
//@media print {
//  @page {
//    margin: 0;
//    size: portrait;
//  }
//}

.swedac {
  width: 45px;
}
</style>
