import type { models } from 'baf-shared';
import type { DBSchema } from 'idb';
import type { FailedToFetchRequest } from '../standard/failed-to-fetch-service';
import type { ObjectStoreParams } from './indexed-db-service';

interface StandardDBSchemaValue<TModel> {
  key: string;
  value: TModel;
  indexes: {
    id: string;
    created: string;
    updated: string;
  };
}

const createDefaultObjectStoreParams = (
  name: ObjectStoreParams['name'],
  keyPath = 'id',
  indexes?: ObjectStoreParams['indexes'],
): ObjectStoreParams => {
  const STANDARD_INDEXES: ObjectStoreParams['indexes'] = [
    { keyPath: 'id', unique: true },
    { keyPath: 'created', unique: false },
    { keyPath: 'updated', unique: false },
  ];

  return {
    name,
    keyPath,
    indexes: indexes ?? STANDARD_INDEXES,
  };
};

export interface BrandabFusionDbSchema extends DBSchema {
  articles: StandardDBSchemaValue<models.Article>;

  certifications: StandardDBSchemaValue<models.Certification>;
  listCertifications: StandardDBSchemaValue<models.ListCertification>;

  customers: StandardDBSchemaValue<models.Customer>;

  employees: StandardDBSchemaValue<models.Employee>;
  listEmployees: StandardDBSchemaValue<models.ListEmployee>;

  labels: StandardDBSchemaValue<models.Label>;

  serviceOrders: StandardDBSchemaValue<models.ServiceOrder>;
  listServiceOrders: StandardDBSchemaValue<models.ListServiceOrder>;

  requests: StandardDBSchemaValue<FailedToFetchRequest>;
}

export const objectStores: ObjectStoreParams[] = [
  createDefaultObjectStoreParams('articles'),

  createDefaultObjectStoreParams('certifications'),
  createDefaultObjectStoreParams('listCertifications'),

  createDefaultObjectStoreParams('customers'),

  createDefaultObjectStoreParams('employees'),
  createDefaultObjectStoreParams('listEmployees'),

  createDefaultObjectStoreParams('labels'),

  createDefaultObjectStoreParams('serviceOrders'),
  createDefaultObjectStoreParams('listServiceOrders'),

  createDefaultObjectStoreParams('requests'),
];
