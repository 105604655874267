<script setup lang="ts">
import BafModal from '@/components/standard/modal/baf-modal.vue';
import BafSearchSelect from '@/components/standard/search-select/baf-search-select.vue';
import { useLoading } from '@/composeables/use-loading';
import { labelService } from '@/services/label/label-service';
import { serviceOrderService } from '@/services/service-order/service-order-service';
import { models } from 'baf-shared';
import { onBeforeMount, ref, toRefs } from 'vue';

type MinimalLabel = {
  id: string;
  description: string;
};

type FormData = {
  labels: MinimalLabel[];
};

function createFormData(serviceOrder?: models.ServiceOrder | models.ListServiceOrder): FormData {
  const labels = Array.from(serviceOrder?.labels.map(toMinimalLabel) ?? []);

  return {
    labels,
  };
}

const modal = ref<InstanceType<typeof BafModal>>();

const props = defineProps<{
  serviceOrder: models.ServiceOrder | models.ListServiceOrder;
  serviceOrderIds?: string[];
}>();
const { serviceOrder, serviceOrderIds } = toRefs(props);

const emit = defineEmits<{
  (e: 'update', serviceOrder: models.ServiceOrder): void;
}>();

const { startLoading, stopLoading, loadingClass } = useLoading();

const labels = ref<MinimalLabel[]>([]);
const formData = ref(createFormData(serviceOrder.value));

function onCancel() {
  formData.value = createFormData(serviceOrder.value);
  modal.value?.close();
}

function toMinimalLabel(label: models.Label): MinimalLabel {
  return {
    id: label.id,
    description: label.description,
  };
}

onBeforeMount(async () => {
  const _labels = await labelService.getAll();
  labels.value = _labels?.map(toMinimalLabel);
});

async function onSubmit() {
  try {
    startLoading();

    const { id: serviceOrderId } = serviceOrder.value;
    const labelIds = formData.value.labels.map((label) => label.id);

    if (serviceOrderIds.value) {
      const updatedServiceOrders = await serviceOrderService.updateLabelsOnServiceOrders(
        serviceOrderIds.value,
        labelIds,
      );
      formData.value = createFormData(updatedServiceOrders[0]);
      updatedServiceOrders.forEach((updatedServiceOrder) => emit('update', updatedServiceOrder));
    } else {
      const updatedServiceOrder = await serviceOrderService.updateLabels(serviceOrderId, labelIds);
      formData.value = createFormData(updatedServiceOrder);
      emit('update', updatedServiceOrder);
    }
    modal.value?.close();
  } finally {
    stopLoading();
  }
}
</script>

<template>
  <baf-modal ref="modal" :title="$t('general.updateLabels')" tag="form" @submit.prevent="onSubmit">
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <div class="field">
        <label class="label" for="serviceOrderLabels">{{ $t('general.labels') }}</label>
        <div class="control">
          <baf-search-select
            id="serviceOrderLabels"
            v-model="formData.labels"
            :values="labels"
            value-key="id"
            text-key="description"
          ></baf-search-select>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="button"
        data-testid="cancelButton"
        :class="loadingClass"
        @click="onCancel"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        type="submit"
        class="button is-primary"
        data-testid="updateButton"
        :class="loadingClass"
      >
        {{ $t('general.update') }}
      </button>
    </template>
  </baf-modal>
</template>
