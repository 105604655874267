<script setup lang="ts">
import type { Nullish, Primitive } from 'baf-shared';
import { toRefs } from 'vue';

const props = withDefaults(
  defineProps<{
    title: string;
    value?: Nullish<Primitive>;
    block?: boolean;
    renderAsIs?: boolean;
    defaultValue?: string;
  }>(),
  {
    value: undefined,
    defaultValue: '-',
    block: false,
    renderAsIs: false,
  },
);

const { title, value } = toRefs(props);
</script>

<template>
  <div class="title-value" :class="{ 'is-block': block }">
    <dt class="title-value-title">{{ title }}</dt>

    <dd class="title-value-value" :class="{ 'render-as-is': renderAsIs }">
      <slot>{{ value || defaultValue }} </slot>
    </dd>
  </div>
</template>

<style lang="scss" scoped>
.title-value {
  padding: 1em 0;
  display: flex;
  justify-content: space-between;

  &-title {
    font-weight: bold;
    margin-right: 2em;
  }

  &.is-block {
    padding: 0;

    .title-value-value {
      margin-left: 0;
    }
  }
}
</style>
