<script setup lang="ts">
import BafTable from '@/components/standard/table/baf-table.vue';
import CategoryValueUpcreateModal from '@/components/category-value/category-value-upcreate-modal.vue';
import {
  TableColumn,
  type TableColumnSelectOption,
  type TableValue,
} from '@/components/standard/table/models/table';
import { useLoading } from '@/composeables/use-loading';
import { categoryValueService } from '@/services/category-value/category-value-service';
import { models } from 'baf-shared';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import ConfirmModal from '@/components/modal/confirm-modal.vue';

const { t } = useI18n();
const route = useRoute();
const { loading, startLoading, stopLoading } = useLoading();
const categoryValues = ref<models.CategoryValue[]>([]);

const categoriesSelectOptions = Object.values(models.CategoryValueCategory).map((value) => {
  const translated = t(`general.enums.categoryValueCategory.${value}`);
  return {
    value: translated,
    text: translated,
  } as TableColumnSelectOption;
});

const columns = [
  TableColumn.for('userActions', '', {
    charactersWidth: 1,
    search: false,
    sort: false,
  }),
  TableColumn.for('category', t('general.category'), {
    renderer: (value) => t(`general.enums.categoryValueCategory.${String(value)}`),
    select: true,
    selectOptions: categoriesSelectOptions,
  }),
  TableColumn.for('value', t('general.value')),
];

const loadCategoryValues = async () => {
  try {
    startLoading();
    categoryValues.value = await categoryValueService.getAll();
  } finally {
    stopLoading();
  }
};

defineExpose({
  loadCategoryValues,
});

const getCategoryValueDeleteConfirmText = (row: models.CategoryValue) => {
  const translatedCategory = t(`general.enums.categoryValueCategory.${row.category}`);
  return `${translatedCategory}: ${row.value}`;
};

function onDeleteLabelCallback(id: TableValue) {
  const labelId = id as string;
  return async () => {
    try {
      startLoading();
      await categoryValueService.delete(labelId);
      categoryValues.value = await categoryValueService.getAll();
    } finally {
      stopLoading();
    }
  };
}

onBeforeMount(async () => {
  await loadCategoryValues();
});
</script>

<template>
  <baf-table
    :columns="columns"
    :rows="categoryValues"
    :loading="loading"
    :search-query="route.query"
    :sort-default="{ key: 'category' }"
    search
    paginate
    hover
  >
    <template #column-userActions="{ row }">
      <div class="tags">
        <category-value-upcreate-modal
          v-slot="{ open }"
          :category-value="row"
          @upcreate="loadCategoryValues"
        >
          <a class="tag is-link is-medium" data-testid="editButton" @click.stop="open">
            <iconify-icon icon="mdi:edit"></iconify-icon>
            <span class="ml-2">{{ $t('general.edit') }}</span>
          </a>
        </category-value-upcreate-modal>
        <confirm-modal
          :message="$t('general.deleteConfirm', { value: getCategoryValueDeleteConfirmText(row) })"
          :confirm-callback="onDeleteLabelCallback(row.id)"
          #="{ open }"
        >
          <a class="tag is-danger is-medium" data-testid="deleteButton" @click.stop="open">
            <iconify-icon icon="material-symbols:delete-outline"></iconify-icon>
            <span class="ml-2">{{ $t('general.delete') }}</span>
          </a>
        </confirm-modal>
      </div>
    </template>
  </baf-table>
</template>
