import { createDataFunction } from '../../../functions/index.js';
import { createLabelHassleholm } from './label.js';
import { createServiceOrderProject } from './project.js';
const serviceOrderProject = createServiceOrderProject();
const label = createLabelHassleholm();
export const createOrder = createDataFunction({
    '@url': 'https://api.fortnox.se/3/orders/1',
    '@urlTaxReductionList': 'https://api.fortnox.se/3/taxreductions?filter=orders&referencenumber=1',
    AdministrationFee: 0,
    AdministrationFeeVAT: 0,
    Address1: 'Testvägen 1',
    Address2: '',
    BasisTaxReduction: 0,
    Cancelled: false,
    City: 'Karlskrona',
    Comments: 'Test',
    ContributionPercent: 100,
    ContributionValue: 50,
    CopyRemarks: true,
    Country: 'Sverige',
    CostCenter: '',
    Currency: 'SEK',
    CurrencyRate: 1,
    CurrencyUnit: 1,
    CustomerName: 'Softhouse Sydost AB',
    CustomerNumber: '1',
    DeliveryState: 'delivery',
    DeliveryAddress1: 'Testgatan 102',
    DeliveryAddress2: 'Testgatan 202',
    DeliveryCity: 'Karlskrona',
    DeliveryCountry: 'Sverige',
    DeliveryDate: '2024-02-29',
    DeliveryName: 'Nils-Karlsson AB',
    DeliveryZipCode: '37132',
    DocumentNumber: '1',
    EmailInformation: {
        EmailAddressFrom: '',
        EmailAddressTo: 'test@test.se',
        EmailAddressCC: '',
        EmailAddressBCC: '',
        EmailSubject: 'Order {no} bifogas',
        EmailBody: ' ',
    },
    ExternalInvoiceReference1: '',
    ExternalInvoiceReference2: '',
    Freight: 0,
    FreightVAT: 0,
    Gross: 50,
    HouseWork: false,
    InvoiceReference: '0',
    Labels: [label],
    Language: 'SV',
    Net: 50,
    NotCompleted: false,
    OfferReference: '0',
    OrderDate: '2023-12-07',
    OrderRows: [
        {
            AccountNumber: 3001,
            ArticleNumber: '2',
            ContributionPercent: '100',
            ContributionValue: '50',
            Cost: 0,
            CostCenter: null,
            DeliveredQuantity: '1',
            Description: 'Brandsläckare Vatten Housegard 6 L',
            Discount: 0,
            DiscountType: 'PERCENT',
            HouseWork: false,
            HouseWorkHoursToReport: null,
            HouseWorkType: null,
            OrderedQuantity: '1',
            Price: 50,
            Project: '3',
            ReservedQuantity: '1',
            RowId: 2,
            StockPointCode: null,
            StockPointId: null,
            Total: 50,
            Unit: 'st',
            VAT: 25,
            VATCode: 'MP1',
        },
        {
            AccountNumber: 3001,
            ArticleNumber: '3',
            ContributionPercent: '100',
            ContributionValue: '50',
            Cost: 0,
            CostCenter: null,
            DeliveredQuantity: '1',
            Description: '- tillsyn',
            Discount: 0,
            DiscountType: 'PERCENT',
            HouseWork: false,
            HouseWorkHoursToReport: null,
            HouseWorkType: null,
            OrderedQuantity: '1',
            Price: 50,
            Project: '6',
            ReservedQuantity: '1',
            RowId: 3,
            StockPointCode: null,
            StockPointId: null,
            Total: 50,
            Unit: 'st',
            VAT: 25,
            VATCode: 'MP1',
        },
    ],
    OrderType: 'Order',
    OrganisationNumber: '',
    OurReference: 'Clark Kent',
    Phone1: '46 455 61 87 00',
    Phone2: '',
    PriceList: 'A',
    PrintTemplate: 'oc',
    Project: serviceOrderProject.ProjectNumber,
    WarehouseReady: true,
    OutboundDate: '2023-12-07',
    Remarks: 'HMS Visby, Test',
    RoundOff: 0.5,
    Sent: true,
    TaxReduction: null,
    TermsOfDelivery: 'FVL',
    TermsOfPayment: 'K',
    TimeBasisReference: 0,
    Total: 63,
    TotalToPay: 63,
    TotalVAT: 12.5,
    VATIncluded: false,
    WayOfDelivery: 'HÄM',
    YourReference: 'Sven Larsson',
    YourOrderNumber: '',
    ZipCode: '37175',
    StockPointCode: null,
    StockPointId: null,
    TaxReductionType: 'none',
});
