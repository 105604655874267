import { createDataFunction } from '../../functions/index.js';
export const createCustomerLundstrom = createDataFunction({
    id: '00419cb6-0029-47e3-be42-e04c2f537f0b',
    fortnoxId: '692712',
    email: 'LundstromInvestment93@gmail.com',
    name: 'Lundström Investment',
    organisationNumber: '5280423315767296',
    created: '2023-10-09T00:25:44.129Z',
    updated: '2024-01-16T09:46:04.245Z',
    address1: 'Lundström Palats',
    address2: '14',
    city: 'Stockholm',
    country: 'Sverige',
    phone: '0761132441',
    postCode: '37171',
});
export const createCustomerMattson = createDataFunction({
    id: '00829f29-134f-4a1b-b14b-bd1824bfecc5',
    fortnoxId: '846822',
    email: 'Mattsson-Eklund.36@yahoo.com',
    name: 'Mattsson-Eklund',
    organisationNumber: '977223664271360',
    created: '2023-02-24T03:58:01.184Z',
    updated: '2024-01-16T16:44:27.514Z',
    address1: 'Mattson Palats',
    address2: '14',
    city: 'Stockholm',
    country: 'Sverige',
    phone: '635-185-7302',
    postCode: '37175',
});
