<script setup lang="ts">
import { ref } from 'vue';
import CertificationAssetTable from '@/components/certification-asset/certification-asset-table.vue';
import CertificationAssetUpcreateModal from '@/components/certification-asset/certification-asset-upcreate-modal.vue';

const assetTable = ref<InstanceType<typeof CertificationAssetTable>>();
</script>

<template>
  <section class="section">
    <div class="buttons is-right">
      <certification-asset-upcreate-modal #="{ open }" @upcreate="assetTable?.loadAssets">
        <button class="button is-success" data-testid="createButton" @click.stop="open">
          <iconify-icon icon="mdi:add-circle"></iconify-icon>
          <span class="ml-2">{{ $t('general.assetCreate') }}</span>
        </button>
      </certification-asset-upcreate-modal>
    </div>
  </section>

  <section class="section">
    <div class="box">
      <certification-asset-table ref="assetTable"></certification-asset-table>
    </div>
  </section>
</template>
