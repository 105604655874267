<script setup lang="ts">
import { models, utils } from 'baf-shared';
import { ref, toRefs } from 'vue';
import BafModal from '@/components/standard/modal/baf-modal.vue';
import { useLoading } from '@/composeables/use-loading';
import BafSearchSelect from '@/components/standard/search-select/baf-search-select.vue';
import { printData } from '@/utils/print-utils';
import { PrintTypeQuery } from '@/models/print-type-query';

const props = defineProps<{
  customers: models.Customer[];
  sequentialNumberId: string;
}>();

const { customers, sequentialNumberId } = toRefs(props);

const { loadingClass } = useLoading();
const modal = ref<InstanceType<typeof BafModal>>();

function onClickPrint() {
  printData(
    PrintTypeQuery.InspectionCertificate,
    sequentialNumberId.value,
    formData.value.customer.id,
  );
  modal.value?.close();
}

type FormData = {
  customer: models.Customer;
  warehouse: models.PressureTestSequentialNumberWarehouse;
};
function createFormData(): FormData {
  return {
    customer: utils.type.asType<models.Customer>(null),
    warehouse: utils.type.asType<models.PressureTestSequentialNumberWarehouse>(null),
  };
}

const formData = ref(createFormData());

function onCancel() {
  formData.value = createFormData();
  modal.value?.close();
}
</script>

<template>
  <baf-modal
    ref="modal"
    :title="`${$t('general.printInspectionCertificate')}`"
    tag="form"
    @submit.prevent
  >
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <div class="field">
        <label class="label" for="pressureTestCustomerPrintInspectionCertificate">{{
          $t('general.customer')
        }}</label>
        <div class="control">
          <baf-search-select
            id="pressureTestCustomerPrintInspectionCertificate"
            v-model="formData.customer"
            required
            data-testid="pressureTestCustomerPrintInspectionCertificateSelect"
            :values="customers"
            value-key="id"
            text-key="name"
          ></baf-search-select>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="button"
        data-testid="cancelButton"
        :class="loadingClass"
        @click="onCancel"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        type="button"
        class="button is-primary"
        data-testid="printButton"
        :class="loadingClass"
        @click="onClickPrint"
      >
        {{ $t('general.print') }}
      </button>
    </template>
  </baf-modal>
</template>

<style scoped lang="scss"></style>
