<script setup lang="ts">
import BafModal from '@/components/standard/modal/baf-modal.vue';
import { useLoading } from '@/composeables/use-loading';
import { serviceOrderService } from '@/services/service-order/service-order-service';
import { models, utils } from 'baf-shared';
import { computed, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

type FormData = models.UpdateServiceOrder & { note: string; information: string };

const WAREHOUSES = Object.values(models.ServiceOrderWarehouse).map((warehouse) => ({
  text: t(`general.enums.warehouse.${warehouse}`),
  value: warehouse,
}));
const warehouses = computed(() => WAREHOUSES);

function createFormData(serviceOrder: models.ServiceOrder): FormData {
  const nextService =
    serviceOrder?.nextService ??
    utils.date.changeDate({
      date: new Date(),
      delta: 12,
      unit: 'months',
    });

  return {
    contactName: serviceOrder?.contactName ?? '',
    contactPhone: serviceOrder?.contactPhone ?? '',
    contactEmail: serviceOrder?.contactEmail ?? '',
    deliveryAddress1: serviceOrder?.deliveryAddress1 ?? '',
    deliveryPostCode: serviceOrder?.deliveryPostCode ?? '',
    deliveryCity: serviceOrder?.deliveryCity ?? '',
    deliveryCountry: serviceOrder?.deliveryCountry ?? '',
    lastService: utils.date.formatDateTimeLocal(serviceOrder?.lastService) ?? undefined,
    nextService: utils.date.formatDateTimeLocal(nextService) ?? undefined,
    note: serviceOrder?.note ?? '',
    information: serviceOrder?.information ?? '',
    noteSignature: serviceOrder?.noteSignature ?? false,
    informationSignature: serviceOrder?.informationSignature ?? false,
    warehouse: serviceOrder.warehouse ?? models.ServiceOrderWarehouse.Karlskrona,
  };
}
const { startLoading, stopLoading, loadingClass } = useLoading();
const modal = ref<InstanceType<typeof BafModal>>();

const props = defineProps<{
  serviceOrder: models.ServiceOrder;
}>();
const { serviceOrder } = toRefs(props);

const emit = defineEmits<{
  (e: 'update', serviceOrder: models.ServiceOrder): void;
}>();

const formData = ref(createFormData(serviceOrder.value));

function onCancel() {
  formData.value = createFormData(serviceOrder.value);
  modal.value?.close();
}

async function onSubmit() {
  try {
    startLoading();
    const updatedServiceOrder = await updateServiceOrder();
    formData.value = createFormData(updatedServiceOrder);

    emit('update', updatedServiceOrder);
    modal.value?.close();
  } finally {
    stopLoading();
  }
}

function updateServiceOrder(): Promise<models.ServiceOrder> {
  const updateServiceOrderFormData = {
    ...formData.value,
    lastService: utils.date.formatDateTimeISO(formData.value.lastService),
    nextService: utils.date.formatDateTimeISO(formData.value.nextService),
  };

  return serviceOrderService.updateServiceOrder(serviceOrder.value.id, updateServiceOrderFormData);
}
</script>

<template>
  <baf-modal
    ref="modal"
    :title="$t('general.serviceOrderUpdate')"
    tag="form"
    large
    @submit.prevent="onSubmit"
  >
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <div class="columns is-multiline">
        <div class="column">
          <div class="field">
            <label class="label" for="serviceOrderWarehouse">{{ $t('general.warehouse') }}</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select
                  id="serviceOrderWarehouse"
                  v-model="formData.warehouse"
                  required
                  data-testid="serviceOrderWarehouseSelect"
                >
                  <option
                    v-for="(warehouse, index) in warehouses"
                    :key="index"
                    :value="warehouse.value"
                  >
                    {{ warehouse.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label" for="serviceOrderContactName">
              {{ $t('general.contactName') }}</label
            >
            <div class="control">
              <input
                id="serviceOrderContactName"
                v-model="formData.contactName"
                data-testid="serviceOrderContactNameInput"
                class="input"
              />
            </div>
          </div>
          <div class="field">
            <label class="label" for="serviceOrderContactPhone">
              {{ $t('general.phoneNumber') }}
            </label>
            <div class="control">
              <input
                id="serviceOrderContactPhone"
                v-model="formData.contactPhone"
                data-testid="serviceOrderContactPhoneInput"
                class="input"
              />
            </div>
          </div>
          <div class="field">
            <label class="label" for="serviceOrderContactEmail"> {{ $t('general.email') }} </label>
            <div class="control">
              <input
                id="serviceOrderContactEmail"
                v-model="formData.contactEmail"
                data-testid="serviceOrderContactEmailInput"
                class="input"
                type="email"
              />
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label" for="serviceOrderDeliveryAddress">
                  {{ $t('general.deliveryAddress') }}
                </label>
                <div class="control">
                  <input
                    id="serviceOrderDeliveryAddress"
                    v-model="formData.deliveryAddress1"
                    data-testid="serviceOrderDeliveryAddressInput"
                    class="input"
                  />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label" for="serviceOrderDeliveryCity">
                  {{ $t('general.deliveryCity') }}
                </label>
                <div class="control">
                  <input
                    id="serviceOrderDeliveryCity"
                    v-model="formData.deliveryCity"
                    data-testid="serviceOrderDeliveryCityInput"
                    class="input"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label" for="serviceOrderDeliveryPostCode">
                  {{ $t('general.deliveryPostCode') }}
                </label>
                <div class="control">
                  <input
                    id="serviceOrderDeliveryPostCode"
                    v-model="formData.deliveryPostCode"
                    data-testid="serviceOrderDeliveryPostCodeInput"
                    class="input"
                  />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label" for="serviceOrderDeliveryCountry">
                  {{ $t('general.deliveryCountry') }}
                </label>
                <div class="control">
                  <input
                    id="serviceOrderDeliveryCountry"
                    v-model="formData.deliveryCountry"
                    data-testid="serviceOrderDeliveryCountryInput"
                    class="input"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label" for="serviceOrderLastService">{{
              $t('general.lastService')
            }}</label>
            <div class="control">
              <input
                id="serviceOrderLastService"
                v-model="formData.lastService"
                data-testid="serviceOrderLastServiceInput"
                type="datetime-local"
                class="input"
              />
            </div>
          </div>

          <div class="field">
            <label class="label" for="serviceOrderNextService">{{
              $t('general.nextService')
            }}</label>
            <div class="control">
              <input
                id="serviceOrderNextService"
                v-model="formData.nextService"
                data-testid="serviceOrderNextServiceInput"
                type="datetime-local"
                class="input"
              />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="button"
        data-testid="cancelButton"
        :class="loadingClass"
        @click="onCancel()"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        type="submit"
        class="button is-primary"
        data-testid="updateButton"
        :class="loadingClass"
      >
        {{ $t('general.update') }}
      </button>
    </template>
  </baf-modal>
</template>
