<script setup lang="ts">
import BafSearchSelect from '@/components/standard/search-select/baf-search-select.vue';
import { useLoading } from '@/composeables/use-loading';
import { articleService } from '@/services/article/article-service';
import { models, utils } from 'baf-shared';
import { onBeforeMount, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import BafModal from '../../standard/modal/baf-modal.vue';

type FormData = models.UpcreateCertificationRow & {
  data: models.FireExtinguisher & { note?: string };
};

const { t } = useI18n();
const { startLoading, stopLoading, loadingClass } = useLoading();
const modal = ref<InstanceType<typeof BafModal>>();

const emit = defineEmits<{
  (e: 'upcreate', value: models.UpcreateCertificationRow): void;
}>();

const props = withDefaults(
  defineProps<{
    rows?: models.UpcreateCertificationRow[];
    certificationRow?: models.UpcreateCertificationRow;
  }>(),
  {
    rows: () => [],
    certificationRow: undefined,
  },
);

const { rows, certificationRow } = toRefs(props);
watch(certificationRow, () => {
  formData.value = createFormData(certificationRow.value);
});

const isUpdating = !!certificationRow.value;
const formData = ref(createFormData(certificationRow.value));
const articles = ref<models.Article[]>([]);
const article = ref<models.Article>(utils.type.asType<models.Article>(null));
const titleAction = isUpdating
  ? t('general.certificationRowUpdate')
  : t('general.certificationRowCreate');
const title = `${titleAction} - ${t('general.enums.certificationType.FireExtinguisher')}`;

function onUpdateArticle(article: models.Article) {
  if (article) {
    formData.value.data.name = article.description;
  }
}

function createFormData(row?: models.UpcreateCertificationRow): FormData {
  const data = utils.json.copy(row?.data ?? {}) as models.FireExtinguisher;
  const rowNumber = utils.number.getNext10thIncrement(rows.value.length * 10);

  return {
    rowNumber: row?.rowNumber ?? rowNumber,
    data: { ...data, note: data.note ?? undefined },
    deleted: row?.deleted ?? false,
    hidden: row?.hidden ?? false,
  };
}

async function onCancel() {
  formData.value = createFormData(certificationRow.value);
  article.value = utils.type.asType<models.Article>(null);
  modal.value?.close();
}

async function onSubmit() {
  startLoading();
  emit('upcreate', formData.value);
  formData.value = createFormData();
  article.value = utils.type.asType<models.Article>(null);
  modal.value?.close();
  stopLoading();
}

onBeforeMount(async () => {
  startLoading();
  articles.value = await articleService.getAll({
    category: ['Brandsläckare', 'Brandfilt'], // TODO what should we filter on?
  });
  stopLoading();
});
</script>

<template>
  <baf-modal ref="modal" :title="title" tag="form" @submit.prevent="onSubmit">
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <div class="field">
        <label class="label" for="fireExtinguisherRowNumber">{{ $t('general.rowNumber') }}</label>
        <div class="control">
          <input
            id="fireExtinguisherRowNumber"
            v-model="formData.rowNumber"
            data-testid="fireExtinguisherRowNumberInput"
            type="number"
            class="input"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label" for="fireExtinguisherArticle"
              >{{ $t('general.search') }}
              {{ $t('general.enums.certificationType.FireExtinguisher').toLowerCase() }}
            </label>
            <div class="control">
              <baf-search-select
                id="fireExtinguisherArticle"
                v-model="article"
                data-testid="fireExtinguisherArticleSelect"
                :values="articles"
                value-key="id"
                text-key="description"
                @update:model-value="onUpdateArticle"
              ></baf-search-select>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label class="label" for="fireExtinguisherName">{{ $t('general.name') }}</label>
            <div class="control">
              <input
                id="fireExtinguisherName"
                v-model="formData.data.name"
                data-testid="fireExtinguisherNameInput"
                type="text"
                class="input"
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label" for="fireExtinguisherPlacement">{{
              $t('general.placement')
            }}</label>
            <div class="control">
              <input
                id="fireExtinguisherPlacement"
                v-model="formData.data.placement"
                data-testid="fireExtinguisherPlacementInput"
                type="text"
                class="input"
              />
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label class="label" for="fireExtinguisherRechargePressureTest">{{
              $t('general.rechargePressureTest')
            }}</label>
            <div class="control">
              <input
                id="fireExtinguisherRechargePressureTest"
                v-model="formData.data.rechargePressureTest"
                data-testid="fireExtinguisherRechargePressureTestInput"
                type="text"
                class="input"
                :placeholder="$t('general.monthYearPlaceholder')"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label" for="fireExtinguisherNote">{{ $t('general.note') }}</label>
        <div class="control">
          <textarea
            id="fireExtinguisherNote"
            v-model="formData.data.note"
            data-testid="fireExtinguisherNoteInput"
            rows="5"
            class="textarea"
          ></textarea>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="button"
        data-testid="cancelButton"
        :class="loadingClass"
        @click="onCancel()"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        type="submit"
        class="button is-primary"
        data-testid="upcreateButton"
        :class="loadingClass"
      >
        {{ isUpdating ? $t('general.update') : $t('general.create') }}
      </button>
    </template>
  </baf-modal>
</template>
