import RootPage from '@/pages/root-page.vue';
import { authService } from '@/services/auth/auth-service';
import { models } from 'baf-shared';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import { RouteName } from './models/route-name';

// Print
import PrintRootPage from '@/pages/print/root-page.vue';

// Data
import DataArticlesPage from '@/pages/data/articles-page.vue';
import DataCustomersPage from '@/pages/data/customers-page.vue';
import DataEmployeesPage from '@/pages/data/employees-page.vue';
import DataRootPage from '@/pages/data/root-page.vue';

// Service orders
import ServiceOrderCreate from '@/components/service-order/service-order-create.vue';
import ServiceOrdersLabelsPage from '@/pages/service-orders/labels-page.vue';
import ServiceOrdersRootPage from '@/pages/service-orders/root-page.vue';
import ServiceOrdersServiceOrderPage from '@/pages/service-orders/service-order-page.vue';
import ServiceOrdersStartPage from '@/pages/service-orders/service-orders-page.vue';

// Certifications
import CertificationsAssetsPage from '@/pages/certifications/certification-assets-page.vue';
import CertificationsCertificationPage from '@/pages/certifications/certification-upcreate-page.vue';
import CertificationsStartPage from '@/pages/certifications/certifications-page.vue';
import CertificationsRootPage from '@/pages/certifications/root-page.vue';

// Pressure tests
import PressureTestsCategoryValuesPage from '@/pages/pressure-tests/category-values-page.vue';
import PressureTestsStartPage from '@/pages/pressure-tests/pressure-test-sequential-numbers-page.vue';
import PressureTestsAssetsPage from '@/pages/pressure-tests/pressure-test-assets-page.vue';
import PressureTestsUpcreatePage from '@/pages/pressure-tests/pressure-tests-upcreate-page.vue';
import PressureTestsRootPage from '@/pages/pressure-tests/root-page.vue';

type Access = models.Access;

type AppRouteRecord = {
  meta: {
    /**
     * defaults to true
     */
    requiresAuthentication: boolean;
    /**
     * defaults to [] which equals that no special access is needed.
     */
    requiresAccess?: Access[];
  };
} & RouteRecordRaw;

function createRoute(options: Partial<AppRouteRecord>): AppRouteRecord {
  const DEFAULT_OPTIONS: Partial<AppRouteRecord> = {
    props: true,
    meta: {
      requiresAuthentication: true,
      requiresAccess: [],
    },
  };
  return Object.assign(DEFAULT_OPTIONS, options) as AppRouteRecord;
}

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    createRoute({
      path: '/',
      name: RouteName.Root,
      component: RootPage,
      meta: {
        requiresAuthentication: false,
      },
    }),
    createRoute({
      path: '/data',
      name: RouteName.DataRoot,
      component: DataRootPage,
      redirect: { name: RouteName.DataArticles },
      children: [
        createRoute({
          path: 'articles',
          name: RouteName.DataArticles,
          component: DataArticlesPage,
          meta: {
            requiresAuthentication: true,
            requiresAccess: [models.Access.Register],
          },
        }),
        createRoute({
          path: 'customers',
          name: RouteName.DataCustomers,
          component: DataCustomersPage,
          meta: {
            requiresAuthentication: true,
            requiresAccess: [models.Access.Register],
          },
        }),
        createRoute({
          path: 'employees',
          name: RouteName.DataEmployees,
          component: DataEmployeesPage,
          meta: {
            requiresAuthentication: true,
            requiresAccess: [models.Access.Register],
          },
        }),
      ],
    }),
    createRoute({
      path: '/service-orders',
      name: RouteName.ServiceOrdersRoot,
      component: ServiceOrdersRootPage,
      redirect: { name: RouteName.ServiceOrdersStart },
      children: [
        createRoute({
          path: '',
          name: RouteName.ServiceOrdersStart,
          component: ServiceOrdersStartPage,
        }),
        createRoute({
          path: 'labels',
          name: RouteName.ServiceOrdersLabels,
          component: ServiceOrdersLabelsPage,
        }),
        createRoute({
          path: 'create',
          name: RouteName.ServiceOrdersCreate,
          component: ServiceOrderCreate,
          meta: {
            requiresAuthentication: true,
            requiresAccess: [models.Access.Admin],
          },
        }),
        createRoute({
          path: 'details/:id',
          name: RouteName.ServiceOrdersServiceOrder,
          component: ServiceOrdersServiceOrderPage,
        }),
      ],
    }),
    createRoute({
      path: '/pressure-tests',
      name: RouteName.PressureTestsRoot,
      component: PressureTestsRootPage,
      redirect: { name: RouteName.PressureTestsStart },
      children: [
        createRoute({
          path: '',
          name: RouteName.PressureTestsStart,
          component: PressureTestsStartPage,
        }),
        createRoute({
          path: 'assets',
          name: RouteName.PressureTestsAssets,
          component: PressureTestsAssetsPage,
        }),
        createRoute({
          path: 'category-values',
          name: RouteName.PressureTestsCategoryValues,
          component: PressureTestsCategoryValuesPage,
        }),
        createRoute({
          path: 'sequential-number/:sequentialNumberId',
          name: RouteName.PressureTestsSequentialNumber,
          component: PressureTestsUpcreatePage,
        }),
      ],
    }),
    createRoute({
      path: '/certifications',
      name: RouteName.CertificationsRoot,
      component: CertificationsRootPage,
      redirect: { name: RouteName.CertificationsStart },
      children: [
        createRoute({
          path: '',
          name: RouteName.CertificationsStart,
          component: CertificationsStartPage,
        }),
        createRoute({
          path: 'create',
          name: RouteName.CertificationsCreate,
          component: CertificationsCertificationPage,
        }),
        createRoute({
          path: 'update/:id',
          name: RouteName.CertificationsUpdate,
          component: CertificationsCertificationPage,
        }),
        createRoute({
          path: 'assets',
          name: RouteName.CertificationsAssets,
          component: CertificationsAssetsPage,
        }),
      ],
    }),
    createRoute({
      path: '/$hidden/print',
      name: RouteName.Print,
      component: PrintRootPage,
    }),
    createRoute({
      path: '/:pathMatch(.*)*',
      redirect: { name: RouteName.Root },
    }),
  ],
});

router.beforeEach((to, from, next) => {
  const authState = authService.getState();
  const authenticatedUser = authState.me;
  const meta = to.meta as AppRouteRecord['meta'];

  if (!meta.requiresAuthentication) {
    return next();
  }

  const requiresAccess = meta.requiresAccess ?? [];
  if (authService.authenticated && requiresAccess.length === 0) {
    return next();
  }

  if (
    authService.authenticated &&
    requiresAccess.every((access) => authenticatedUser?.access.includes(access))
  ) {
    return next();
  }

  return next({ name: RouteName.Root });
});

function removeStopScrollClass() {
  const html = document.querySelector('html');
  const STOP_SCROLL_CLASS = 'is-clipped';

  html?.classList.remove(STOP_SCROLL_CLASS);
}

router.afterEach(() => {
  removeStopScrollClass();
});
