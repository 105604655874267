<script setup lang="ts">
import { models, utils } from 'baf-shared';
import { computed, ref } from 'vue';
import BafModal from '@/components/standard/modal/baf-modal.vue';
import { useI18n } from 'vue-i18n';
import { useLoading } from '@/composeables/use-loading';
import { RouteName } from '@/router/models/route-name';
import { router } from '@/router/router';
import { pressureTestSequentialNumberService } from '@/services/pressure-test-sequential-number/pressure-test-sequential-number-service';
import BafSelectField from '@/components/standard/form/baf-select-field.vue';

const { t } = useI18n();

const WAREHOUSES = Object.values(models.PressureTestSequentialNumberWarehouse).map((warehouse) => ({
  text: t(`general.enums.warehouse.${warehouse}`),
  value: warehouse,
}));

const warehouses = computed(() => WAREHOUSES);

const { startLoading, stopLoading, loadingClass } = useLoading();
const modal = ref<InstanceType<typeof BafModal>>();

function createFormData(): models.CreatePressureTestSequentialNumber {
  return {
    warehouse: utils.type.asType<models.PressureTestSequentialNumberWarehouse>(null),
  };
}

const formData = ref(createFormData());

function onCancel() {
  formData.value = createFormData();
  modal.value?.close();
}

async function onSubmit() {
  try {
    startLoading();
    const createdSequentialNumber = await pressureTestSequentialNumberService.create(
      formData.value,
    );
    await router.push({
      name: RouteName.PressureTestsSequentialNumber,
      params: {
        sequentialNumberId: createdSequentialNumber.id,
      },
    });
    modal.value?.close();
  } finally {
    stopLoading();
  }
}
</script>

<template>
  <baf-modal
    ref="modal"
    :title="`${$t('general.sequentialNumberCreate')}`"
    tag="form"
    @submit.prevent="onSubmit"
  >
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <div class="field">
        <baf-select-field
          id="sequentialNumberWarehouse"
          v-model="formData.warehouse"
          :label="$t('general.warehouse')"
          data-testid="sequentialNumberWarehouseSelect"
          required
        >
          <option :value="undefined" disabled>-</option>
          <option v-for="(warehouse, index) in warehouses" :key="index" :value="warehouse.value">
            {{ warehouse.text }}
          </option>
        </baf-select-field>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="button"
        data-testid="cancelButton"
        :class="loadingClass"
        @click="onCancel"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        type="submit"
        class="button is-primary"
        data-testid="createButton"
        :class="loadingClass"
      >
        {{ $t('general.create') }}
      </button>
    </template>
  </baf-modal>
</template>

<style scoped lang="scss"></style>
