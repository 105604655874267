import { utils } from 'baf-shared';
import { failedToFetchService } from '../standard/failed-to-fetch-service';
import { LocalStorageService } from '../storage/local-storage-service';

interface OnlineState {
  lastOnlineDate: string;
  lastOnline: boolean;
  online: boolean;
}

const createOnlineState = (): OnlineState => ({
  lastOnlineDate: new Date().toISOString(),
  lastOnline: true,
  online: true,
});

class OnlineService extends LocalStorageService<OnlineState> {
  constructor() {
    super('online', createOnlineState);
  }

  get online() {
    return this.getState().online === true;
  }

  get offline() {
    return this.getState().online === false;
  }

  setOnline(online: boolean): void {
    const state = this.getState();
    const lastOnlineDate = utils.date.nowInISO();
    const lastOnline = state.online;

    if (online) {
      failedToFetchService.retry();
    }

    this.setState({
      online,
      lastOnline,
      lastOnlineDate,
    });
  }
}

export const onlineService = new OnlineService();
