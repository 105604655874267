import type { models, queries } from 'baf-shared';
import { isFailedToFetchErrorOrThrow } from '../standard/failed-to-fetch-service';
import { appHttpService } from '../standard/http-service';

class CategoryValueService {
  async getAll(query?: queries.GetCategoryValuesQuery): Promise<models.CategoryValue[]> {
    try {
      const categoryValues = await appHttpService.request
        .url('/category-values')
        .query({
          ...query,
        })
        .get()
        .json<models.CategoryValue[]>();
      return categoryValues;
    } catch (error) {
      isFailedToFetchErrorOrThrow(error);
      return [];
    }
  }

  async create(upcreateCategoryValue: models.UpcreateCategoryValue): Promise<models.CategoryValue> {
    const categoryValue = await appHttpService.request
      .url(`/category-values`)
      .post(upcreateCategoryValue)
      .json<models.CategoryValue>();
    return categoryValue;
  }

  async update(
    id: string,
    upcreateCategoryValue: models.UpcreateCategoryValue,
  ): Promise<models.CategoryValue> {
    const categoryValue = await appHttpService.request
      .url(`/category-values/${id}`)
      .put(upcreateCategoryValue)
      .json<models.CategoryValue>();
    return categoryValue;
  }

  async delete(id: string): Promise<models.CategoryValue> {
    const categoryValue = await appHttpService.request
      .url(`/category-values/${id}`)
      .delete()
      .json<models.CategoryValue>();
    return categoryValue;
  }
}

export const categoryValueService = new CategoryValueService();
