<script setup lang="ts">
import BafTable from '@/components/standard/table/baf-table.vue';
import { useServiceOrderComputed } from '@/composeables/service-order/use-service-order-computed';
import { useAuth } from '@/composeables/use-auth';
import { useLoading } from '@/composeables/use-loading';
import { serviceOrderService } from '@/services/service-order/service-order-service';
import { models, utils } from 'baf-shared';
import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import ServiceOrderRowUpcreateModal from '../service-order/service-order-row-upcreate-modal.vue';
import { TableColumn, type TableRow } from '../standard/table/models/table';

const { t } = useI18n();

const props = defineProps<{
  serviceOrder: Omit<models.ServiceOrder, 'rows'>;
  rows: models.ServiceOrderRow[];
}>();
const { rows, serviceOrder } = toRefs(props);

const emit = defineEmits<{
  (e: 'update', serviceOrder: models.ServiceOrder): void;
}>();

const { loading, startLoading, stopLoading, loadingClass } = useLoading();

const { isInProgress: serviceOrderIsInProgress } = useServiceOrderComputed(serviceOrder);
const { isAdmin } = useAuth();

const columns = [
  TableColumn.for('userActions', '', {
    charactersWidth: 1,
    search: false,
    sort: false,
  }),
  TableColumn.for('rowNumber', t('general.rowNumber'), { charactersWidth: 16 }),
  TableColumn.for('marking', t('general.marking'), { charactersWidth: 16 }),
  TableColumn.for('article', t('general.equipmentType'), {
    renderer: (value) => {
      const article = value as unknown as models.Article;
      return utils.joinValues([article?.description]);
    },
  }),
  TableColumn.for('placement', t('general.placement'), { charactersWidth: 20 }),
  TableColumn.for('addition', t('general.addition'), {
    renderer: (_value, row) => {
      const serviceOrderRow = utils.type.asType<models.ServiceOrderRow>(row);
      const addition = serviceOrderRow.addition
        ? t(`general.enums.addition.${serviceOrderRow.addition}`)
        : '';
      return addition;
    },
  }),
  TableColumn.for('actions', t('general.actions'), {
    renderer: (_value, row) => {
      const serviceOrderRow = row as models.ServiceOrderRow;
      const actions = serviceOrderRow.actions.map((action) => action.article?.description);
      return utils.joinValues(actions, ', ');
    },
  }),
  TableColumn.for('nextService', `${t('general.nextRechargePressureTestExchange')}`, {
    renderer: (_value, row) => {
      return utils.date.formatDate(row.nextService);
    },
  }),
];

const isRowInProgress = (row: models.ServiceOrderRow) =>
  row.status === models.ServiceOrderRowStatus.InProgress;
const isRowFinished = (row: models.ServiceOrderRow) =>
  row.status === models.ServiceOrderRowStatus.Finished;

function onUpdateServiceOrder(serviceOrder: models.ServiceOrder) {
  emit('update', serviceOrder);
}
async function onClickRow(row: models.ServiceOrderRow | TableRow) {
  const serviceOrderRow = row as models.ServiceOrderRow;
  serviceOrderRow.marked = !serviceOrderRow.marked;
  const updatedServiceOrder = await serviceOrderService.updateInternalServiceOrderRow(
    serviceOrder.value.id,
    row.id,
    {
      marked: serviceOrderRow.marked,
    },
  );
  emit('update', updatedServiceOrder);
}

async function onClickStatus(row: models.ServiceOrderRow, status: models.ServiceOrderRowStatus) {
  try {
    startLoading();
    const updatedServiceOrder = await serviceOrderService.updateServiceOrderRowStatus(
      serviceOrder.value.id,
      row.id,
      {
        status,
        lastService:
          status === models.ServiceOrderRowStatus.Finished ? utils.date.nowInISO() : undefined,
      },
    );
    emit('update', updatedServiceOrder);
  } finally {
    stopLoading();
  }
}
</script>

<template>
  <baf-table
    :columns="columns"
    :rows="rows"
    :row-class="{
      'is-marked': (row) => row.marked,
    }"
    :sort-default="{ key: 'rowNumber' }"
    :loading="loading"
    sort
    search
    hover
    @click-row="onClickRow"
  >
    <template #column-userActions="{ row }">
      <div v-if="serviceOrderIsInProgress || isAdmin" class="tags" data-testid="tableUserActions">
        <service-order-row-upcreate-modal
          v-if="serviceOrderIsInProgress || isAdmin"
          #="{ open }"
          :rows="rows"
          :service-order-id="serviceOrder.id"
          :service-order-row="row"
          @upcreate="onUpdateServiceOrder"
        >
          <a
            class="tag is-link is-medium"
            :class="loadingClass"
            data-testid="editTag"
            @click.stop="open"
          >
            <iconify-icon icon="mdi:edit"></iconify-icon>
            <span class="ml-2">{{ $t('general.edit') }}</span>
          </a>
        </service-order-row-upcreate-modal>

        <a
          v-if="isRowInProgress(row) && (serviceOrderIsInProgress || isAdmin)"
          class="tag is-success is-medium"
          :class="loadingClass"
          data-testid="finishTag"
          @click.stop="onClickStatus(row, models.ServiceOrderRowStatus.Finished)"
        >
          <iconify-icon icon="mdi:check"></iconify-icon>
          <span class="ml-2">{{ $t('general.finish') }}</span>
        </a>
        <a
          v-else-if="isRowFinished(row) && (serviceOrderIsInProgress || isAdmin)"
          class="tag is-info is-medium"
          :class="loadingClass"
          data-testid="resumeTag"
          @click.stop="onClickStatus(row, models.ServiceOrderRowStatus.InProgress)"
        >
          <iconify-icon icon="mdi:undo-variant"></iconify-icon>
          <span class="ml-2">{{ $t('general.resume') }}</span>
        </a>
      </div>
    </template>
  </baf-table>
</template>

<style lang="scss">
@import 'bulma/sass/utilities/derived-variables';
@import '@/assets/scss/overrides';

.is-marked {
  background-color: $warning-light;

  &:hover {
    background-color: darken($warning-light, 10) !important;
  }
}
</style>
