<script setup lang="ts">
import CategoryValueTable from '@/components/category-value/category-value-table.vue';
import CategoryValueUpcreateModal from '@/components/category-value/category-value-upcreate-modal.vue';
import { ref } from 'vue';

const categoryValueTable = ref<InstanceType<typeof CategoryValueTable>>();
</script>

<template>
  <section class="section">
    <div class="buttons is-right">
      <category-value-upcreate-modal
        #="{ open }"
        @upcreate="categoryValueTable?.loadCategoryValues"
      >
        <button class="button is-success" data-testid="createButton" @click.stop="open">
          <iconify-icon icon="mdi:add-circle"></iconify-icon>
          <span class="ml-2">{{ $t('general.categoryValueCreate') }}</span>
        </button>
      </category-value-upcreate-modal>
    </div>
  </section>

  <section class="section">
    <div class="box">
      <category-value-table ref="categoryValueTable"></category-value-table>
    </div>
  </section>
</template>
