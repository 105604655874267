<script setup lang="ts">
import { computed, toRefs } from 'vue';
import { PrintOrientation } from '@/models/print-orientation';

const props = withDefaults(
  defineProps<{
    pageNumber?: number | string;
    orientation?: PrintOrientation;
    title?: string;
    titleElement?: 'h1' | 'h2' | 'h3';
    titleTextAlign?: 'left' | 'center';
    textSize?: string;
  }>(),
  {
    pageNumber: undefined,
    orientation: PrintOrientation.Portrait,
    title: '',
    titleElement: 'h1',
    titleTextAlign: 'center',
    textSize: '0.875rem',
  },
);

const { pageNumber, orientation, titleTextAlign } = toRefs(props);

const rootClasses = computed(() => ({
  [orientation.value]: true,
}));
</script>

<template>
  <div class="print-page" :class="rootClasses">
    <header class="print-page-header">
      <img src="@/assets/images/logo-color.webp" :alt="$t('components.printPage.logoAlt')" />
      <p>{{ $t('components.printPage.logoTitle') }}</p>
    </header>

    <main class="print-page-main content">
      <component :is="titleElement" v-if="title" class="print-page-main-title">{{
        title
      }}</component>

      <slot></slot>
    </main>

    <slot name="pre-footer"></slot>
    <footer class="print-page-footer">
      <slot name="footer">
        <p>
          <span>{{ $t('components.printPage.companyName') }}</span>
          <br />
          <span>{{ $t('components.printPage.companyAddress') }}</span>
          <br />
          <a href="tel:+4645526500">0455-265 00</a>&nbsp;
          <a href="mailto:info@brandab.com">info@brandab.com</a>
        </p>
      </slot>

      <p v-if="pageNumber">
        <span>{{ pageNumber }}</span>
      </p>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/overrides';

:deep(h1),
:deep(h2),
:deep(h3),
:deep(h4),
:deep(h5),
:deep(p),
:deep(strong),
:deep(span),
:deep(th),
:deep(td),
:deep(dt),
:deep(dd) {
  color: $black !important;
}

:deep(h1),
:deep(h2),
:deep(h3),
:deep(h4),
:deep(h5) {
  text-transform: uppercase;
}

:deep(.table-container),
:deep(.table-pagination) {
  margin: 0 !important;
  padding: 0 !important;
}

:deep(th) {
  border-bottom: 1px solid $black !important;
}

//:deep(th):first-of-type,
//:deep(td):first-of-type {
//  padding: 0.5em 0 !important;
//}

:deep(p),
:deep(strong),
:deep(span),
:deep(th),
:deep(td),
:deep(dt),
:deep(dd) {
  font-size: v-bind(textSize);
}

.print-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  box-shadow: $shadow;

  overflow-x: hidden;
  overflow-y: auto;
  color: $black !important;

  &.portrait {
    width: 210mm;
    max-width: 210mm;
    height: 297mm;
    max-height: 297mm;
    padding: 1cm 1.5cm;
  }

  &.landscape {
    width: 297mm;
    max-width: 297mm;
    height: 210mm;
    max-height: 210mm;
    padding: 1cm 1.5cm;
  }

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    img {
      margin-right: 0.5em;
      height: 2.5em;
    }

    p {
      font-size: 2em;
      font-weight: bold;
    }
  }

  &-main {
    flex-grow: 2;

    &-title {
      text-align: v-bind(titleTextAlign);
    }
  }

  &-footer {
    font-size: 0.75em;
    border-top: 1px solid $black;
    padding-top: 0.5em;
    display: flex;
    justify-content: space-between;
  }
}
</style>
