import { utils } from 'baf-shared';
import { ref } from 'vue';
import type { NotificationData, OpenNotificationParams } from './models/notification';

class NotificationService {
  readonly notifications = ref<NotificationData[]>([]);

  open({ text, type, timeout = 5000 }: OpenNotificationParams): string {
    const id = utils.randomString();
    this.notifications.value.push({
      id,
      text,
      type,
      timeout,
    });

    if (timeout !== -1) {
      setTimeout(() => {
        this.close(id);
      }, timeout);
    }

    return id;
  }

  close(id: string): void {
    const index = this.notifications.value.findIndex((n) => n.id === id);

    if (index >= 0) {
      this.notifications.value.splice(index, 1);
    }
  }
}

export const notificationService = new NotificationService();
