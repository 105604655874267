<script setup lang="ts">
import { utils } from 'baf-shared';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import CertificationUpcreate from '@/components/certification/certification-upcreate.vue';

const route = useRoute();
const id = computed(() => utils.type.asType<string>(route.params.id));
</script>

<template>
  <certification-upcreate :id="id"></certification-upcreate>
</template>
