<script setup lang="ts">
import { RouteName } from '@/router/models/route-name';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useAuth } from '@/composeables/use-auth';
import { models } from 'baf-shared';

const { authenticated, me } = useAuth();

const activeRoute = useRoute();

defineOptions({
  inheritAttrs: false,
});

const { t } = useI18n();

const routes = computed(() => [
  {
    name: RouteName.ServiceOrdersStart,
    text: t('general.routeName.serviceOrders'),
    activeRouteNames: [RouteName.ServiceOrdersStart, RouteName.ServiceOrdersServiceOrder],
  },
  {
    name: RouteName.ServiceOrdersLabels,
    text: t('general.routeName.labels'),
    activeRouteNames: [RouteName.ServiceOrdersLabels],
    requiresAccess: [models.Access.Admin],
  },
]);

const $routes = computed(() =>
  routes.value.filter((route) => {
    if (route.requiresAccess) {
      const hasAccess = route.requiresAccess.every((access) => me.value?.access?.includes(access));
      return authenticated.value && hasAccess;
    }

    return true;
  }),
);
</script>

<template>
  <div class="tabs is-boxed is-fullwidth">
    <ul>
      <router-link
        v-for="route in $routes"
        v-slot="{ href, isExactActive, navigate }"
        :key="route.name"
        :to="{ name: route.name }"
        custom
      >
        <li
          :class="{
            'is-active':
              isExactActive || route.activeRouteNames.includes(activeRoute.name as RouteName),
          }"
        >
          <a :href="href" @click="navigate">{{ route.text }}</a>
        </li>
      </router-link>
    </ul>
  </div>

  <router-view v-slot="{ Component }" :key="$route.fullPath">
    <div class="px-4">
      <component :is="Component" v-bind="$attrs" />
    </div>
  </router-view>
</template>
