<script setup lang="ts">
import ServiceOrderUpdateTextsModal from '@/components/service-order/modal/service-order-update-texts-modal.vue';
import { useLoading } from '@/composeables/use-loading';
import { serviceOrderService } from '@/services/service-order/service-order-service';
import { models, utils } from 'baf-shared';
import { computed, toRefs } from 'vue';
import { notificationService } from '@/services/notification/notification-service';
import { useI18n } from 'vue-i18n';
import ConfirmModal from '@/components/modal/confirm-modal.vue';
import ServiceOrderStatusReasonModal from '@/components/service-order/modal/service-order-status-reason-modal.vue';
import { router } from '@/router/router';
import { RouteName } from '@/router/models/route-name';

const { t } = useI18n();
const ServiceOrderStatus = models.ServiceOrderStatus;

const props = defineProps<{
  serviceOrder: models.ServiceOrder;
}>();
const { serviceOrder } = toRefs(props);

const emit = defineEmits<{
  (e: 'update', serviceOrder: models.ServiceOrder): void;
}>();

const { startLoading, stopLoading, loadingClass } = useLoading();

const status = computed(() => serviceOrder.value.status);

function isStatus(...statuses: models.ServiceOrderStatus[]) {
  return statuses.some((s) => status.value === s);
}

async function onClickStatus(status: models.ServiceOrderStatus) {
  try {
    if (status === models.ServiceOrderStatus.Finished) {
      const rows = serviceOrder.value.rows;
      if (rows.some(({ status }) => status !== models.ServiceOrderRowStatus.Finished)) {
        notificationService.open({
          text: t('general.enums.bafErrorSlug.ServiceOrderFinishWithoutRowsCompleted'),
          type: 'warning',
        });
        return;
      }
    }

    startLoading();
    const updatedServiceOrder = await serviceOrderService.updateStatus(serviceOrder.value.id, {
      status,
      statusReason: undefined,
      lastService: undefined,
    });
    emit('update', updatedServiceOrder);
  } finally {
    stopLoading();
  }
}

async function onCompleteServiceOrder() {
  try {
    startLoading();
    const updatedServiceOrder = await serviceOrderService.updateStatus(serviceOrder.value.id, {
      status: ServiceOrderStatus.Completed,
      statusReason: undefined,
      lastService: utils.date.nowInISO(),
    });
    emit('update', updatedServiceOrder);

    await router.push({
      name: RouteName.ServiceOrdersStart,
    });
  } finally {
    stopLoading();
  }
}

async function onDenyServiceOrder(reason: string) {
  try {
    startLoading();
    const updatedServiceOrder = await serviceOrderService.updateStatus(serviceOrder.value.id, {
      status: models.ServiceOrderStatus.Denied,
      statusReason: reason,
    });
    emit('update', updatedServiceOrder);

    await router.push({
      name: RouteName.ServiceOrdersStart,
    });
  } finally {
    stopLoading();
  }
}

async function onRestServiceOrder(reason: string) {
  try {
    startLoading();
    const updatedServiceOrder = await serviceOrderService.updateStatus(serviceOrder.value.id, {
      status: models.ServiceOrderStatus.Resting,
      statusReason: reason,
    });
    emit('update', updatedServiceOrder);

    await router.push({
      name: RouteName.ServiceOrdersStart,
    });
  } finally {
    stopLoading();
  }
}
</script>

<template>
  <div class="buttons is-right">
    <template v-if="isStatus(ServiceOrderStatus.Received)">
      <service-order-status-reason-modal
        :status="ServiceOrderStatus.Resting"
        #="{ open }"
        @deny="onRestServiceOrder"
      >
        <button
          class="button is-warning"
          :class="loadingClass"
          data-testid="restButton"
          @click="open"
        >
          <iconify-icon icon="mdi:bed-queen"></iconify-icon>
          <span class="ml-2">{{ $t('general.rest') }}</span>
        </button>
      </service-order-status-reason-modal>

      <button
        class="button is-success"
        :class="loadingClass"
        data-testid="acceptButton"
        @click="onClickStatus(ServiceOrderStatus.Accepted)"
      >
        <iconify-icon icon="mdi:thumb-up"></iconify-icon>
        <span class="ml-2">{{ $t('general.accept') }}</span>
      </button>

      <service-order-status-reason-modal
        :status="ServiceOrderStatus.Denied"
        #="{ open }"
        @deny="onDenyServiceOrder"
      >
        <button
          class="button is-danger"
          :class="loadingClass"
          data-testid="denyButton"
          @click="open"
        >
          <iconify-icon icon="mdi:thumb-down"></iconify-icon>
          <span class="ml-2">{{ $t('general.deny') }}</span>
        </button>
      </service-order-status-reason-modal>
    </template>

    <template v-if="isStatus(ServiceOrderStatus.Accepted)">
      <service-order-status-reason-modal
        :status="ServiceOrderStatus.Resting"
        #="{ open }"
        @deny="onRestServiceOrder"
      >
        <button
          class="button is-warning"
          :class="loadingClass"
          data-testid="restButton"
          @click="open"
        >
          <iconify-icon icon="mdi:bed-queen"></iconify-icon>
          <span class="ml-2">{{ $t('general.rest') }}</span>
        </button>
      </service-order-status-reason-modal>

      <button
        class="button is-info"
        :class="loadingClass"
        data-testid="beginButton"
        @click="onClickStatus(ServiceOrderStatus.InProgress)"
      >
        <iconify-icon icon="mdi:play"></iconify-icon>
        <span class="ml-2">{{ $t('general.begin') }}</span>
      </button>
    </template>

    <template v-if="isStatus(ServiceOrderStatus.InProgress)">
      <service-order-status-reason-modal
        :status="ServiceOrderStatus.Resting"
        #="{ open }"
        @deny="onRestServiceOrder"
      >
        <button
          class="button is-warning"
          :class="loadingClass"
          data-testid="restButton"
          @click="open"
        >
          <iconify-icon icon="mdi:bed-queen"></iconify-icon>
          <span class="ml-2">{{ $t('general.rest') }}</span>
        </button>
      </service-order-status-reason-modal>

      <service-order-update-texts-modal
        #="{ open }"
        :service-order="utils.type.asType<models.ServiceOrder>(serviceOrder)"
        signature-required
        @update="onClickStatus(ServiceOrderStatus.Finished)"
      >
        <button
          class="button is-success"
          :class="loadingClass"
          data-testid="finishButton"
          @click="open"
        >
          <iconify-icon icon="mdi:check"></iconify-icon>
          <span class="ml-2">{{ $t('general.finish') }}</span>
        </button>
      </service-order-update-texts-modal>
    </template>

    <template v-if="isStatus(ServiceOrderStatus.Resting)">
      <button
        class="button is-success"
        :class="loadingClass"
        data-testid="resumeButton"
        @click="onClickStatus(ServiceOrderStatus.Received)"
      >
        <iconify-icon icon="mdi:undo-variant"></iconify-icon>
        <span class="ml-2">{{ $t('general.resume') }}</span>
      </button>
    </template>

    <template v-if="isStatus(ServiceOrderStatus.Finished)">
      <service-order-status-reason-modal
        :status="ServiceOrderStatus.Resting"
        #="{ open }"
        @deny="onRestServiceOrder"
      >
        <button
          class="button is-warning"
          :class="loadingClass"
          data-testid="restButton"
          @click="open"
        >
          <iconify-icon icon="mdi:bed-queen"></iconify-icon>
          <span class="ml-2">{{ $t('general.rest') }}</span>
        </button>
      </service-order-status-reason-modal>

      <button
        class="button is-success"
        :class="loadingClass"
        data-testid="resumeButton"
        @click="onClickStatus(ServiceOrderStatus.InProgress)"
      >
        <iconify-icon icon="mdi:undo-variant"></iconify-icon>
        <span class="ml-2">{{ $t('general.resume') }}</span>
      </button>

      <confirm-modal
        :message="$t('general.completeConfirm')"
        :confirm-callback="onCompleteServiceOrder"
        #="{ open }"
      >
        <button
          class="button is-primary"
          :class="loadingClass"
          data-testid="completeButton"
          @click="open"
        >
          <iconify-icon icon="mdi:send-check"></iconify-icon>
          <span class="ml-2">{{ $t('general.complete') }}</span>
        </button>
      </confirm-modal>
    </template>
  </div>
</template>
