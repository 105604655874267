import { utils, type Nullish } from 'baf-shared';
import { ref, watch, type Ref } from 'vue';
import { buildStorageKey, type StorageService } from './storage-service';

export class LocalStorageService<State extends object> implements StorageService<State> {
  protected _state: Ref<Nullish<State>> = ref(undefined);
  protected _stateKey: string;
  protected _defaultStateInitiator: () => State;

  constructor(key: string, defaultStateInitiator: () => State) {
    this._stateKey = buildStorageKey(key);
    this._defaultStateInitiator = defaultStateInitiator;

    this._state.value = this._defaultStateInitiator();

    watch(this._state, () => {}, { deep: true });

    this.setState(this.getState());
  }

  state(): Ref<Nullish<State>> {
    return this._state;
  }

  setState(state?: Partial<State>): State {
    const stateToUse = Object.assign(this.getState(), state ?? {});
    this._state.value = stateToUse;
    localStorage.setItem(this._stateKey, JSON.stringify(stateToUse));
    return stateToUse;
  }

  getState(): State {
    const state = localStorage.getItem(this._stateKey);
    const stateOrUndefined = utils.type.isString(state)
      ? utils.json.parseOrDefault(state, undefined)
      : undefined;
    return stateOrUndefined ?? this._defaultStateInitiator();
  }

  resetState(): void {
    this._state.value = this._defaultStateInitiator();
  }
}
