<script setup lang="ts">
import BafModal from '@/components/standard/modal/baf-modal.vue';
import { useLoading } from '@/composeables/use-loading';
import { models } from 'baf-shared';
import { computed, ref, toRefs } from 'vue';
import BafSearchSelect from '@/components/standard/search-select/baf-search-select.vue';
import BafField from '@/components/standard/form/baf-field.vue';
import { employeeService } from '@/services/employee/employee-service';
import { useI18n } from 'vue-i18n';

type FormData = Omit<models.UpdateEmployee, 'access'> & {
  access: { text: string; value: models.Access }[];
};

const { t } = useI18n();
const ACCESSES = Object.values(models.Access)
  .filter((access) => models.Access.Admin !== access)
  .map((access) => ({
    text: t(`general.enums.access.${access}`),
    value: access,
  }));
const accesses = computed(() => ACCESSES);

function createFormData(employee: models.ListEmployee): FormData {
  return {
    access: [
      ...employee.access.map((access) => ({
        text: t(`general.enums.access.${access}`),
        value: access,
      })),
    ],
  };
}

const { startLoading, stopLoading, loadingClass } = useLoading();
const modal = ref<InstanceType<typeof BafModal>>();

const props = defineProps<{
  employee: models.ListEmployee;
}>();
const { employee } = toRefs(props);
const isEmployeeAdmin = computed(() => employee.value.access.includes(models.Access.Admin));

const emit = defineEmits<{
  (e: 'update', employee: models.ListEmployee): void;
}>();

const formData = ref(createFormData(employee.value));

function onCancel() {
  formData.value = createFormData(employee.value);
  modal.value?.close();
}

async function onSubmit() {
  if (isEmployeeAdmin.value) {
    return;
  }
  try {
    startLoading();
    const updatedEmployee = await updateEmployee();
    formData.value = createFormData(updatedEmployee);
    emit('update', updatedEmployee);
    modal.value?.close();
  } finally {
    stopLoading();
  }
}

function updateEmployee(): Promise<models.ListEmployee> {
  const updateEmployeeFormData = {
    ...formData.value,
    access: formData.value.access.map((access) => access.value),
  };

  return employeeService.update(employee.value.id, updateEmployeeFormData);
}
</script>

<template>
  <baf-modal
    ref="modal"
    :title="$t('general.employeeUpdate')"
    tag="form"
    @submit.prevent="onSubmit"
  >
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <div class="columns is-multiline">
        <div class="column">
          <baf-field>
            <strong v-if="isEmployeeAdmin">{{ $t('general.changeEmployeeInFortnox') }}</strong>
          </baf-field>

          <baf-field>
            <label class="label" for="employeeAccess">{{ $t('general.access') }}</label>
            <div class="control">
              <baf-search-select
                id="employeeAccess"
                v-model="formData.access"
                :values="accesses"
                value-key="value"
                text-key="text"
                :disabled="isEmployeeAdmin"
              ></baf-search-select>
            </div>
          </baf-field>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="button"
        data-testid="cancelButton"
        :class="loadingClass"
        @click="onCancel()"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        type="submit"
        class="button is-primary"
        data-testid="updateButton"
        :class="loadingClass"
        :disabled="isEmployeeAdmin"
      >
        {{ $t('general.update') }}
      </button>
    </template>
  </baf-modal>
</template>
