<script setup lang="ts">
import BafTable from '@/components/standard/table/baf-table.vue';
import { TableColumn } from '@/components/standard/table/models/table';
import { articleService } from '@/services/article/article-service';
import type { models } from 'baf-shared';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useLoading } from '@/composeables/use-loading';

const { t } = useI18n();
const route = useRoute();
const { loading, startLoading, stopLoading } = useLoading();

const columns = [
  TableColumn.for('fortnoxId', t('general.fortnoxId'), { charactersWidth: 16 }),
  TableColumn.for('ean', t('general.articleNumber'), { charactersWidth: 20 }),
  TableColumn.for('categories', t('general.category'), { charactersWidth: 20 }),
  TableColumn.for('description', t('general.description')),
  TableColumn.for('certifications', t('general.certifications')),
];

const data = ref<models.Article[]>([]);

onBeforeMount(async () => {
  try {
    startLoading();
    data.value = await articleService.getAll();
  } finally {
    stopLoading();
  }
});
</script>

<template>
  <baf-table
    :columns="columns"
    :rows="data"
    :loading="loading"
    :sort-default="{ key: 'fortnoxId' }"
    :search-query="route.query"
    search
    paginate
  ></baf-table>
</template>
