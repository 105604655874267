<script setup lang="ts">
import { useLoading } from '@/composeables/use-loading';
import { ref, toRefs } from 'vue';
import BafModal from '@/components/standard/modal/baf-modal.vue';
import { type DateString } from 'baf-shared';
import { certificationService } from '@/services/certification/certification-service';
import BafTextField from '@/components/standard/form/baf-text-field.vue';
import BafField from '@/components/standard/form/baf-field.vue';

type FormData = {
  validFrom?: DateString;
  clearNotes: boolean;
};

function createFormData(): FormData {
  return {
    validFrom: undefined,
    clearNotes: false,
  };
}

const { startLoading, stopLoading, loadingClass } = useLoading();
const modal = ref<InstanceType<typeof BafModal>>();

const props = defineProps<{
  id: string;
}>();

const { id } = toRefs(props);

const formData = ref(createFormData());

const emit = defineEmits<{
  (e: 'renew', id: string): void;
}>();

async function onCancel() {
  formData.value = createFormData();
  modal.value?.close();
}

async function onSubmit() {
  try {
    startLoading();
    const renewedCertification = await certificationService.renew(id.value, {
      validFrom: formData.value.validFrom!,
      clearNotes: formData.value.clearNotes,
    });
    emit('renew', renewedCertification.id);
    modal.value?.close();
  } finally {
    stopLoading();
  }
}
</script>

<template>
  <baf-modal
    ref="modal"
    :title="$t('general.certificationRenew')"
    tag="form"
    @submit.prevent="onSubmit"
  >
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <baf-text-field
        id="certificationRenewValidFrom"
        v-model="formData.validFrom"
        :label="$t('general.date')"
        type="datetime-local"
        required
      ></baf-text-field>

      <baf-field>
        <label class="checkbox">
          <input
            v-model="formData.clearNotes"
            type="checkbox"
            data-testid="certificationRenewClearNotesCheckbox"
          />
          {{ $t('general.clearNotes') }}
        </label>
      </baf-field>
    </template>
    <template #footer>
      <button
        type="button"
        class="button"
        data-testid="cancelButton"
        :class="loadingClass"
        @click="onCancel()"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        type="submit"
        class="button is-primary"
        data-testid="submitButton"
        :class="loadingClass"
      >
        {{ $t('general.renew') }}
      </button>
    </template>
  </baf-modal>
</template>

<style scoped lang="scss"></style>
