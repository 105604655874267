<script setup lang="ts">
import LabelUpcreateModal from '@/components/label/label-upcreate-modal.vue';
import ConfirmModal from '@/components/modal/confirm-modal.vue';
import BafTable from '@/components/standard/table/baf-table.vue';
import {
  TableColumn,
  type TableRow,
  type TableValue,
} from '@/components/standard/table/models/table';
import { useLoading } from '@/composeables/use-loading';
import { RouteName } from '@/router/models/route-name';
import { labelService } from '@/services/label/label-service';
import { models } from 'baf-shared';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { loading, startLoading, stopLoading } = useLoading();

const columns = [
  TableColumn.for('userActions', '', {
    charactersWidth: 1,
    search: false,
    sort: false,
  }),
  TableColumn.for('fortnoxId', t('general.fortnoxId'), { charactersWidth: 16 }),
  TableColumn.for('description', t('general.label')),
  TableColumn.for('assignedServiceOrders', t('general.serviceOrders'), {
    charactersWidth: 18,
    right: true,
  }),
  TableColumn.for('assignedServiceOrderRows', t('general.serviceOrderRows'), {
    charactersWidth: 26,
    right: true,
  }),
];

const loadLabels = async () => {
  try {
    startLoading();
    data.value = await labelService.getAll();
  } finally {
    stopLoading();
  }
};

defineExpose({
  loadLabels,
});

const data = ref<models.Label[]>([]);

async function onClickRow(row: models.Label | TableRow) {
  const label = row as models.Label;

  await router.push({
    name: RouteName.ServiceOrdersStart,
    query: {
      labels: label.description,
    },
  });
}

function onDeleteLabelCallback(id: TableValue) {
  const labelId = id as string;
  return async () => {
    try {
      startLoading();
      await labelService.delete(labelId);
      data.value = await labelService.getAll();
    } finally {
      stopLoading();
    }
  };
}

onBeforeMount(async () => {
  await loadLabels();
});
</script>

<template>
  <baf-table
    :columns="columns"
    :rows="data"
    :loading="loading"
    :sort-default="{ key: 'fortnoxId' }"
    :search-query="route.query"
    search
    paginate
    hover
    @click-row="onClickRow"
  >
    <template #column-userActions="{ row }">
      <div class="tags">
        <label-upcreate-modal :label="row" #="{ open }" @upcreate="loadLabels">
          <a class="tag is-link is-medium" data-testid="editButton" @click.stop="open">
            <iconify-icon icon="mdi:edit"></iconify-icon>
            <span class="ml-2">{{ $t('general.edit') }}</span>
          </a>
        </label-upcreate-modal>

        <confirm-modal
          :message="$t('general.deleteConfirm', { value: row.description })"
          :confirm-callback="onDeleteLabelCallback(row.id)"
          #="{ open }"
        >
          <a class="tag is-danger is-medium" data-testid="deleteButton" @click.stop="open">
            <iconify-icon icon="material-symbols:delete-outline"></iconify-icon>
            <span class="ml-2">{{ $t('general.delete') }}</span>
          </a>
        </confirm-modal>
      </div>
    </template>
  </baf-table>
</template>
