import { createDataFunction } from '../../functions/index.js';
import { createCustomerLundstrom, createCustomerMattson } from './customer.js';
import { createPressureTestAssetAxel } from './pressure-test-asset.js';
import { createEmployeeSkopis } from './employee.js';
import { createPressureTestSequentialNumberKarlskrona } from './pressure-test-sequential-number.js';
export const createPressureTest = createDataFunction({
    id: '12467cb6-0029-47e3-be42-e04c2f537f0b',
    customer: createCustomerMattson(),
    asset: createPressureTestAssetAxel(),
    sequentialNumber: createPressureTestSequentialNumberKarlskrona(),
    servicedBy: createEmployeeSkopis(),
    filledBy: createEmployeeSkopis(),
    actions: ['UA'],
    manufacturer: 'Ström-Pettersson',
    serialNumber: '175956',
    manufacturingDate: new Date('2023-06-24T17:28:58.439Z'),
    gas: 'Tomt',
    volume: '1.68',
    diameter: '2.5',
    emptyWeight: '0.15',
    filledWeight: '26.70',
    filledPressure: '29.46',
    testedPressure: '48.96',
    certifications: ['SWEDAC'],
    receiptNumber: '2147483647',
    information: 'Otrolig grön hatt.',
    note: 'Söt purpur bil.\nLiten grön handskar.\nIntelligent rosa bord.',
    lastService: new Date('2024-05-13T11:11:22.404Z'),
    nextService: null,
    validFrom: new Date('2024-05-13T13:01:12.832Z'),
    created: new Date('2023-11-06T18:00:12.021Z'),
    updated: new Date('2024-05-13T03:09:34.438Z'),
});
export const createPressureTestCustomerMattson = createDataFunction({
    id: '297db503-a620-4430-bb34-1052ea8b4dd6',
    customer: createCustomerMattson(),
    asset: createPressureTestAssetAxel(),
    sequentialNumber: createPressureTestSequentialNumberKarlskrona(),
    servicedBy: createEmployeeSkopis(),
    filledBy: createEmployeeSkopis(),
    actions: ['UA'],
    manufacturer: 'Ström-Pettersson',
    serialNumber: '175956',
    manufacturingDate: new Date('2023-06-24T17:28:58.439Z'),
    gas: 'Tomt',
    volume: '1.68',
    diameter: '2.5',
    emptyWeight: '0.15',
    filledWeight: '26.70',
    filledPressure: '29.46',
    testedPressure: '48.96',
    certifications: ['SWEDAC'],
    receiptNumber: '2147483647',
    information: 'Otrolig grön hatt.',
    note: 'Söt purpur bil.\nLiten grön handskar.\nIntelligent rosa bord.',
    lastService: new Date('2024-05-13T11:11:22.404Z'),
    nextService: null,
    validFrom: new Date('2024-05-13T13:01:12.832Z'),
    created: new Date('2023-11-06T18:00:12.021Z'),
    updated: new Date('2024-05-13T03:09:34.438Z'),
});
export const createPressureTestCustomerLundstrom = createDataFunction({
    id: '2bb6cc26-38c1-4035-b88b-a68708d42a43',
    customer: createCustomerLundstrom(),
    asset: createPressureTestAssetAxel(),
    sequentialNumber: createPressureTestSequentialNumberKarlskrona(),
    servicedBy: createEmployeeSkopis(),
    filledBy: createEmployeeSkopis(),
    actions: ['UA'],
    manufacturer: 'Ström-Pettersson',
    serialNumber: '175956',
    manufacturingDate: new Date('2023-06-24T17:28:58.439Z'),
    gas: 'Tomt',
    volume: '1.68',
    diameter: '2.5',
    emptyWeight: '0.15',
    filledWeight: '26.70',
    filledPressure: '29.46',
    testedPressure: '48.96',
    certifications: ['SWEDAC'],
    receiptNumber: '2147483647',
    information: 'Otrolig grön hatt.',
    note: 'Söt purpur bil.\nLiten grön handskar.\nIntelligent rosa bord.',
    lastService: new Date('2024-05-13T11:11:22.404Z'),
    nextService: null,
    validFrom: new Date('2024-05-13T13:01:12.832Z'),
    created: new Date('2023-11-06T18:00:12.021Z'),
    updated: new Date('2024-05-13T03:09:34.438Z'),
});
export const createUpcreatePressureTest = createDataFunction({
    customerId: createCustomerMattson().id,
    assetId: createPressureTestAssetAxel().id,
    sequentialNumberId: createPressureTestSequentialNumberKarlskrona().id,
    servicedByEmployeeId: createEmployeeSkopis().id,
    filledByEmployeeId: createEmployeeSkopis().id,
    actions: ['UA'],
    manufacturer: 'Ström-Pettersson',
    serialNumber: '175956',
    manufacturingDate: new Date('2023-06-24T17:28:58.439Z'),
    gas: 'Tomt',
    volume: '1.68',
    diameter: '2.5',
    emptyWeight: '0.15',
    filledWeight: '26.70',
    filledPressure: '29.46',
    testedPressure: '48.96',
    certifications: ['SWEDAC'],
    receiptNumber: '2147483647',
    information: 'Otrolig grön hatt.',
    note: 'Söt purpur bil.\nLiten grön handskar.\nIntelligent rosa bord.',
    lastService: new Date('2024-05-13T11:11:22.404Z'),
    nextService: null,
    validFrom: new Date('2024-05-13T13:01:12.832Z'),
});
