import { isDate, isNumber, isString } from '../utils/type-utils.js';
const LOCALE = 'sv';
export function sortOriginalBy(values, path) {
    const createCopy = false;
    _sortBy(values, path, createCopy);
}
export function sortBy(values, path) {
    return _sortBy(values, path);
}
function _sortBy(values, path, createCopy = true) {
    const valueForPath = (value, path) => {
        return (path
            .split('.')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .reduce((result, part) => (result && result?.[part]) ?? undefined, value));
    };
    const copy = createCopy ? Array.from(values) : values;
    return copy.sort((first, second) => {
        const firstValue = valueForPath(first, path);
        const secondValue = valueForPath(second, path);
        if (isNumber(firstValue) && isNumber(secondValue)) {
            return firstValue - secondValue;
        }
        if (isString(firstValue) && isString(secondValue)) {
            return firstValue.toLowerCase().localeCompare(secondValue.toLowerCase(), LOCALE);
        }
        if (isDate(firstValue) && isDate(secondValue)) {
            return firstValue.getTime() - secondValue.getTime();
        }
        return 0;
    });
}
