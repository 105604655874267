import { createDataFunction } from '../../functions/index.js';
import { Access } from '../../models/employee.js';
import { CertificationType, CertificationVerificationStatus, } from '../../models/index.js';
import { createCustomerMattson } from './customer.js';
export const createCertificationRow = createDataFunction({
    id: '76cd69c1-7167-4cd9-cda2-0aad80928b36',
    rowNumber: 10,
    hidden: false,
    deleted: false,
    created: '2023-08-14T04:13:11.472Z',
    updated: '2024-02-20T07:05:46.776Z',
    data: {
        name: 'value',
    },
});
export const createCertificationRowLifeVest = (id, serialNumber, created) => createDataFunction({
    id: id ?? '76cd69c1-7167-4cd9-cda2-0aad80928b36',
    rowNumber: 10,
    hidden: false,
    deleted: false,
    created: created ?? '2023-08-14T04:13:11.472Z',
    updated: '2024-02-20T07:05:46.776Z',
    data: {
        name: 'value',
        serialNumber,
    },
});
export const createCertificationVerification = createDataFunction({
    id: '16cd69c1-7167-4cd9-bda1-0aad80928b35',
    rowNumber: 10,
    name: 'Tryckvakt',
    status: CertificationVerificationStatus.Approved,
    note: 'Everything is OK',
    created: '2023-08-14T04:13:11.472Z',
    updated: '2024-02-20T07:05:46.776Z',
});
export const createCertification = createDataFunction({
    id: '46cd69c1-7167-4cd9-bda2-0aad80928b35',
    type: CertificationType.FireExtinguisher,
    service: 'Brandsläckare',
    city: 'Karlskrona',
    text: 'Some text',
    note: 'Some note',
    customer: createCustomerMattson(),
    employee: {
        id: 'bc196860-9e34-43e2-bf67-bfa72fdbde26',
        fortnoxId: '367803',
        email: 'Inger56@hotmail.com',
        inactive: false,
        firstName: 'Inger',
        lastName: 'Johansson',
        fullName: 'Inger Johansson',
        access: [Access.Certificates],
        created: '2023-12-14T14:56:32.460Z',
        updated: '2024-02-20T02:33:44.300Z',
    },
    asset: {
        id: '03d27ad3-d75b-4ac2-a97d-8dda784827b4',
        name: 'Ergonomisk vit dator.',
        contactName: 'Ergo Datorsson',
        contactEmail: 'Siv.ManssonOlsson@gmail.com',
        contactPhone: '+46706241051',
        note: 'Otrolig grön dator.\n Liten guld skjorta.\n Robust blå dator.',
        customer: createCustomerMattson(),
        created: '2024-01-11T06:43:57.231Z',
        updated: '2024-02-20T07:08:32.048Z',
    },
    rows: [createCertificationRow()],
    verifications: [createCertificationVerification()],
    validFrom: '2023-09-14T04:06:11.472Z',
    created: '2023-08-14T04:13:11.472Z',
    updated: '2024-02-20T07:05:46.776Z',
});
export const createUpcreateCertificationFireExtinguisherRow = createDataFunction({
    rowNumber: 10,
    data: {
        name: 'Brandfilt 180x180cm',
        placement: 'Vid dörren',
        rechargePressureTest: '2025',
        note: 'En intern anteckning',
    },
    hidden: false,
    deleted: false,
});
export const createUpcreateCertificationExtinguishingSystemRow = createDataFunction({
    rowNumber: 10,
    data: {
        bottleManufacturer: 'Heiser',
        manufacturingNumber: '123456789',
        gas: 'Argonite',
        volume: '80',
        emptyWeight: '112',
        filledWeight: '120',
        filledPressure: '300bar',
        pressureTest: '2029',
        note: 'OK',
    },
    deleted: false,
    hidden: false,
});
export const createUpcreateCertificationVerification = createDataFunction({
    rowNumber: 10,
    name: 'Tryckvakt',
    status: CertificationVerificationStatus.Approved,
    note: 'Everything is OK',
});
export const createUpcreateCertificationSuitRow = createDataFunction({
    rowNumber: 10,
    data: {
        name: 'Viking SOLAS',
        manufacturingDate: '01-2024',
        expirationDate: '01-2030',
        serialNumber: '321789',
        size: '205',
        note: 'OK',
    },
    deleted: false,
    hidden: false,
});
export const createUpcreateCertificationLifeVestRow = createDataFunction({
    rowNumber: 10,
    data: {
        name: 'Viking SOLAS',
        manufacturingDate: '01-2024',
        expirationDate: '01-2030',
        serialNumber: '321789',
        size: '205',
        note: 'OK',
    },
    deleted: false,
    hidden: false,
});
export const createUpcreateCertification = createDataFunction({
    customerId: '026f7197-4726-4797-a787-d03f6cd6e93e',
    employeeId: '00212baa-75cd-4b88-8371-d184d1e20eb7',
    assetId: '03d27ad3-d75b-4ac2-a97d-8dda784827b4',
    type: CertificationType.ExtinguishingSystem,
    service: 'Släcksystem',
    city: 'Arlöv',
    text: 'Liten blå skjorta.\n Intelligent silver stol.\n Grym purpur stol.',
    note: 'Grym lila skjorta.\n Liten grå skjorta.\n Praktisk purpur skjorta.',
    validFrom: '2024-12-15 01:35:01.112',
    rows: [createUpcreateCertificationFireExtinguisherRow()],
    verifications: [
        {
            rowNumber: 10,
            name: 'Tryckvakt',
            status: CertificationVerificationStatus.Approved,
            note: 'Everything is OK',
        },
    ],
});
export const createUpcreateCertificationWithInput = (rows) => createDataFunction({
    customerId: '026f7197-4726-4797-a787-d03f6cd6e93e',
    employeeId: '00212baa-75cd-4b88-8371-d184d1e20eb7',
    assetId: '03d27ad3-d75b-4ac2-a97d-8dda784827b4',
    type: CertificationType.LifeVest,
    service: 'Släcksystem',
    city: 'Arlöv',
    text: 'Liten blå skjorta.\n Intelligent silver stol.\n Grym purpur stol.',
    note: 'Grym lila skjorta.\n Liten grå skjorta.\n Praktisk purpur skjorta.',
    validFrom: '2024-12-15 01:35:01.112',
    rows,
    verifications: [
        {
            rowNumber: 10,
            name: 'Tryckvakt',
            status: CertificationVerificationStatus.Approved,
            note: 'Everything is OK',
        },
    ],
});
export const createListCertificationBergman = createDataFunction({
    id: '0002c1b2-aa29-4059-ac35-547800b37fc6',
    type: CertificationType.ExtinguishingSystem,
    customer: {
        id: '0063e8ba-0628-4650-89eb-11ce2b0fbff0',
        name: 'Bergman AB',
    },
    rows: [],
    asset: {
        id: '0064a8ba-0628-4650-89eb-11ce2b0fb123',
        name: 'Bergmans industri fastighet',
    },
    employee: {
        id: 'a607580d-b0e4-4f5e-a55a-76b1fdbca456',
        email: 'Agnes.Holmqvist95@hotmail.com',
        firstName: 'Agnes',
        lastName: 'Holmqvist',
    },
    validFrom: '2023-08-02T04:27:10.122Z',
    created: '2023-08-02T04:27:10.122Z',
    updated: '2024-01-08T21:25:10.458Z',
});
export const createListCertificationEliasson = createDataFunction({
    id: '002ce39a-0829-4097-9425-4813b454d4a3',
    type: CertificationType.Suit,
    customer: {
        id: '45f872fe-e304-4b31-a2c3-fc427c52e9ac',
        name: 'Eliasson AB',
    },
    rows: [],
    asset: {
        id: '0065a8ba-0628-4650-89eb-11ce2b0fb125',
        name: 'Eliassons fiskebåt',
    },
    employee: {
        id: 'ef82cf22-3af1-410f-ac4f-bb466ee189ad',
        email: 'Ingrid_Holmberg@gmail.com',
        firstName: 'Ingrid',
        lastName: 'Holmberg',
    },
    validFrom: '2023-09-09T20:19:00.261Z',
    created: '2023-09-09T20:19:00.261Z',
    updated: '2024-01-09T08:34:56.066Z',
});
