import { createDataFunction } from '../../../functions/index.js';
export const createLabelKarlskrona = createDataFunction({
    Id: 27,
    Description: 'Karlskrona',
});
export const createLabelHassleholm = createDataFunction({
    Id: 28,
    Description: 'Hässleholm',
});
export const createLabelStockholm = createDataFunction({
    Id: 8,
    Description: 'Stockholm',
});
