<script setup lang="ts">
import { type models, sortBy } from 'baf-shared';
import BafTable from '@/components/standard/table/baf-table.vue';
import { computed, toRefs } from 'vue';
import { TableColumn } from '@/components/standard/table/models/table';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  certification: models.Certification;
}>();
const { certification } = toRefs(props);

const columns = [
  TableColumn.for('rowNumber', t('general.rowNumberShort'), { charactersWidth: 4 }),
  TableColumn.for('data.bottleManufacturer', t('general.bottleManufacturerShort'), {
    renderer: (value, row) => row.data.bottleManufacturer,
    charactersWidth: 10,
  }),
  TableColumn.for('data.manufacturingNumber', t('general.manufacturingNumberShort'), {
    renderer: (value, row) => row.data.manufacturingNumber,
  }),
  TableColumn.for('data.gas', t('general.gas'), {
    renderer: (value, row) => row.data.gas,
  }),
  TableColumn.for('data.volume', t('general.volumeLiterShort'), {
    renderer: (value, row) => row.data.volume,
  }),
  TableColumn.for('data.emptyWeight', t('general.emptyWeightShort'), {
    renderer: (value, row) => row.data.emptyWeight,
  }),
  TableColumn.for('data.filledWeight', t('general.filledWeightShort'), {
    renderer: (value, row) => row.data.filledWeight,
  }),
  TableColumn.for('data.filledPressure', t('general.filledPressure'), {
    renderer: (value, row) => row.data.filledPressure,
  }),
  TableColumn.for('data.pressureTest', t('general.pressureTestShort'), {
    renderer: (value, row) => row.data.pressureTest,
    charactersWidth: 10,
  }),
  TableColumn.for('data.note', '', {
    renderer: (value, row) => row.data.note,
  }),
];

const rows = computed(() => sortBy(certification.value.rows, 'rowNumber'));
</script>

<template>
  <baf-table :columns="columns" :rows="rows"></baf-table>
</template>
