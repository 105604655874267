import { models, queries } from 'baf-shared';
import { appHttpService } from '../standard/http-service';

class CertificationService {
  async getAll(): Promise<models.ListCertification[]> {
    const certifications = await appHttpService.request
      .url('/certifications')
      .get()
      .json<models.ListCertification[]>();
    return certifications;
  }

  async search(search: string): Promise<models.ListCertification[]> {
    const certifications = await appHttpService.request
      .url('/certifications')
      .query({
        search,
      })
      .get()
      .json<models.ListCertification[]>();
    return certifications;
  }

  async searchRows(
    query?: queries.SearchCertificationRowsQuery,
  ): Promise<models.CertificationRow[]> {
    const certificationRows = await appHttpService.request
      .url('/certifications/rows/search')
      .query({
        ...query,
      })
      .get()
      .json<models.CertificationRow[]>();
    return certificationRows;
  }

  async create(certification: models.UpcreateCertification): Promise<models.Certification> {
    const createdCertification = await appHttpService.request
      .url(`/certifications`)
      .post(certification)
      .json<models.Certification>();

    return createdCertification;
  }

  async update(id: string, certification: models.UpcreateCertification) {
    const updatedCertification = await appHttpService.request
      .url(`/certifications/${id}`)
      .put(certification)
      .json<models.Certification>();

    return updatedCertification;
  }

  async get(id: string): Promise<models.Certification> {
    const certification = await appHttpService.request
      .url(`/certifications/${id}`)
      .get()
      .json<models.Certification>();

    return certification;
  }

  async renew(id: string, body: models.RenewCertification) {
    const certification = await appHttpService.request
      .url(`/certifications/${id}/renew`)
      .post(body)
      .json<models.Certification>();
    return certification;
  }
}

export const certificationService = new CertificationService();
