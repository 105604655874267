import { appHttpService } from '@/services/standard/http-service';
import { models, queries } from 'baf-shared';
import { isFailedToFetchErrorOrThrow } from '../standard/failed-to-fetch-service';

class CertificationAssetService {
  async getAll(query?: queries.GetAssetsQuery): Promise<models.CertificationAsset[]> {
    try {
      const assets = await appHttpService.request
        .url('/certification-assets')
        .query({
          ...query,
        })
        .get()
        .json<models.CertificationAsset[]>();
      return assets;
    } catch (error) {
      isFailedToFetchErrorOrThrow(error);
      return [];
    }
  }

  async create(
    upcreateAsset: models.UpcreateCertificationAsset,
  ): Promise<models.CertificationAsset> {
    const asset = await appHttpService.request
      .url(`/certification-assets`)
      .post(upcreateAsset)
      .json<models.CertificationAsset>();
    return asset;
  }

  async update(
    id: string,
    upcreateAsset: models.UpcreateCertificationAsset,
  ): Promise<models.CertificationAsset> {
    const asset = await appHttpService.request
      .url(`/certification-assets/${id}`)
      .put(upcreateAsset)
      .json<models.CertificationAsset>();
    return asset;
  }

  async delete(id: string): Promise<models.CertificationAsset> {
    const asset = await appHttpService.request
      .url(`/certification-assets/${id}`)
      .delete()
      .json<models.CertificationAsset>();
    return asset;
  }
}

export const certificationAssetService = new CertificationAssetService();
