<script setup lang="ts">
import PressureTestAssetUpcreateModal from '@/components/pressure-test-asset/pressure-test-asset-upcreate-modal.vue';
import ConfirmModal from '@/components/modal/confirm-modal.vue';
import BafTable from '@/components/standard/table/baf-table.vue';
import { TableColumn, type TableValue } from '@/components/standard/table/models/table';
import { useLoading } from '@/composeables/use-loading';
import { pressureTestAssetService } from '@/services/pressure-test-asset/pressure-test-asset-service';
import { models, utils } from 'baf-shared';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const { t } = useI18n();
const route = useRoute();
const { loading, startLoading, stopLoading } = useLoading();
const assets = ref<models.PressureTestAsset[]>([]);

const columns = [
  TableColumn.for('userActions', '', {
    charactersWidth: 1,
    search: false,
    sort: false,
  }),
  TableColumn.for('customer', t('general.customer'), {
    renderer: (value) => utils.type.asType<models.Customer>(value)?.name,
  }),
  TableColumn.for('name', t('general.name')),
  TableColumn.for('contactName', t('general.contactName')),
  TableColumn.for('contactEmail', t('general.email')),
  TableColumn.for('contactPhone', t('general.phoneNumber')),
  TableColumn.for('note', t('general.note')),
];

const loadAssets = async () => {
  try {
    startLoading();
    assets.value = await pressureTestAssetService.getAll();
  } finally {
    stopLoading();
  }
};

defineExpose({
  loadAssets,
});

function onDeleteAsset(id: TableValue) {
  const assetId = id as string;
  return async () => {
    try {
      startLoading();
      await pressureTestAssetService.delete(assetId);
      assets.value = await pressureTestAssetService.getAll();
    } finally {
      stopLoading();
    }
  };
}

onBeforeMount(async () => {
  await loadAssets();
});
</script>

<template>
  <baf-table
    :columns="columns"
    :rows="assets"
    :loading="loading"
    :search-query="route.query"
    search
    paginate
    hover
  >
    <template #column-userActions="{ row }">
      <div class="tags">
        <pressure-test-asset-upcreate-modal :asset="row" #="{ open }" @upcreate="loadAssets">
          <a class="tag is-link is-medium" data-testid="editButton" @click.stop="open">
            <iconify-icon icon="mdi:edit"></iconify-icon>
            <span class="ml-2">{{ $t('general.edit') }}</span>
          </a>
        </pressure-test-asset-upcreate-modal>

        <confirm-modal
          :message="$t('general.deleteConfirm', { value: row.name })"
          :confirm-callback="onDeleteAsset(row.id)"
          #="{ open }"
        >
          <a class="tag is-danger is-medium" data-testid="deleteButton" @click.stop="open">
            <iconify-icon icon="material-symbols:delete-outline"></iconify-icon>
            <span class="ml-2">{{ $t('general.delete') }}</span>
          </a>
        </confirm-modal>
      </div>
    </template>
  </baf-table>
</template>
