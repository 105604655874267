import { models } from 'baf-shared';
import { appHttpService } from '@/services/standard/http-service';

class PressureTestSequentialNumberService {
  async getAll(): Promise<models.PressureTestSequentialNumber[]> {
    const sequentialNumbers = await appHttpService.request
      .url('/pressure-test-sequential-number')
      .get()
      .json<models.PressureTestSequentialNumber[]>();
    return sequentialNumbers;
  }

  async get(id: string): Promise<models.PressureTestSequentialNumber> {
    const sequentialNumber = await appHttpService.request
      .url(`/pressure-test-sequential-number/${id}`)
      .get()
      .json<models.PressureTestSequentialNumber>();
    return sequentialNumber;
  }

  async create(
    data: models.CreatePressureTestSequentialNumber,
  ): Promise<models.PressureTestSequentialNumber> {
    const sequentialNumber = await appHttpService.request
      .url('/pressure-test-sequential-number')
      .post(data)
      .json<models.PressureTestSequentialNumber>();

    return sequentialNumber;
  }
}

export const pressureTestSequentialNumberService = new PressureTestSequentialNumberService();
