<script setup lang="ts">
import { RouteName } from '@/router/models/route-name';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

type Route = {
  name: RouteName;
  text: string;
  activeRouteNames: string[];
};

const activeRoute = useRoute();

defineOptions({
  inheritAttrs: false,
});

const { t } = useI18n();

const routes = computed<Route[]>(() => [
  {
    name: RouteName.DataArticles,
    text: t('general.routeName.articles'),
    activeRouteNames: [RouteName.DataArticles],
  },
  {
    name: RouteName.DataCustomers,
    text: t('general.routeName.customers'),
    activeRouteNames: [RouteName.DataCustomers],
  },
  {
    name: RouteName.DataEmployees,
    text: t('general.routeName.employees'),
    activeRouteNames: [RouteName.DataEmployees],
  },
]);
</script>

<template>
  <div class="tabs is-boxed is-fullwidth">
    <ul>
      <router-link
        v-for="route in routes"
        v-slot="{ href, isExactActive, navigate }"
        :key="route.name"
        :to="{ name: route.name }"
        custom
      >
        <li
          :class="{
            'is-active': isExactActive || route.activeRouteNames.includes(String(activeRoute.name)),
          }"
        >
          <a :href="href" @click="navigate">{{ route.text }}</a>
        </li>
      </router-link>
    </ul>
  </div>

  <router-view v-slot="{ Component }">
    <div class="px-4">
      <component :is="Component" v-bind="$attrs" />
    </div>
  </router-view>
</template>
