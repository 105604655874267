import { createDataFunction } from '../../functions/create-data-function.js';
import { ArticleType } from '../../models/article.js';
export const createFireExtinguisher = createDataFunction({
    id: '1a8a916c-edf5-43d6-8d19-51b4bb7b2178',
    fortnoxId: '1',
    description: 'Brandsläckare Vatten Housegard 6 L',
    certifications: ['SS-EN3', 'CE', 'Wheelmark'],
    categories: ['Brandsläckare'],
    type: ArticleType.Article,
    ean: '5817381914006',
});
export const createFireBlanket = createDataFunction({
    id: '1b295bab-4ec1-49bc-b639-997b8d17e1b6',
    fortnoxId: '2',
    description: 'Brandfilt 180x180cm',
    certifications: [],
    categories: ['Tillbehör'],
    type: ArticleType.Article,
    ean: '6506435366580',
});
export const createCheckAction = createDataFunction({
    id: '8fd2aebd-89cb-4b54-ba07-560d99d00157',
    fortnoxId: '10',
    description: 'Kontroll',
    certifications: [],
    categories: ['Tjänst'],
    type: ArticleType.Action,
    ean: '6506435366580',
});
export const createLifeVest = createDataFunction({
    id: '1a8a916c-edf5-43d6-8d19-51b4bb7b2179',
    fortnoxId: '11',
    description: 'Baltic LEGEND',
    certifications: [],
    categories: ['Livväst'],
    type: ArticleType.Article,
    ean: '5817381914001',
});
export const createSuit = createDataFunction({
    id: '1b8a916c-edf5-43d6-8d19-51b4bb7b2178',
    fortnoxId: '12',
    description: 'Sweden Survivor',
    certifications: [],
    categories: ['Dräkt'],
    type: ArticleType.Article,
    ean: '5817381914002',
});
