import { computed, ref } from 'vue';

export function useOpenable(hoverable = false) {
  const openValue = ref(false);
  const toggleOpen = () => (openValue.value = !openValue.value);
  const open = () => (openValue.value = true);
  const close = () => (openValue.value = false);

  const elementClass = computed(() => ({
    'is-active': !hoverable && openValue.value,
    'is-hoverable': hoverable,
  }));

  return {
    openValue,
    toggleOpen,
    open,
    close,
    elementClass,
  };
}
