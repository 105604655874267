import { createDataFunction } from '../../functions/create-data-function.js';
export const createLabelKarlskrona = createDataFunction({
    id: 'c6062d39-18d2-4952-989c-854915a157fc',
    fortnoxId: 27,
    description: 'Karlskrona',
    fortnoxDescription: 'Karlskrona',
    assignedServiceOrders: 3,
    assignedServiceOrderRows: 10,
    created: '2024-02-10T16:52:37.539Z',
    updated: '2024-02-21T03:16:29.853Z',
});
export const createLabelHassleholm = createDataFunction({
    id: '6b91b4ea-9e89-44cf-88b6-a296e93b0fad',
    fortnoxId: 28,
    description: 'Hässleholm',
    fortnoxDescription: 'Hässleholm',
    assignedServiceOrders: 3,
    assignedServiceOrderRows: 10,
    created: '2023-08-14T04:13:11.472Z',
    updated: '2024-02-20T07:05:46.776Z',
});
export const createLabelStockholm = createDataFunction({
    id: '760670fc-8675-4d98-b895-32b3f11374ea',
    fortnoxId: 8,
    description: 'Stockholm',
    fortnoxDescription: 'Stockholm',
    assignedServiceOrders: 3,
    assignedServiceOrderRows: 10,
    created: '2024-08-08T04:13:11.472Z',
    updated: '2024-08-18T07:05:46.776Z',
});
export const createLabels = createDataFunction([
    createLabelKarlskrona(),
    createLabelHassleholm(),
    createLabelStockholm(),
]);
