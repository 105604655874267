<script setup lang="ts" generic="TModel extends Nullish<string | number>">
import type { Nullish } from 'baf-shared';
import { computed, toRefs, useAttrs } from 'vue';
import BafField from './baf-field.vue';

const SUFFIX = 'TextareaField';

defineOptions({
  inheritAttrs: false,
});
const model = defineModel<TModel>();

const attrs = useAttrs();

const props = withDefaults(
  defineProps<{
    id: string;
    label: string;
    hideLabel?: boolean;
  }>(),
  {
    hideLabel: false,
  },
);
const { id, label, hideLabel } = toRefs(props);

const labelClasses = computed(() => ({
  'is-sr-only': hideLabel.value,
}));

const testId = computed(() => attrs['data-testid'] ?? `${id.value}${SUFFIX}`);
</script>

<template>
  <baf-field>
    <label class="label" :class="labelClasses" :for="id"> {{ label }}</label>
    <div class="control">
      <textarea :id="id" v-model="model" class="textarea" v-bind="attrs" :data-testid="testId" />
    </div>
  </baf-field>
</template>
