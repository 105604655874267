<script setup lang="ts">
import { type models, utils } from 'baf-shared';
import { toRefs } from 'vue';
import TitleValueRoot from '@/components/standard/title-value/title-value-root.vue';
import TitleValue from '@/components/standard/title-value/title-value.vue';
import PrintUnderline from '@/components/print/standard/print-underline.vue';

const props = defineProps<{
  pressureTests: models.PressureTest[];
  startRowNumber: number;
}>();
const { pressureTests } = toRefs(props);
</script>

<template>
  <table>
    <thead>
      <th class="minimal-table-column">{{ $t('general.rowNumberShort') }}</th>
      <th class="minimal-table-column">{{ $t('general.customerNumberShort') }}</th>
      <th class="minimal-table-column">{{ $t('general.customer') }}</th>
      <th class="minimal-table-column">{{ $t('general.address') }}</th>
      <th></th>
      <th></th>
      <th class="nowrap"></th>
    </thead>

    <tbody>
      <template v-for="(pressureTest, index) in pressureTests" :key="pressureTest.id">
        <tr>
          <td>{{ startRowNumber + index }}</td>
          <td>{{ pressureTest.customer.fortnoxId }}</td>
          <td>{{ pressureTest.customer.name }}</td>
          <td>{{ pressureTest.asset.name }}</td>
          <td>
            <title-value-root fullwidth>
              <title-value
                class="py-0"
                :title="$t('general.bottleManufacturerShort')"
                :value="pressureTest.manufacturer"
              ></title-value>
              <title-value
                class="py-0"
                :title="$t('general.manufacturingNumberShort')"
                :value="pressureTest.serialNumber"
              ></title-value>
              <title-value
                class="py-0"
                :title="$t('general.gas')"
                :value="pressureTest.gas"
              ></title-value>
              <title-value
                class="py-0"
                :title="$t('general.volumeLiter')"
                :value="pressureTest.volume"
              ></title-value>
              <title-value
                class="py-0"
                :title="$t('general.emptyWeight')"
                :value="pressureTest.emptyWeight"
              ></title-value>
              <title-value
                class="py-0"
                :title="$t('general.diameter')"
                :value="pressureTest.diameter"
              ></title-value>
            </title-value-root>
          </td>
          <td>
            <title-value-root class="mb-0 pb-0" fullwidth>
              <title-value
                class="py-0"
                :title="$t('general.filledWeight')"
                :value="pressureTest.filledWeight"
              ></title-value>
              <title-value
                class="py-0"
                :title="$t('general.filledPressure')"
                :value="pressureTest.filledPressure"
              ></title-value>
              <title-value
                class="py-0"
                :title="$t('general.pressureTestShortAlternative')"
                :value="pressureTest.testedPressure"
              ></title-value>
              <title-value
                class="py-0"
                :title="$t('general.date')"
                :value="utils.date.formatDate(pressureTest.validFrom)"
              ></title-value>
              <title-value
                class="py-0"
                :title="utils.joinValues(pressureTest.certifications ?? [], ', ')"
                default-value=""
              ></title-value>
              <title-value
                class="py-0"
                :title="$t('general.receiptNumberShort')"
                :value="pressureTest.receiptNumber"
              ></title-value>
            </title-value-root>
          </td>
          <td class="minimal-table-column">
            <title-value-root>
              <title-value
                class="py-0"
                :title="$t('general.remark')"
                :block="pressureTest.actions.length > 1"
              >
                <span class="limit-text-to-three-rows">
                  {{ utils.joinValues(pressureTest.actions, ', ') }}
                </span>
              </title-value>
              <title-value :title="$t('general.control')" class="pt-4 pb-0">
                <print-underline :size="10"></print-underline>
              </title-value>
              <title-value
                :title="$t('general.reoccurringControlText')"
                class="py-0 nowrap"
                default-value=""
                block
              ></title-value>
            </title-value-root>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
.minimal-table-column {
  width: 1%;
}
.nowrap {
  white-space: nowrap;
}
.limit-text-to-three-rows {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
