import { models, utils } from 'baf-shared';
import {
  listServiceOrderIdbService,
  serviceOrderIdbService,
} from '../indexed-db/service-order-idb-service';
import { isFailedToFetchErrorOrThrow } from '../standard/failed-to-fetch-service';
import { appHttpService } from '../standard/http-service';
import { ServiceOrderRowStatus } from 'baf-shared/dist/models';

class ServiceOrderService {
  async syncServiceOrdersForOfflineUsage(serviceOrders: models.ListServiceOrder[]) {
    for (const serviceOrder of serviceOrders) {
      try {
        const existingServiceOrder = await serviceOrderIdbService.getOrThrow(serviceOrder.id);

        if (utils.date.isBefore(existingServiceOrder.updated, serviceOrder.updated)) {
          await this.get(serviceOrder.id);
        }
      } catch (e) {
        await this.get(serviceOrder.id);
      }
    }
  }

  async getAll(): Promise<models.ListServiceOrder[]> {
    try {
      const serviceOrders = await appHttpService.request
        .url('/service-orders')
        .get()
        .json<models.ListServiceOrder[]>();
      listServiceOrderIdbService.putAll(serviceOrders);
      return serviceOrders;
    } catch (error) {
      isFailedToFetchErrorOrThrow(error);
      return listServiceOrderIdbService.getAll();
    }
  }

  async get(id: string): Promise<models.ServiceOrder> {
    try {
      const serviceOrder = await appHttpService.request
        .url(`/service-orders/${id}`)
        .get()
        .json<models.ServiceOrder>();
      await serviceOrderIdbService.put(serviceOrder);
      return serviceOrder;
    } catch (error) {
      isFailedToFetchErrorOrThrow(error);
      return serviceOrderIdbService.getOrThrow(id);
    }
  }

  async updateEmployee(id: string, employeeId: string) {
    const serviceOrder = await appHttpService.request
      .url(`/service-orders/${id}/employee`)
      .put({
        employeeId,
      })
      .json<models.ServiceOrder>();
    await serviceOrderIdbService.put(serviceOrder);
    return serviceOrder;
  }

  async updateEmployeeOnServiceOrders(serviceOrderIds: string[], employeeId: string) {
    const serviceOrders = await appHttpService.request
      .url(`/service-orders/employee`)
      .put({
        serviceOrderIds,
        employeeId,
      } as models.UpdateEmployeeOnServiceOrdersBody)
      .json<models.ServiceOrder[]>();
    serviceOrderIdbService.putAll(serviceOrders);
    return serviceOrders;
  }

  async updateLabels(id: string, labelIds: string[]): Promise<models.ServiceOrder> {
    const serviceOrder = await appHttpService.request
      .url(`/service-orders/${id}/labels`)
      .put({
        labelIds,
      })
      .json<models.ServiceOrder>();
    await serviceOrderIdbService.put(serviceOrder);
    return serviceOrder;
  }

  async updateLabelsOnServiceOrders(serviceOrderIds: string[], labelIds: string[]) {
    const serviceOrders = await appHttpService.request
      .url(`/service-orders/labels`)
      .put({
        serviceOrderIds,
        labelIds,
      } as models.UpdateLabelsOnServiceOrdersBody)
      .json<models.ServiceOrder[]>();
    serviceOrderIdbService.putAll(serviceOrders);
    return serviceOrders;
  }

  async updateStatus(
    id: string,
    body: models.UpdateServiceOrderStatusBody,
  ): Promise<models.ServiceOrder> {
    try {
      const serviceOrder = await appHttpService.request
        .url(`/service-orders/${id}/status`)
        .put(body)
        .json<models.ServiceOrder>();
      await serviceOrderIdbService.put(serviceOrder);
      return serviceOrder;
    } catch (error) {
      isFailedToFetchErrorOrThrow(error);
      const existingServiceOrder = await serviceOrderIdbService.getOrThrow(id);
      await serviceOrderIdbService.put(utils.json.merge(existingServiceOrder, body));

      const existingListServiceOrder = await listServiceOrderIdbService.getOrThrow(id);
      await listServiceOrderIdbService.put(utils.json.merge(existingListServiceOrder, body));

      return serviceOrderIdbService.getOrThrow(id);
    }
  }

  async createServiceOrderRow(id: string, row: models.UpcreateServiceOrderRow) {
    try {
      const serviceOrder = await appHttpService.request
        .url(`/service-orders/${id}/service-order-row`)
        .post(row)
        .json<models.ServiceOrder>();
      await serviceOrderIdbService.put(serviceOrder);
      return serviceOrder;
    } catch (error) {
      isFailedToFetchErrorOrThrow(error);
      const existingServiceOrder = await serviceOrderIdbService.getOrThrow(id);
      const rows = utils.json.copy(existingServiceOrder.rows);
      rows.push({
        id: error.generatedId!,
        ...row,
        article: {
          ...row.article,
          categories: [...row.article.categories],
          certifications: [...row.article.certifications],
        },
        actions: row.actions.map((action) => ({
          id: crypto.randomUUID(),
          article: {
            ...action.article,
            categories: [...action.article.categories],
            certifications: [...action.article.certifications],
          },
          created: utils.date.nowInISO(),
          updated: utils.date.nowInISO(),
        })),
        status: ServiceOrderRowStatus.InProgress,
        marked: false,
        important: false,
        highlighted: false,
        deleted: false,
        created: utils.date.nowInISO(),
        updated: utils.date.nowInISO(),
      });
      await serviceOrderIdbService.put(utils.json.merge(existingServiceOrder, { rows }));

      const existingListServiceOrder = await listServiceOrderIdbService.getOrThrow(id);
      await listServiceOrderIdbService.put(
        utils.json.merge(existingListServiceOrder, {
          connectedServiceOrderRows: existingListServiceOrder.connectedServiceOrderRows + 1,
        }),
      );

      return serviceOrderIdbService.getOrThrow(id);
    }
  }

  async updateInternalServiceOrderRow(
    id: string,
    rowId: string,
    row: models.UpdateInternalServiceOrderRow,
  ) {
    try {
      const serviceOrder = await appHttpService.request
        .url(`/service-orders/${id}/service-order-row/${rowId}/internal`)
        .put(row)
        .json<models.ServiceOrder>();
      await serviceOrderIdbService.put(serviceOrder);
      return serviceOrder;
    } catch (error) {
      isFailedToFetchErrorOrThrow(error);

      const existingServiceOrder = await serviceOrderIdbService.getOrThrow(id);
      const rows = utils.json.copy(existingServiceOrder.rows);
      const rowIndex = rows.findIndex((row) => row.id === rowId);
      const updatedRow = utils.json.merge(rows[rowIndex], {
        marked: row.marked,
      });
      rows.splice(rowIndex, 0, updatedRow);

      await serviceOrderIdbService.put(utils.json.merge(existingServiceOrder, { rows }));
      return serviceOrderIdbService.getOrThrow(id);
    }
  }

  async updateServiceOrderRow(id: string, rowId: string, row: models.UpcreateServiceOrderRow) {
    try {
      const serviceOrder = await appHttpService.request
        .url(`/service-orders/${id}/service-order-row/${rowId}`)
        .put(row)
        .json<models.ServiceOrder>();
      await serviceOrderIdbService.put(serviceOrder);
      return serviceOrder;
    } catch (error) {
      isFailedToFetchErrorOrThrow(error);
      const existingServiceOrder = await serviceOrderIdbService.getOrThrow(id);
      const rows = utils.json.copy(existingServiceOrder.rows);
      const rowIndex = rows.findIndex((row) => row.id === rowId);
      const updatedRow = utils.json.merge(rows[rowIndex], {
        ...row,
        article: {
          ...row.article,
          categories: [...row.article.categories],
          certifications: [...row.article.certifications],
        },
        actions: row.actions.map((action) => ({
          id: crypto.randomUUID(),
          article: {
            ...action.article,
            categories: [...action.article.categories],
            certifications: [...action.article.certifications],
          },
          created: utils.date.nowInISO(),
          updated: utils.date.nowInISO(),
        })),
      });
      rows.splice(rowIndex, 1, updatedRow);

      await serviceOrderIdbService.put(utils.json.merge(existingServiceOrder, { rows }));
      return serviceOrderIdbService.getOrThrow(id);
    }
  }

  async updateServiceOrderRowStatus(
    id: string,
    rowId: string,
    body: models.UpdateServiceOrderRowStatusBody,
  ) {
    try {
      const serviceOrder = await appHttpService.request
        .url(`/service-orders/${id}/service-order-row/${rowId}/status`)
        .put(body)
        .json<models.ServiceOrder>();
      await serviceOrderIdbService.put(serviceOrder);
      return serviceOrder;
    } catch (error) {
      isFailedToFetchErrorOrThrow(error);
      const existingServiceOrder = await serviceOrderIdbService.getOrThrow(id);
      const rows = utils.json.copy(existingServiceOrder.rows);
      const rowIndex = rows.findIndex((row) => row.id === rowId);
      const updatedRow = utils.json.merge(rows[rowIndex], body);
      rows.splice(rowIndex, 1, updatedRow);
      await serviceOrderIdbService.put(utils.json.merge(existingServiceOrder, { rows }));
      return serviceOrderIdbService.getOrThrow(id);
    }
  }

  async updateServiceOrder(id: string, serviceOrder: models.UpdateServiceOrder) {
    try {
      const updatedServiceOrder = await appHttpService.request
        .url(`/service-orders/${id}`)
        .put(serviceOrder)
        .json<models.ServiceOrder>();
      await serviceOrderIdbService.put(updatedServiceOrder);
      return updatedServiceOrder;
    } catch (error) {
      isFailedToFetchErrorOrThrow(error);
      const existingServiceOrder = await serviceOrderIdbService.getOrThrow(id);
      await serviceOrderIdbService.put(utils.json.merge(existingServiceOrder, serviceOrder));
      return serviceOrderIdbService.getOrThrow(id);
    }
  }

  async createServiceOrder(serviceOrder: models.CreateServiceOrder) {
    const createdServiceOrder = await appHttpService.request
      .url(`/service-orders`)
      .post(serviceOrder)
      .json<models.ServiceOrder>();
    await serviceOrderIdbService.put(createdServiceOrder);
    return createdServiceOrder;
  }

  async copyServiceOrders(serviceOrderIds: string[]) {
    const copiedServiceOrders = await appHttpService.request
      .url(`/service-orders/copy`)
      .post({ serviceOrderIds })
      .json<models.ServiceOrder[]>();

    serviceOrderIdbService.putAll(copiedServiceOrders);
    return copiedServiceOrders;
  }
}

export const serviceOrderService = new ServiceOrderService();
