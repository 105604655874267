<script setup lang="ts" generic="GenericTableRow extends TableRow">
import { LocalStorageService } from '@/services/storage/local-storage-service';
import { type Nullish, sortBy, utils } from 'baf-shared';
import { computed, onActivated, onBeforeMount, ref, type Ref, toRefs, watch } from 'vue';
import BafLoader from '../loader/baf-loader.vue';
import { TableColumn, type TableRow, type TableValue } from './models/table';

type BafTablesSettingsState = Record<string, BafTableSettingsState | undefined>;

interface BafTableSettingsState {
  search: { queries: Record<string, string> };
  sort: {
    key: Nullish<string>;
    asc?: boolean;
  };
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
}

const createBafTablesSettingsState = (): BafTablesSettingsState => ({
  serviceOrderTable: undefined,
});

class BafTablesSettingsService extends LocalStorageService<BafTablesSettingsState> {
  constructor() {
    super('settings', createBafTablesSettingsState);
  }

  getSettings(key: keyof BafTablesSettingsState) {
    return this.getState()[key];
  }

  setSettings(key: keyof BafTablesSettingsState, settings: BafTableSettingsState): void {
    this.setState({ [key]: settings });
  }
}
const bafTablesSettingsService = new BafTablesSettingsService();

function loadFromSettingsService() {
  if (persistState.value) {
    const defaultSettings = {
      search: { queries: {} },
      sort: {
        key: sortDefault?.value?.key,
        asc: sortDefault?.value?.asc ?? true,
      },
      pagination: {
        pageNumber: 1,
        pageSize: pageSizes.value.at(0)!,
      },
    };

    const { search, sort, pagination } =
      bafTablesSettingsService.getSettings(persistState.value!) ?? defaultSettings;

    searchState.value.queries = search.queries;
    sortState.value.key = sort.key;
    sortState.value.asc = sort.asc;
    paginationState.value.pageNumber = pagination.pageNumber;
    paginationState.value.pageSize = pagination.pageSize;

    watch([searchState.value, sortState.value, paginationState.value], () => {
      bafTablesSettingsService.setSettings(persistState.value!, {
        search: searchState.value,
        sort: sortState.value,
        pagination: paginationState.value,
      });
    });
  }
}

type SortState = {
  key: Nullish<string>;
  asc?: boolean;
};
type SearchQuery = Record<string, Nullish<string> | Nullish<string>[]>;
type RenderedRow = Record<
  string,
  {
    value: TableValue;
    rendered: string | string[];
  }
> & {
  $raw: GenericTableRow;
};

const props = withDefaults(
  defineProps<{
    columns: TableColumn[];
    rows: GenericTableRow[];
    rowClass?: { [key: string]: (row: GenericTableRow) => boolean };
    loading?: boolean;
    sort?: boolean;
    sortDefault?: SortState;
    search?: boolean;
    searchQuery?: SearchQuery;
    paginate?: boolean;
    hover?: boolean;
    checkbox?: boolean;
    actions?: boolean;
    maxActionsHeight?: string;
    persistState?: keyof BafTablesSettingsState;
  }>(),
  {
    rowClass: undefined,
    loading: false,
    sort: true,
    sortDefault: undefined,
    search: false,
    searchQuery: undefined,
    paginate: false,
    hover: false,
    checkbox: false,
    actions: false,
    maxActionsHeight: undefined,
    persistState: undefined,
  },
);
const {
  columns,
  rows,
  rowClass,
  loading,
  sort,
  sortDefault,
  search,
  searchQuery,
  paginate,
  hover,
  checkbox,
  actions,
  maxActionsHeight,
  persistState,
} = toRefs(props);

const tableRows = ref();
defineExpose({
  tableRows,
});

const emit = defineEmits<{
  (e: 'click-row', row: TableRow, rowIndex: number): void;
  (e: 'check-row', rows: TableRow[]): void;
}>();
const activatedHookRunning = ref(false);

const searchState = ref({
  queries: {} as Record<string, string>,
});
const sortState = ref<SortState>({
  key: sortDefault?.value?.key,
  asc: sortDefault?.value?.asc ?? true,
});
const pageSizes = ref([20, 40, 60, 80, 100]);
const paginationState = ref({
  pageNumber: 1,
  pageSize: pageSizes.value.at(0)!,
});
const selectedPageSize = ref(pageSizes.value.at(0)!);

const lastPageNumber = computed(() =>
  Math.ceil($rows.value.length / paginationState.value.pageSize),
);
const previousDisabled = computed(() => paginationState.value.pageNumber <= 1);
const nextDisabled = computed(() => paginationState.value.pageNumber >= lastPageNumber.value);
const hasPagination = computed(() => paginate.value && rows.value.length > pageSizes.value.at(0)!);
const hasRowsChecked = computed(() => $rowsChecked.value.length > 0);

function clickSort(column: TableColumn) {
  if (!sort.value || !column?.options?.sort) {
    return;
  }

  if (sortState.value.key === column.key) {
    sortState.value.asc = !sortState.value.asc;
  } else {
    sortState.value.key = column.key;
    sortState.value.asc = true;
  }
}

function clickPrevious() {
  if (!previousDisabled.value) {
    paginationState.value.pageNumber--;
  }
}

function clickNext() {
  if (!nextDisabled.value) {
    paginationState.value.pageNumber++;
  }
}

function inputSearch() {
  paginationState.value.pageNumber = 1;
  uncheckAllRows();
}

function trClass(row: GenericTableRow) {
  const trClass = rowClass.value ?? {};
  const trClassWithComputedValue = Object.keys(trClass).map((key) => ({
    [key]: trClass[key](row),
  }));
  const trClassMergedIntoSingleObject = Object.assign({}, ...trClassWithComputedValue);
  return {
    'is-baf-active': rowChecked(row),
    ...trClassMergedIntoSingleObject,
  };
}

function tdClass(column: TableColumn) {
  return {
    'has-text-right': column.options.right,
  };
}

function thClass(column: TableColumn) {
  return {
    ...tdClass(column),
    'is-clickable': sort.value,
  };
}

function thStyle(column: TableColumn) {
  const { charactersWidth } = column.options;
  return {
    width: charactersWidth ? `${charactersWidth * 10}px` : undefined,
  };
}

const tableContainerClass = computed(() => ({
  'has-actions': hasRowsChecked.value,
}));
const tableClass = computed(() => ({
  'is-hoverable': hover.value,
  'has-actions': hasRowsChecked.value,
}));
const tableActionsClass = computed(() => ({}));

function changePageSize() {
  paginationState.value.pageSize = selectedPageSize.value;

  if (paginationState.value.pageNumber > lastPageNumber.value) {
    paginationState.value.pageNumber = lastPageNumber.value;
  }
}

function loadSearchQueriesFromSearchQuery(query?: SearchQuery) {
  let searchQueries: Record<string, string> | undefined;

  $columns.value.forEach(({ key }) => {
    const queryValue = query?.[key];

    if (queryValue && utils.type.isString(queryValue)) {
      searchQueries ??= {};
      searchQueries[key] = queryValue;
    }
  });

  const tableSettings = bafTablesSettingsService.getSettings(persistState.value ?? '');
  const tableSettingsHasSearchQueries =
    Object.values(tableSettings?.search?.queries ?? {}).filter(Boolean).length > 0;

  const tableSettingsKeys = Object.entries(tableSettings?.search?.queries ?? {})
    .filter(([, value]) => Boolean(value))
    .map(([key]) => key)
    .map((v) => v.toLowerCase())
    .sort()
    .join();
  const searchQuerysKeys = Object.keys(searchQueries ?? {})
    .map((v) => v.toLowerCase())
    .sort()
    .join();
  const tableSettingsHasSameKeysAsSearchQueries = tableSettingsKeys === searchQuerysKeys;

  if (
    searchQueries &&
    (!tableSettingsHasSearchQueries || tableSettingsHasSameKeysAsSearchQueries)
  ) {
    searchState.value.queries = {
      ...searchState.value.queries,
      ...searchQueries,
    };
  }
}

function resetFilter() {
  searchState.value.queries = {} as Record<string, string>;
  sortState.value.key = sortDefault?.value?.key;
  sortState.value.asc = sortDefault?.value?.asc ?? true;
  paginationState.value.pageNumber = 1;
}

function loadIntoComponent() {
  loadFromSettingsService();
  loadSearchQueriesFromSearchQuery(searchQuery.value);
}

const $columns = computed(() => columns.value);
const $columnKeys = computed(() => {
  const columnKeys = $columns.value.map((column) => column.key);
  const rowKeys = Object.keys(rows.value.at(0) ?? {});
  return utils.uniqueArray([...columnKeys, ...rowKeys]);
});
const $columnsByKey = computed<Record<string, TableColumn>>(() =>
  $columns.value.reduce(
    (previous, current) => ({
      ...previous,
      [current.key]: current,
    }),
    {},
  ),
);
const $columnsLength = computed(() => $columns.value.length + (checkbox.value ? 1 : 0));

const $renderedRows = computed(() => {
  const _rows = rows.value.map((row) => {
    return $columnKeys.value.reduce(
      (result, key) => {
        const value = row?.[key] ?? undefined;
        const rendered = $columnsByKey.value?.[key]?.options?.renderer(value, row) ?? value;

        return {
          ...result,
          [key]: {
            value,
            rendered,
          },
        };
      },
      {
        $raw: row,
      } as RenderedRow,
    );
  });

  return _rows;
});

const $uniqueRowValuesPerColumn = computed(() => {
  if (!search.value) {
    return {};
  }
  const _rows = Array.from($renderedRows.value);
  const _columns = Array.from($columns.value).filter((column) => column.options.select);

  const valuesPerColumn = _columns.reduce(
    (result, current) => ({
      ...result,
      [current.key]: [],
    }),
    {} as Record<string, string[]>,
  );

  _rows.forEach((row) => {
    _columns.forEach((column) => {
      const existingValues = valuesPerColumn[column.key];
      const renderedValue = row[column.key].rendered;
      const newValues = Array.isArray(renderedValue) ? renderedValue : [renderedValue];
      valuesPerColumn[column.key] = [...existingValues, ...newValues];
    });
  });

  _columns.forEach((column) => {
    const values = valuesPerColumn[column.key];
    const uniqueValues = utils.uniqueArray(values);

    valuesPerColumn[column.key] = uniqueValues
      .map((value) => value.trim())
      .filter(Boolean)
      .sort();
  });

  return valuesPerColumn;
});

const $rows = computed(() => {
  let _rows = Array.from($renderedRows.value);

  if (sortState.value.key) {
    _rows = sortBy(_rows, `${sortState.value.key}.rendered`);
  }

  if (!sortState.value.asc) {
    _rows.reverse();
  }

  if (search.value) {
    const searchQueries = Object.entries(searchState.value.queries).filter(([, searchQuery]) =>
      Boolean(searchQuery),
    );

    if (searchQueries) {
      _rows = _rows.filter((row) => {
        const match = searchQueries.every(([key, searchQuery]) => {
          const renderedValue = row[key].rendered;
          const lowerCaseRenderedValue = String(renderedValue).toLowerCase();
          const lowerCaseSearchQuery = searchQuery.toLowerCase();

          return lowerCaseRenderedValue.includes(lowerCaseSearchQuery);
        });

        return match;
      });
    }
  }

  return _rows;
});
const $rowsPaginated = computed(() => {
  const _rows = $rows.value;
  const { pageNumber, pageSize } = paginationState.value;

  const startIndex = (pageNumber - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  return _rows.slice(startIndex, endIndex);
});
const $rowsPaginatedOrRows = computed(() =>
  hasPagination.value ? $rowsPaginated.value : $rows.value,
);
const $empty = computed(() => $rows.value.length === 0);

const $rowsChecked = ref([]) as Ref<GenericTableRow[]>;
const onClickRow = (raw: GenericTableRow, rowIndex: number) => {
  if (checkbox.value) {
    onCheckRow(raw);
  }
  emit('click-row', raw, rowIndex);
};

function onCheckRow(row: GenericTableRow) {
  const index = $rowsChecked.value.indexOf(row);
  if (index >= 0) {
    $rowsChecked.value.splice(index, 1);
  } else {
    $rowsChecked.value.push(row);
  }

  emit('check-row', $rowsChecked.value);
}

function onCheckAllVisibleRows() {
  if (visibleRowsChecked.value) {
    uncheckAllRows();
  } else {
    $rowsChecked.value = [
      ...$rowsChecked.value,
      ...$rowsPaginatedOrRows.value.map((row) => row.$raw),
    ];
  }
  emit('check-row', $rowsChecked.value);
}

function uncheckAllRows() {
  $rowsChecked.value = [];
}

function rowChecked(row: GenericTableRow) {
  return $rowsChecked.value.includes(row);
}

const visibleRowsChecked = computed(() => {
  const rowIds = $rowsPaginatedOrRows.value.map((row) => row.$raw.id);
  const rowCheckedIds = $rowsChecked.value.map((row) => row.id);
  const rowCheckedIdsContainsAllRowIds = rowIds.every((id) => rowCheckedIds.includes(id));

  return !loading.value && rowCheckedIdsContainsAllRowIds;
});

onActivated(() => {
  activatedHookRunning.value = true;
  loadIntoComponent();
  activatedHookRunning.value = false;
});
onBeforeMount(() => {
  if (activatedHookRunning.value) {
    return;
  }

  loadIntoComponent();
});

const slotActionsBind = computed(() => ({
  rows: $rows.value.map((row) => row.$raw),
  rowsChecked: $rowsChecked.value,
}));
</script>

<template>
  <div class="table-pagination columns m-0 is-gapless is-mobile px-4 pb-4">
    <div v-if="hasPagination" class="column is-narrow">
      <div class="field has-addons is-align-items-center">
        <div class="control">
          <button class="button is-small" :disabled="previousDisabled" @click="clickPrevious">
            &lt;
          </button>
        </div>
        <div class="control">
          <span class="py-2 px-3 is-bordered"
            >{{ paginationState.pageNumber }} / {{ lastPageNumber }}</span
          >
          <span class="py-2 px-3 is-bordered"
            >{{ $rowsPaginated.length }} ({{ $rows.length }}) / {{ rows.length }}</span
          >
        </div>
        <div class="control">
          <button class="button is-small" :disabled="nextDisabled" @click="clickNext">&gt;</button>
        </div>
      </div>
    </div>

    <div v-if="hasPagination" class="column spacer"></div>

    <div v-if="hasPagination" class="column is-narrow">
      <div class="field has-addons is-align-items-center">
        <div class="control">
          <span class="py-2 px-3 is-bordered has-radius-left">{{ $t('general.pageSize') }}</span>
        </div>
        <label class="label is-sr-only" for="page-size">{{ $t('general.pageSize') }}</label>
        <div class="control">
          <div class="select is-small">
            <select id="page-size" v-model="selectedPageSize" @change="changePageSize">
              <option v-for="pageSize in pageSizes" :key="pageSize" :value="pageSize">
                {{ pageSize }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div v-if="persistState" class="column is-narrow" :class="{ 'ml-4': hasPagination }">
      <div class="field has-addons is-align-items-center">
        <div class="control">
          <button class="button is-small" @click="resetFilter">
            {{ $t('general.resetFilter') }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="table-container" :class="tableContainerClass">
    <div
      v-if="actions && checkbox && hasRowsChecked"
      class="table-actions p-4"
      :class="tableActionsClass"
    >
      <slot name="actions" v-bind="slotActionsBind"></slot>
    </div>

    <table class="table is-fullwidth" :class="tableClass">
      <thead>
        <th v-if="checkbox">
          <label class="checkbox">
            <input
              type="checkbox"
              :indeterminate="loading"
              :checked="visibleRowsChecked"
              data-testid="checkAllVisibleRows"
              @click.stop="onCheckAllVisibleRows"
            />
          </label>
        </th>
        <th
          v-for="column in $columns"
          :key="column.key"
          :class="thClass(column)"
          :style="thStyle(column)"
          :data-testid="`th-${column.key}`"
          @click="clickSort(column)"
        >
          <span class="icon-text">
            <span>{{ column.text }}</span>
            <span v-if="sort && sortState.key === column.key && column?.options?.sort" class="icon">
              <iconify-icon v-show="sortState.asc" icon="mdi:arrow-down"></iconify-icon>
              <iconify-icon v-show="!sortState.asc" icon="mdi:arrow-up"></iconify-icon>
            </span>
          </span>
        </th>
      </thead>

      <tbody v-if="loading">
        <tr>
          <td :colspan="$columnsLength" class="has-text-centered py-4">
            <baf-loader></baf-loader>
          </td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr v-if="search">
          <td v-if="checkbox"></td>
          <td
            v-for="column in $columns"
            :key="column.key"
            :data-testid="`td-search-${column.key}`"
            :class="tdClass(column)"
          >
            <div v-if="column.options.search" class="field m-0">
              <div class="control">
                <div
                  v-if="
                    column.options.select &&
                    (column.options.selectOptions || $uniqueRowValuesPerColumn[column.key])
                  "
                  class="select is-small is-fullwidth"
                  :class="tdClass(column)"
                >
                  <select
                    :id="`search-input-${column.key}`"
                    v-model.trim="searchState.queries[column.key]"
                    :data-testid="`search-input-${column.key}`"
                    :placeholder="`${$t('general.search')} ${column.text}`"
                    @change="inputSearch"
                  >
                    <option :value="undefined">-</option>
                    <template v-if="column.options.selectOptions">
                      <option
                        v-for="(option, optionIndex) in column.options.selectOptions"
                        :key="optionIndex"
                        :value="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </template>
                    <template v-else-if="$uniqueRowValuesPerColumn[column.key]">
                      <option
                        v-for="(rowValue, rowValueIndex) in $uniqueRowValuesPerColumn[column.key]"
                        :key="rowValueIndex"
                        :value="rowValue"
                      >
                        {{ rowValue }}
                      </option>
                    </template>
                  </select>
                </div>

                <input
                  v-else
                  :id="`search-input-${column.key}`"
                  v-model.trim="searchState.queries[column.key]"
                  :data-testid="`search-input-${column.key}`"
                  :placeholder="`${$t('general.search')} ${column.text}`"
                  type="search"
                  class="input is-small"
                  :class="tdClass(column)"
                  @input="inputSearch"
                />
              </div>
            </div>
          </td>
        </tr>

        <tr
          v-for="(row, rowIndex) in $rowsPaginatedOrRows"
          :key="String(row.$raw.id)"
          ref="tableRows"
          :data-testid="`tr-${rowIndex}`"
          :class="trClass(row.$raw)"
          @click="onClickRow(row.$raw, rowIndex)"
        >
          <td v-if="checkbox">
            <label class="checkbox">
              <input
                type="checkbox"
                data-testid="checkRow"
                :checked="rowChecked(row.$raw)"
                @click.stop="onCheckRow(row.$raw)"
              />
            </label>
          </td>
          <td
            v-for="(column, columnIndex) in columns"
            :key="column.key"
            :data-testid="`td-${column.key}`"
            :class="tdClass(column)"
          >
            <slot
              :name="`column-${column.key}`"
              v-bind="{
                row: row.$raw,
                rowIndex,
                column,
                columnIndex,
                value: row[column.key].value,
                rendered: row[column.key].rendered,
              }"
            >
              {{ row[column.key].rendered || '-' }}
            </slot>
          </td>
        </tr>

        <tr v-if="$empty">
          <td :colspan="$columnsLength" class="has-text-centered py-4">
            {{ $t('general.noDataFound') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
@import 'bulma/sass/utilities/derived-variables';
@import '@/assets/scss/overrides';

.table-pagination {
  .is-bordered {
    outline: solid 1px $border;
    outline-offset: -1px;
  }

  .has-radius-left {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

.table-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  &.has-actions {
    max-height: v-bind(maxActionsHeight);
    overflow-y: auto;
  }

  .table {
    table-layout: auto;

    &.has-actions {
      width: auto;
    }
  }
}

.table-actions {
  width: min-content;
  top: 0;
  position: sticky;
}
</style>
