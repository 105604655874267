import { appHttpService } from '@/services/standard/http-service';
import { models, queries } from 'baf-shared';
import { isFailedToFetchErrorOrThrow } from '../standard/failed-to-fetch-service';

class PressureTestAssetService {
  async getAll(query?: queries.GetAssetsQuery): Promise<models.PressureTestAsset[]> {
    try {
      const assets = await appHttpService.request
        .url('/pressure-test-assets')
        .query({
          ...query,
        })
        .get()
        .json<models.PressureTestAsset[]>();
      return assets;
    } catch (error) {
      isFailedToFetchErrorOrThrow(error);
      return [];
    }
  }

  async create(upcreateAsset: models.UpcreatePressureTestAsset): Promise<models.PressureTestAsset> {
    const asset = await appHttpService.request
      .url(`/pressure-test-assets`)
      .post(upcreateAsset)
      .json<models.PressureTestAsset>();
    return asset;
  }

  async update(
    id: string,
    upcreateAsset: models.UpcreatePressureTestAsset,
  ): Promise<models.PressureTestAsset> {
    const asset = await appHttpService.request
      .url(`/pressure-test-assets/${id}`)
      .put(upcreateAsset)
      .json<models.PressureTestAsset>();
    return asset;
  }

  async delete(id: string): Promise<models.PressureTestAsset> {
    const asset = await appHttpService.request
      .url(`/pressure-test-assets/${id}`)
      .delete()
      .json<models.PressureTestAsset>();
    return asset;
  }
}

export const pressureTestAssetService = new PressureTestAssetService();
