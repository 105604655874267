import { authService } from '../auth/auth-service';
import { appHttpService } from '../standard/http-service';

class SeedService {
  run() {
    if (!authService.authenticated) {
      return;
    }

    return appHttpService.request.url('/seed').post().json<unknown>();
  }
}

export const seedService = new SeedService();
