<script setup lang="ts">
import BafField from '@/components/standard/form/baf-field.vue';
import BafSelectField from '@/components/standard/form/baf-select-field.vue';
import BafTextField from '@/components/standard/form/baf-text-field.vue';
import BafTextareaField from '@/components/standard/form/baf-textarea-field.vue';
import BafLoader from '@/components/standard/loader/baf-loader.vue';
import BafSearchSelect from '@/components/standard/search-select/baf-search-select.vue';
import TitleValueRoot from '@/components/standard/title-value/title-value-root.vue';
import TitleValue from '@/components/standard/title-value/title-value.vue';
import { useLoading } from '@/composeables/use-loading';
import { categoryValueService } from '@/services/category-value/category-value-service';
import { customerService } from '@/services/customer/customer-service';
import { employeeService } from '@/services/employee/employee-service';
import { pressureTestAssetService } from '@/services/pressure-test-asset/pressure-test-asset-service';
import { pressureTestService } from '@/services/pressure-test/pressure-test-service';
import { models, utils } from 'baf-shared';
import { computed, nextTick, onBeforeMount, ref, toRefs, watch } from 'vue';
import { useAuth } from '@/composeables/use-auth';
import PressureTestSearch from '@/components/pressure-test/pressure-test-search.vue';

const CERTIFICATIONS = ['EU', 'SWEDAC', 'AFS 2019:1', 'EU 18119'];
const certifications = computed(() => CERTIFICATIONS);

type FormData = Omit<
  models.UpcreatePressureTest,
  'customerId' | 'assetId' | 'servicedByEmployeeId' | 'filledByEmployeeId' | 'actions'
> & {
  asset: models.PressureTestAsset;
  customer: models.Customer;
  servicedBy: models.Employee;
  filledBy: models.Employee;
  actions: models.CategoryValue[];
};

function createFormData(pressureTest?: models.PressureTest): FormData {
  const _actions: models.CategoryValue[] = pressureTest?.actions
    ? actions.value.filter((a) => pressureTest.actions.includes(a.value))
    : [];

  const currentlyLoggedInEmployee = employees.value.find(
    (employee) => employee.id === me.value?.employeeId,
  );

  return {
    customer: pressureTest?.customer ?? utils.type.asType<models.Customer>(null),
    asset: pressureTest?.asset ?? utils.type.asType<models.PressureTestAsset>(null),
    filledBy:
      pressureTest?.filledBy ?? utils.type.asType<models.Employee>(currentlyLoggedInEmployee),
    servicedBy:
      pressureTest?.servicedBy ?? utils.type.asType<models.Employee>(currentlyLoggedInEmployee),
    sequentialNumberId: sequentialNumberId.value,

    actions: _actions,
    manufacturer: pressureTest?.manufacturer,
    serialNumber: pressureTest?.serialNumber,
    manufacturingDate: utils.date.formatDateTimeLocal(pressureTest?.manufacturingDate) ?? undefined,
    gas: pressureTest?.gas,
    diameter: pressureTest?.diameter,
    volume: pressureTest?.volume,
    emptyWeight: pressureTest?.emptyWeight,
    filledWeight: pressureTest?.filledWeight,
    filledPressure: pressureTest?.filledPressure,
    testedPressure: pressureTest?.testedPressure,
    certifications: pressureTest?.certifications ?? [],

    receiptNumber: pressureTest?.receiptNumber,
    information: pressureTest?.information,
    note: pressureTest?.note,

    lastService: utils.date.formatDateTimeLocal(pressureTest?.lastService) ?? undefined,
    nextService: utils.date.formatDateTimeLocal(pressureTest?.nextService) ?? undefined,
    validFrom:
      utils.date.formatDateTimeLocal(pressureTest?.validFrom) ??
      utils.date.formatDateTimeLocal(new Date()),
  };
}

const { me } = useAuth();
const { loading, startLoading, stopLoading } = useLoading();
const {
  loadingClass: submitLoadingClass,
  startLoading: startSubmitLoading,
  stopLoading: stopSubmitLoading,
} = useLoading();

const emit = defineEmits<{
  (e: 'upcreate'): void;
  (e: 'cancel'): void;
}>();

const props = withDefaults(
  defineProps<{
    sequentialNumberId: string;
    pressureTest?: models.PressureTest;
  }>(),
  {
    pressureTest: undefined,
  },
);
const { sequentialNumberId, pressureTest } = toRefs(props);

const formData = ref<FormData>(utils.type.asType<FormData>(null));
const customers = ref<models.Customer[]>([]);
const employees = ref<models.Employee[]>([]);
const assets = ref<models.PressureTestAsset[]>([]);
const gases = ref<string[]>([]);
const manufacturers = ref<string[]>([]);
const actions = ref<models.CategoryValue[]>([]);
const isUpdating = computed(() => !!pressureTest.value);
watch(pressureTest, () => {
  formData.value = createFormData(pressureTest.value);
});

function updateNextServiceToTodayPlusNumberOfYears(numberOfYears: number) {
  const newDate = utils.date.changeDate({
    date: new Date(),
    delta: numberOfYears,
    unit: 'years',
  });

  formData.value.nextService = utils.date.formatDateTimeLocal(newDate);
}

onBeforeMount(async () => {
  try {
    startLoading();
    customers.value = await customerService.getAll();
    assets.value = await pressureTestAssetService.getAll();
    employees.value = utils.type.asType<models.Employee[]>(
      await employeeService.getAll({ access: models.Access.PressureTests }),
    );
    gases.value = await categoryValueService
      .getAll({
        category: models.CategoryValueCategory.Gas,
      })
      .then((data) => data.map((v) => v.value).sort());
    manufacturers.value = await categoryValueService
      .getAll({
        category: models.CategoryValueCategory.Manufacturer,
      })
      .then((data) => data.map((v) => v.value).sort());
    actions.value = await categoryValueService.getAll({
      category: models.CategoryValueCategory.PressureTestAction,
    });
    formData.value = createFormData();
  } finally {
    stopLoading();
  }
});

function onSearchClick(value: models.PressureTest) {
  formData.value = createFormData({
    ...value,
    actions: [],
    validFrom: new Date(),
    nextService: undefined,
  });
}

async function onUpdateCustomer() {
  assets.value = await pressureTestAssetService.getAll({
    customerId: formData.value.customer?.id,
  });
  await nextTick();
  formData.value.asset = utils.type.asType<models.PressureTestAsset>(null);
}

async function onUpdateAsset() {
  if (formData.value.asset) {
    formData.value.customer = formData.value.asset.customer;
    assets.value = await pressureTestAssetService.getAll({
      customerId: formData.value.customer?.id,
    });
  }
}

async function onUpdateCertification(certification: string) {
  const indexOfCertification = formData.value.certifications!.indexOf(certification);
  if (indexOfCertification === -1) {
    formData.value.certifications?.push(certification);
  } else {
    formData.value.certifications?.splice(indexOfCertification, 1);
  }
}

async function onCancel() {
  formData.value = createFormData();
  emit('cancel');
}

function upcreatePressureTest(): Promise<models.PressureTest> {
  const upcreatePressureTest: models.UpcreatePressureTest = {
    customerId: formData.value.customer.id,
    assetId: formData.value.asset.id,
    sequentialNumberId: formData.value.sequentialNumberId,
    servicedByEmployeeId: formData.value?.servicedBy?.id,
    filledByEmployeeId: formData.value?.filledBy?.id,

    actions: formData.value.actions.map((a) => a.value),

    manufacturer: formData.value.manufacturer,
    serialNumber: formData.value.serialNumber,
    manufacturingDate: utils.date.formatDateTimeISO(formData.value.manufacturingDate) ?? undefined,
    gas: formData.value.gas,
    volume: formData.value.volume,
    diameter: formData.value.diameter,
    emptyWeight: formData.value.emptyWeight,
    filledWeight: formData.value.filledWeight,
    filledPressure: formData.value.filledPressure,
    testedPressure: formData.value.testedPressure,
    certifications: formData.value.certifications,

    receiptNumber: formData.value.receiptNumber,
    information: formData.value.information,
    note: formData.value.note,

    lastService: utils.date.formatDateTimeISO(formData.value.lastService) ?? undefined,
    nextService: utils.date.formatDateTimeISO(formData.value.nextService) ?? undefined,
    validFrom: utils.date.formatDateTimeISO(formData.value.validFrom) ?? undefined,
  };

  if (isUpdating.value) {
    return pressureTestService.update(pressureTest.value!.id, upcreatePressureTest);
  } else {
    return pressureTestService.create(upcreatePressureTest);
  }
}

async function onSubmit() {
  try {
    startSubmitLoading();
    await upcreatePressureTest();
    formData.value = createFormData();
    emit('upcreate');
  } finally {
    stopSubmitLoading();
  }
}
</script>

<template>
  <baf-loader v-if="loading"></baf-loader>

  <template v-else>
    <div class="mt-4 py-4">
      <p class="title is-3 p-0">
        {{
          isUpdating
            ? `${$t('general.pressureTestUpdate')} ${$t('general.pressureTestWithSerialNumber', { serialNumber: formData.serialNumber })}`
            : $t('general.pressureTestCreate')
        }}
      </p>
    </div>

    <div class="columns">
      <div class="column">
        <pressure-test-search @click-row="onSearchClick"></pressure-test-search>
      </div>
    </div>

    <form ref="form" @submit.prevent="onSubmit">
      <div class="columns">
        <div class="column">
          <div class="box pb-5">
            <p class="title is-5">{{ $t('general.customer') }}</p>

            <div class="columns">
              <div class="column">
                <baf-field>
                  <label class="label" for="pressureTestCustomer">
                    {{ $t('general.customer') }}</label
                  >
                  <div class="control pb-3">
                    <baf-search-select
                      id="pressureTestCustomer"
                      v-model="formData.customer"
                      required
                      data-testid="pressureTestCustomerSelect"
                      :values="customers"
                      value-key="id"
                      text-key="name"
                      @update:model-value="onUpdateCustomer"
                    ></baf-search-select>
                  </div>
                </baf-field>

                <baf-field>
                  <label class="label" for="pressureTestObject"> {{ $t('general.object') }}</label>
                  <div class="control">
                    <baf-search-select
                      id="pressureTestObject"
                      v-model="formData.asset"
                      required
                      data-testid="pressureTestObjectSelect"
                      :values="assets"
                      value-key="id"
                      text-key="name"
                      @update:model-value="onUpdateAsset"
                    ></baf-search-select>
                  </div>
                </baf-field>

                <title-value-root fullwidth>
                  <title-value
                    :title="$t('general.name')"
                    :value="formData.asset?.name"
                  ></title-value>
                  <title-value
                    :title="$t('general.contactName')"
                    :value="formData.asset?.contactName"
                  ></title-value>
                  <title-value
                    :title="$t('general.email')"
                    :value="formData.asset?.contactEmail"
                  ></title-value>
                  <title-value
                    :title="$t('general.phoneNumber')"
                    :value="formData.asset?.contactPhone"
                  ></title-value>

                  <title-value
                    :title="$t('general.deliveryAddress')"
                    :value="formData.asset?.deliveryAddress"
                  ></title-value>
                  <title-value
                    :title="$t('general.deliveryPostCode')"
                    :value="
                      utils.joinValues(
                        [
                          formData.asset?.deliveryPostCode,
                          formData.asset?.deliveryCity,
                          formData.asset?.deliveryCountry,
                        ],
                        ', ',
                      )
                    "
                  ></title-value>
                </title-value-root>
              </div>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="box">
            <p class="title is-5">{{ $t('general.article') }}</p>

            <div class="columns">
              <div class="column">
                <baf-select-field
                  id="pressureTestManufacturer"
                  v-model="formData.manufacturer"
                  :label="$t('general.manufacturer')"
                  required
                >
                  <option :value="undefined" disabled>-</option>
                  <option
                    v-for="(manufacturer, index) in manufacturers"
                    :key="index"
                    :value="manufacturer"
                  >
                    {{ manufacturer }}
                  </option>
                </baf-select-field>
              </div>
              <div class="column">
                <baf-select-field
                  id="pressureTestGas"
                  v-model="formData.gas"
                  :label="$t('general.gas')"
                  required
                >
                  <option :value="undefined" disabled>-</option>
                  <option v-for="(gas, index) in gases" :key="index" :value="gas">
                    {{ gas }}
                  </option>
                </baf-select-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <baf-text-field
                  id="pressureTestSerialNumber"
                  v-model="formData.serialNumber"
                  :label="$t('general.serialNumber')"
                  required
                ></baf-text-field>
              </div>
              <div class="column">
                <baf-text-field
                  id="pressureTestManufacturingDate"
                  v-model="formData.manufacturingDate"
                  :label="$t('general.manufacturingDate')"
                  type="datetime-local"
                ></baf-text-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <baf-text-field
                  id="pressureTestVolume"
                  v-model="formData.volume"
                  :label="$t('general.volumeLiter')"
                ></baf-text-field>
              </div>
              <div class="column">
                <baf-text-field
                  id="pressureTestDiameter"
                  v-model="formData.diameter"
                  :label="$t('general.diameterMm')"
                ></baf-text-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <baf-text-field
                  id="pressureTestEmptyWeight"
                  v-model="formData.emptyWeight"
                  :label="$t('general.emptyWeight')"
                ></baf-text-field>
              </div>
              <div class="column">
                <baf-text-field
                  id="pressureTestFilledWeight"
                  v-model="formData.filledWeight"
                  :label="$t('general.filledWeight')"
                ></baf-text-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <baf-text-field
                  id="pressureTestFilledPressure"
                  v-model="formData.filledPressure"
                  :label="$t('general.filledPressure')"
                ></baf-text-field>
              </div>
              <div class="column">
                <baf-text-field
                  id="pressureTestPressureTest"
                  v-model="formData.testedPressure"
                  :label="$t('general.pressureTest')"
                ></baf-text-field>
              </div>
            </div>

            <baf-field>
              <div class="checkbox" style="padding-bottom: 6px">
                <template v-for="(checkbox, index) in certifications" :key="checkbox">
                  <label class="checkbox" :class="{ 'ml-4': index !== 0 }">
                    <input
                      type="checkbox"
                      :value="checkbox"
                      :checked="formData.certifications?.includes(checkbox)"
                      @input="onUpdateCertification(checkbox)"
                    />
                    {{ checkbox }}
                  </label>
                </template>
              </div>
            </baf-field>
          </div>
        </div>

        <div class="column">
          <div class="box">
            <p class="title is-5">{{ $t('general.service') }}</p>

            <div class="columns">
              <div class="column">
                <baf-field>
                  <label class="label" for="pressureTestFilledBy">{{
                    $t('general.filledBy')
                  }}</label>
                  <div class="control">
                    <baf-search-select
                      id="pressureTestFilledBy"
                      v-model="formData.filledBy"
                      data-testid="pressureTestFilledBySelect"
                      :values="employees"
                      text-key="fullName"
                      value-key="id"
                      required
                    ></baf-search-select>
                  </div>
                </baf-field>
              </div>
              <div class="column">
                <baf-field>
                  <label class="label" for="pressureTestServicedBy">{{
                    $t('general.servicedBy')
                  }}</label>
                  <div class="control">
                    <baf-search-select
                      id="pressureTestServicedBy"
                      v-model="formData.servicedBy"
                      data-testid="pressureTestServicedBySelect"
                      :values="employees"
                      text-key="fullName"
                      value-key="id"
                      required
                    ></baf-search-select>
                  </div>
                </baf-field>
              </div>
            </div>

            <baf-field>
              <label class="label" for="pressureTestActions">{{ $t('general.actions') }}</label>
              <div class="control">
                <baf-search-select
                  id="pressureTestActions"
                  v-model="formData.actions"
                  data-testid="pressureTestActionsSelect"
                  :values="actions"
                  text-key="value"
                  value-key="id"
                ></baf-search-select>
              </div>
            </baf-field>

            <div class="columns">
              <div class="column">
                <baf-text-field
                  id="pressureTestDate"
                  v-model="formData.validFrom"
                  :label="$t('general.date')"
                  type="datetime-local"
                ></baf-text-field>
              </div>
              <div class="column">
                <baf-field>
                  <label class="label" for="pressureTestNextService">{{
                    $t('general.nextService')
                  }}</label>
                  <baf-field addons>
                    <div class="control is-fullwidth">
                      <input
                        id="pressureTestNextService"
                        v-model="formData.nextService"
                        data-testid="serviceOrderRowNextServiceInput"
                        type="datetime-local"
                        class="input"
                      />
                    </div>

                    <div class="control">
                      <button
                        type="button"
                        class="button is-link"
                        data-testid="nextServiceQuickChoiceFiveYearsButton"
                        @click="updateNextServiceToTodayPlusNumberOfYears(5)"
                      >
                        <span class="ml-2">{{ $t('general.fiveYears') }}</span>
                      </button>
                    </div>

                    <div class="control">
                      <button
                        type="button"
                        class="button is-link"
                        data-testid="nextServiceQuickChoiceTenYearsButton"
                        @click="updateNextServiceToTodayPlusNumberOfYears(10)"
                      >
                        <span class="ml-2">{{ $t('general.tenYears') }}</span>
                      </button>
                    </div>
                  </baf-field>
                </baf-field>
              </div>
            </div>

            <baf-text-field
              id="pressureTestReceiptNumber"
              v-model="formData.receiptNumber"
              :label="$t('general.receiptNumber')"
            ></baf-text-field>

            <div class="columns">
              <div class="column">
                <baf-textarea-field
                  id="pressureTestCustomerInformation"
                  v-model="formData.information"
                  :label="$t('general.customerInformation')"
                  rows="4"
                ></baf-textarea-field>
              </div>
              <div class="column">
                <baf-textarea-field
                  id="pressureTestNote"
                  v-model="formData.note"
                  :label="$t('general.internalNote')"
                  rows="4"
                ></baf-textarea-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="buttons is-right">
        <button
          type="button"
          class="button"
          data-testid="cancelButton"
          :class="submitLoadingClass"
          @click="onCancel"
        >
          {{ $t('general.reset') }}
        </button>
        <button
          type="submit"
          class="button is-primary"
          data-testid="upcreateButton"
          :class="submitLoadingClass"
        >
          {{ isUpdating ? $t('general.update') : $t('general.create') }}
        </button>
      </div>
    </form>
  </template>
</template>
