export var ErrorInformationCode;
(function (ErrorInformationCode) {
    ErrorInformationCode[ErrorInformationCode["SystemException"] = 1000003] = "SystemException";
    ErrorInformationCode[ErrorInformationCode["InvalidResponseType"] = 1000030] = "InvalidResponseType";
    ErrorInformationCode[ErrorInformationCode["InvalidContentType"] = 1000031] = "InvalidContentType";
    ErrorInformationCode[ErrorInformationCode["AlphanumericValueRequired"] = 2000106] = "AlphanumericValueRequired";
    ErrorInformationCode[ErrorInformationCode["NumericValueRequired"] = 2000108] = "NumericValueRequired";
    ErrorInformationCode[ErrorInformationCode["BooleanValueRequired"] = 2000134] = "BooleanValueRequired";
    ErrorInformationCode[ErrorInformationCode["InvalidLogin"] = 2000310] = "InvalidLogin";
    ErrorInformationCode[ErrorInformationCode["LoginMissing"] = 2000311] = "LoginMissing";
    ErrorInformationCode[ErrorInformationCode["InvalidCharacters"] = 2000359] = "InvalidCharacters";
    ErrorInformationCode[ErrorInformationCode["InvalidParameter"] = 2000588] = "InvalidParameter";
    ErrorInformationCode[ErrorInformationCode["CustomerNumberAlreadyUsed"] = 2000637] = "CustomerNumberAlreadyUsed";
    ErrorInformationCode[ErrorInformationCode["ValidIdentifierNotProvided"] = 2000729] = "ValidIdentifierNotProvided";
    ErrorInformationCode[ErrorInformationCode["MissingApiLicense"] = 2001103] = "MissingApiLicense";
    ErrorInformationCode[ErrorInformationCode["EmptyOrIncorrectData"] = 2001392] = "EmptyOrIncorrectData";
    ErrorInformationCode[ErrorInformationCode["DocumentReadingFailed"] = 2001740] = "DocumentReadingFailed";
    ErrorInformationCode[ErrorInformationCode["JsonDeserializationError"] = 2002115] = "JsonDeserializationError";
    ErrorInformationCode[ErrorInformationCode["AccountNotFound"] = 2001304] = "AccountNotFound";
    ErrorInformationCode[ErrorInformationCode["InvalidFieldName"] = 2001399] = "InvalidFieldName";
    ErrorInformationCode[ErrorInformationCode["NoActiveLicenseForScope"] = 2001101] = "NoActiveLicenseForScope";
    ErrorInformationCode[ErrorInformationCode["NoScopeAccess"] = 2000663] = "NoScopeAccess";
    ErrorInformationCode[ErrorInformationCode["MissingAccountForPurchaseSE"] = 2003095] = "MissingAccountForPurchaseSE";
    ErrorInformationCode[ErrorInformationCode["SupplierInvoiceNotBalanced"] = 2000755] = "SupplierInvoiceNotBalanced";
    ErrorInformationCode[ErrorInformationCode["VatTypeReverseMustHaveCode"] = 2003115] = "VatTypeReverseMustHaveCode";
    ErrorInformationCode[ErrorInformationCode["OnlyDeliveredOrdersCanBeMarked"] = 2003124] = "OnlyDeliveredOrdersCanBeMarked";
    ErrorInformationCode[ErrorInformationCode["CannotModifyMarkedDocument"] = 2003125] = "CannotModifyMarkedDocument";
    ErrorInformationCode[ErrorInformationCode["DeliveredDateCannotBeLater"] = 2003126] = "DeliveredDateCannotBeLater";
    ErrorInformationCode[ErrorInformationCode["MigrationAlreadyStartedOrFinished"] = 2003241] = "MigrationAlreadyStartedOrFinished";
    ErrorInformationCode[ErrorInformationCode["NotAuthorized"] = 2003275] = "NotAuthorized";
    ErrorInformationCode[ErrorInformationCode["NotFoundInWarehouseModule"] = 2003277] = "NotFoundInWarehouseModule";
    ErrorInformationCode[ErrorInformationCode["DocumentVoidedInWarehouseModule"] = 2003399] = "DocumentVoidedInWarehouseModule";
    ErrorInformationCode[ErrorInformationCode["WarehouseModuleError"] = 2003127] = "WarehouseModuleError";
    ErrorInformationCode[ErrorInformationCode["CustomerNotFound"] = 2000204] = "CustomerNotFound";
    ErrorInformationCode[ErrorInformationCode["CustomerNotFoundAlt"] = 2000433] = "CustomerNotFoundAlt";
    ErrorInformationCode[ErrorInformationCode["ArticleNotFound"] = 2001302] = "ArticleNotFound";
    ErrorInformationCode[ErrorInformationCode["ArticleNotFoundAlt"] = 2000428] = "ArticleNotFoundAlt";
    ErrorInformationCode[ErrorInformationCode["LabelNotFound"] = 2003084] = "LabelNotFound";
})(ErrorInformationCode || (ErrorInformationCode = {}));
