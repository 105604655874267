/// <reference types="vite-plugin-pwa/client" />
import { useRegisterSW } from 'virtual:pwa-register/vue';
import { watch } from 'vue';
import { i18n } from './i18n/i18n';
import { notificationService } from './services/notification/notification-service';

const REFRESH_INTERVAL_IN_MS = 60_000 * 60;

const { needRefresh, updateServiceWorker } = useRegisterSW({
  onOfflineReady() {
    notificationService.open({
      text: i18n.global.t('general.offlineReady'),
      type: 'link',
      timeout: 10_000,
    });
  },

  onRegisteredSW(_url, registeration) {
    if (registeration) {
      setInterval(() => {
        registeration.update();
      }, REFRESH_INTERVAL_IN_MS);
    }
  },
});

watch(needRefresh, (_needRefresh) => {
  if (_needRefresh) {
    updateServiceWorker();
  }
});
