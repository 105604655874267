<script setup lang="ts">
import LabelTable from '@/components/label/label-table.vue';
import LabelUpcreateModal from '@/components/label/label-upcreate-modal.vue';
import { useAuth } from '@/composeables/use-auth';
import { ref } from 'vue';

const labelTable = ref<InstanceType<typeof LabelTable>>();

const { isAdmin } = useAuth();
</script>

<template>
  <section class="section">
    <div v-if="isAdmin" class="buttons is-right">
      <label-upcreate-modal v-if="isAdmin" #="{ open }" @upcreate="labelTable?.loadLabels">
        <button class="button is-success" data-testid="createButton" @click.stop="open">
          <iconify-icon icon="mdi:add-circle"></iconify-icon>
          <span class="ml-2">{{ $t('general.labelCreate') }}</span>
        </button>
      </label-upcreate-modal>
    </div>
  </section>

  <section class="section">
    <div class="box">
      <label-table ref="labelTable"></label-table>
    </div>
  </section>
</template>
