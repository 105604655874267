/* prettier-ignore */
export const en = {
  general: {
    address: 'Address',
    alarmedArea: 'Alarmed area',
    asset: 'Asset',
    bottleManufacturerShort: 'Manufacturer',
    central: 'Central',
    certification: 'Certificate',
    certificationCity: 'City of signature',
    certificationService: 'Service documentation',
    certified: 'Hereby certified that',
    checkpoints: 'Checkpoints',
    contact: 'Contact',
    control: 'Control',
    customer: 'Customer',
    customerInformation: 'Customer information',
    customerNumber: 'Customer number',
    customerNumberShort: 'Cnr',
    date: 'Date',
    deliveryInformation: 'Delivery information',
    diameter: 'Diameter',
    disapprovedServiceOrderRows: 'Non-approved service-order rows',
    emptyFilledWeight: 'Empty/Filled weight kg',
    emptyWeight: 'Empty weight kg',
    expirationDate: 'Expiration date light',
    filledPressure: 'Filled pressure',
    filledWeight: 'Filled weight kg',
    inspectionCertificate: 'Inspection certificate',
    inspectionCertificateText: 'Inspection carried out according to AFS 2019:1',
    inspector: 'Inspector',
    legalCompanyName: 'Brandab Skydd & Säkerhet AB',
    liquidSample: 'Liquid sample',
    manufacturer: 'Manufacturer',
    manufacturingDate: 'Manufacturing date',
    manufacturingDateShort: 'Manufacturing date',
    manufacturingNumberShort: 'Manufacturing nr',
    name: 'Name',
    nextService: 'Next service',
    phoneNumber: 'Phone number',
    placement: 'Placement',
    pressureTest: 'Pressure test',
    pressureTestShort: 'VGG',
    pressureTestShortAlternative: 'PT',
    receiptNumberShort: 'Receipt nr',
    remark: 'Remark',
    reoccurringControl: 'Reoccurring control of gas cylinders',
    reoccurringControlNumber: 'Print number: {number}',
    reoccurringControlText: 'Inspection carried out according to AFS 2017:3',
    rowNumberShort: 'Nr',
    serialNumber: 'Serial number',
    serviceObject: 'Service object',
    serviceOrder: 'Service order',
    signature: 'Signature',
    size: 'Size',
    typeOfControl: 'Type of control',
    volumeLiter: 'Volume liter',
    enums: {
      certificationType: {
        FireExtinguisher: 'Fire extinguisher',
        ExtinguishingSystem: 'Extinguishing system',
        FireAlarm: 'Fire alarm',
        LifeVest: 'Life vest',
        Suit: 'Suit',
      },
    },
  },
  components: {
    printPage: {
      companyAddress: '2 Skyttevägen, Karlskrona, Blekinge, 371 41, Sweden',
      companyName: 'Brandab Skydd & Säkerhet AB',
      logoAlt: 'Brandab Skydd & Säkerhet AB',
      logoTitle: 'Brandab',
    },
    printCertification: {
      body: 'Has been inspected and found to be in good condition.',
    },
  },
};
