<template>
  <div class="loader-container">
    <div class="loader"></div>
    <div>
      <strong>{{ $t('general.loading') }}</strong>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/overrides';

$loader-main: $info;
$loader-accent: $primary;

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
}

.loader {
  margin: 3em;
  border: none;
  width: 1.5em;
  height: 1.5em;
  aspect-ratio: 1;
  border-radius: 50%;
  background: $primary;
  box-shadow: 0 0 0 0 $primary;
  animation: pulse 1.5s infinite;
}
@keyframes pulse {
  100% {
    box-shadow: 0 0 0 30px #0000;
  }
}
</style>
