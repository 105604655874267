import wretch from 'wretch';
import queryStringAddon from 'wretch/addons/queryString';
import { dedupe } from 'wretch/middlewares/dedupe';
export class HttpService {
    request;
    constructor(baseUrl, wretchOptions = {}) {
        const options = Object.assign({ headers: {} }, wretchOptions);
        this.request = wretch(baseUrl, options).addon(queryStringAddon).middlewares([dedupe()]);
        return this;
    }
    middlewares(...middlewares) {
        this.request = this.request.middlewares(middlewares);
        return this;
    }
    headers(headers) {
        this.request = this.request.headers(headers);
        return this;
    }
    catcher(errorId, callback) {
        this.request = this.request.catcher(errorId, callback);
        return this;
    }
    catcherFallback(callback) {
        this.request = this.request.catcherFallback(callback);
        return this;
    }
}
