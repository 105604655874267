import { asType } from './type-utils.js';
export function parseOrDefault(value, defaultValue) {
    if (!value) {
        return defaultValue;
    }
    try {
        return JSON.parse(value ?? '');
    }
    catch (error) {
        return defaultValue;
    }
}
export function strinify(value) {
    return JSON.stringify(value, undefined, 2);
}
export function copy(value) {
    return asType(JSON.parse(JSON.stringify(value)));
}
export function merge(data, override) {
    return asType({
        ...data,
        ...override,
    });
}
