import { LocalStorageService } from '../storage/local-storage-service';
import type { DateString } from 'baf-shared';

interface SettingsState {
  lastSync?: DateString;
  lastVisitedServiceOrderId?: string;
}

const createSettingsState = (): SettingsState => ({
  lastSync: undefined,
  lastVisitedServiceOrderId: undefined,
});

class SettingsService extends LocalStorageService<SettingsState> {
  constructor() {
    super('settings', createSettingsState);
  }

  getSettings(key: keyof SettingsState) {
    return this.getState()[key];
  }

  setSettings(settings: SettingsState): void {
    this.setState(settings);
  }
}

export const settingsService = new SettingsService();
