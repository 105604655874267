import { type models, queries } from 'baf-shared';
import { appHttpService } from '../standard/http-service';

class PressureTestService {
  async getAll(query?: queries.GetPressureTestQuery): Promise<models.PressureTest[]> {
    const pressureTests = await appHttpService.request
      .url('/pressure-tests')
      .query({
        ...query,
      })
      .get()
      .json<models.PressureTest[]>();
    return pressureTests;
  }

  async search(query?: queries.SearchPressureTestQuery): Promise<models.PressureTest[]> {
    const pressureTests = await appHttpService.request
      .url('/pressure-tests/search')
      .query({
        ...query,
      })
      .get()
      .json<models.PressureTest[]>();
    return pressureTests;
  }

  async create(data: models.UpcreatePressureTest): Promise<models.PressureTest> {
    const pressureTest = await appHttpService.request
      .url('/pressure-tests')
      .post(data)
      .json<models.PressureTest>();
    return pressureTest;
  }

  async update(id: string, data: models.UpcreatePressureTest) {
    const pressureTest = await appHttpService.request
      .url(`/pressure-tests/${id}`)
      .put(data)
      .json<models.PressureTest>();
    return pressureTest;
  }

  async delete(id: string): Promise<models.PressureTest> {
    const asset = await appHttpService.request
      .url(`/pressure-tests/${id}`)
      .delete()
      .json<models.PressureTest>();
    return asset;
  }
}

export const pressureTestService = new PressureTestService();
