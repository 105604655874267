<script setup lang="ts">
import TheNotifications from '@/components/app/notification/the-notifications.vue';
import TheOfflinebar from '@/components/app/offline/the-offlinebar.vue';
import TheNavbar from '@/components/app/the-navbar.vue';
import { computed } from 'vue';
import { appService } from './services/app/app-service';

const rootAppKey = computed(() => appService.rootAppKey.value);
</script>

<template>
  <the-offlinebar></the-offlinebar>
  <the-navbar></the-navbar>
  <the-notifications></the-notifications>

  <main :key="rootAppKey" class="container is-fluid p-0">
    <router-view></router-view>
  </main>
</template>
