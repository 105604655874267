<script setup lang="ts">
import { RouteName } from '@/router/models/route-name';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

defineOptions({
  inheritAttrs: false,
});

const { t } = useI18n();

const routes = computed(() => [
  {
    name: RouteName.PressureTestsStart,
    text: t('general.routeName.pressureTests'),
  },
  {
    name: RouteName.PressureTestsAssets,
    text: t('general.routeName.assets'),
  },
  {
    name: RouteName.PressureTestsCategoryValues,
    text: t('general.routeName.categoryValues'),
  },
]);
</script>

<template>
  <div class="tabs is-boxed is-fullwidth">
    <ul>
      <router-link
        v-for="route in routes"
        v-slot="{ href, isExactActive, navigate }"
        :key="route.name"
        :to="{ name: route.name }"
        custom
      >
        <li :class="{ 'is-active': isExactActive }">
          <a :href="href" @click="navigate">{{ route.text }}</a>
        </li>
      </router-link>
    </ul>
  </div>

  <router-view v-slot="{ Component }">
    <div class="px-4">
      <component :is="Component" v-bind="$attrs" />
    </div>
  </router-view>
</template>
