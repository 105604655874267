import { config } from '@/config';
import { i18n } from '@/i18n/i18n';
import { logger } from '@/utils/logger-utils';
import { sortBy, utils } from 'baf-shared';
import type { Wretch, WretchResponse } from 'wretch';
import type { QueryStringAddon } from 'wretch/addons';
import { requestIdbService } from '../indexed-db/request-idb-service';
import { notificationService } from '../notification/notification-service';
import { appHttpService } from './http-service';

type WretchRequest = Wretch<QueryStringAddon, unknown, undefined> & QueryStringAddon;

export interface FailedToFetchRequest {
  id: string;
  created: string;
  updated: string;
  config: {
    url: string;
    method: string;
    body?: string;
  };
}

export class FailedToFetchError extends Error {
  generatedId?: string;

  constructor(url: string, generatedId?: string) {
    super(`Failed to fetch on url: ${url}`);
    this.generatedId = generatedId;
  }
}

export function isFailedToFetchErrorOrThrow(
  error: unknown,
): asserts error is FailedToFetchError | never {
  if (error instanceof FailedToFetchError) {
    return;
  }

  throw error;
}

class FailedToFetchService {
  async add(request: WretchRequest) {
    const IGNORE_METHODS = ['GET'];
    const IGNORE_URLS = ['/auth/', '/seed', '/health'];

    const url = request._url;
    const { method, body } = request._options ?? {};

    const urlIncludesIgnoredUrls = IGNORE_URLS.some((ignoreUrl) => url.includes(ignoreUrl));

    if (IGNORE_METHODS.includes(method) || urlIncludesIgnoredUrls) {
      return;
    }

    const id = crypto.randomUUID();
    await requestIdbService.put({
      id,
      config: {
        method,
        url,
        body: body || undefined,
      },
      created: utils.date.nowInISO(),
      updated: utils.date.nowInISO(),
    });

    const changes = await requestIdbService.count();
    requestIdbService.setNumberOfOfflineChanges(changes);

    return id;
  }

  reset() {
    requestIdbService.deleteAll();
    requestIdbService.setNumberOfOfflineChanges(0);
  }

  async retry() {
    const delayInMs = 250;
    const requests = await requestIdbService.getAll();
    const requestsSortedByCreated = sortBy(requests, 'created');
    const total = requests.length;
    const generatedIdToDatabaseId = new Map<string, string>();

    for (const [index, request] of requestsSortedByCreated.entries()) {
      notificationService.open({
        text: i18n.global.t('general.syncingData', { count: index + 1, total }),
        type: 'info',
        timeout: delayInMs * 3,
      });

      let url = request.config.url.replace(`${config.BACKEND_URL}/api`, '');
      Array.from(generatedIdToDatabaseId.entries()).forEach(([generatedId, databaseId]) => {
        if (url.includes(generatedId)) {
          url = url.replace(generatedId, databaseId);
        }
      });

      const result = await appHttpService.request
        .fetch(
          request.config.method,
          url,
          request.config.body ? JSON.parse(request.config.body) : undefined,
        )
        .res()
        .catch(() => {
          logger.warn(`Failed to sync data for ${request.config.method} on ${request.config.url}`);
        });

      if (request.config.method === 'POST') {
        let databaseId;

        if (url.includes('service-order-row')) {
          databaseId = await this.getIdFromLastInsertedServiceOrderRow(result as WretchResponse);
        } else {
          databaseId = await this.getDatabaseIdFromResponse(result as WretchResponse);
        }

        generatedIdToDatabaseId.set(request.id, databaseId);
      }

      await requestIdbService.delete(request.id);
      const numberOfOfflineChanges = requestIdbService.getNumberOfOfflineChanges().value - 1;
      requestIdbService.setNumberOfOfflineChanges(numberOfOfflineChanges);
      await utils.wait(delayInMs);
    }
  }

  private async getDatabaseIdFromResponse(result: WretchResponse) {
    const responseAsJson = await result.json();
    return responseAsJson.id;
  }

  private async getIdFromLastInsertedServiceOrderRow(result: WretchResponse) {
    const responseAsJson = await result.json();
    return responseAsJson.rows.at(-1).id;
  }
}

export const failedToFetchService = new FailedToFetchService();
