import { createDataFunction } from '../../functions/index.js';
import { createCustomerLundstrom, createCustomerMattson } from './customer.js';
export const createPressureTestAssetAxel = createDataFunction({
    id: '00419cb6-0029-47a3-be42-e04c2f537f0b',
    name: 'Axel',
    contactName: 'Axel Axelsson',
    contactEmail: 'axel@axelsson.se',
    contactPhone: '+46123456789',
    note: 'En redig skuta',
    deliveryAddress: 'Britt-Maries Väg 64',
    deliveryPostCode: '23521',
    deliveryCity: 'Kramtorp',
    deliveryCountry: 'Italien',
    customer: createCustomerMattson(),
    created: '2023-10-09T00:25:44.129Z',
    updated: '2024-01-16T09:46:04.245Z',
});
export const createPressureTestAssetGasafjarden = createDataFunction({
    id: '02419cb6-0029-47a3-be42-e04c2f537f0b',
    name: 'Gåsafjärden',
    contactName: 'Gåsa Fjärdensson',
    contactEmail: 'gasa@fjadersson.se',
    contactPhone: '+46123456780',
    note: 'En gåsig skuta',
    deliveryAddress: 'Britt-Maries Väg 64',
    deliveryPostCode: '23521',
    deliveryCity: 'Kramtorp',
    deliveryCountry: 'Italien',
    customer: createCustomerLundstrom(),
    created: '2023-10-09T00:25:44.129Z',
    updated: '2024-01-16T09:46:04.245Z',
});
export const createUpcreatePressureTestAsset = createDataFunction({
    name: 'Gåsafjärden',
    contactName: 'Gåsa Fjärdensson',
    contactEmail: 'gasa@fjadersson.se',
    contactPhone: '+46123456780',
    note: 'En gåsig skuta',
    deliveryAddress: 'Britt-Maries Väg 65',
    deliveryPostCode: '23522',
    deliveryCity: 'Karlskrona',
    deliveryCountry: 'Sverige',
    customerId: createCustomerLundstrom().id,
});
