<script setup lang="ts">
import ServiceOrderTable from '@/components/service-order/service-order-table.vue';
import { useAuth } from '@/composeables/use-auth';
import { RouteName } from '@/router/models/route-name';

const { isAdmin } = useAuth();
</script>

<template>
  <section class="section">
    <div v-if="isAdmin" class="buttons is-right">
      <router-link
        v-if="isAdmin"
        :to="{ name: RouteName.ServiceOrdersCreate }"
        class="button is-success"
      >
        <iconify-icon icon="mdi:add-circle"></iconify-icon>
        <span class="ml-2">{{ $t('general.serviceOrderCreate') }}</span>
      </router-link>
    </div>
  </section>
  <section class="section">
    <div class="box">
      <service-order-table></service-order-table>
    </div>
  </section>
</template>
