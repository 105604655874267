<script setup lang="ts">
import BafModal from '@/components/standard/modal/baf-modal.vue';
import BafSearchSelect from '@/components/standard/search-select/baf-search-select.vue';
import { useLoading } from '@/composeables/use-loading';
import { employeeService } from '@/services/employee/employee-service';
import { serviceOrderService } from '@/services/service-order/service-order-service';
import { models, utils } from 'baf-shared';
import { onBeforeMount, ref, toRefs } from 'vue';

type FormData = {
  employee?: models.Employee;
};

function createFormData(serviceOrder?: models.ServiceOrder | models.ListServiceOrder): FormData {
  return {
    employee: serviceOrder?.employee ?? undefined,
  };
}

const { startLoading, stopLoading, loadingClass } = useLoading();
const modal = ref<InstanceType<typeof BafModal>>();

const emit = defineEmits<{
  (e: 'update', value: models.ServiceOrder): void;
}>();
const props = defineProps<{
  serviceOrder: models.ServiceOrder | models.ListServiceOrder;
  /**
   * When used, all service orders will be assigned to the selected employee.
   */
  serviceOrderIds?: string[];
}>();
const { serviceOrder, serviceOrderIds } = toRefs(props);

const formData = ref(createFormData(serviceOrder.value));
const employees = ref<models.Employee[]>([]);

function onCancel() {
  formData.value = createFormData(serviceOrder.value);
  modal.value?.close();
}

async function onSubmit() {
  if (!formData.value.employee) {
    return;
  }

  try {
    startLoading();

    if (serviceOrderIds.value) {
      const updatedServiceOrders = await serviceOrderService.updateEmployeeOnServiceOrders(
        serviceOrderIds.value,
        formData.value.employee.id,
      );

      formData.value = createFormData(updatedServiceOrders[0]);
      updatedServiceOrders.forEach((updatedServiceOrder) => emit('update', updatedServiceOrder));
    } else {
      const updatedServiceOrder = await serviceOrderService.updateEmployee(
        serviceOrder.value.id,
        formData.value.employee.id,
      );

      formData.value = createFormData(updatedServiceOrder);
      emit('update', updatedServiceOrder);
    }

    modal.value?.close();
  } finally {
    stopLoading();
  }
}

onBeforeMount(async () => {
  employees.value = utils.type.asType<models.Employee[]>(
    await employeeService.getAll({ access: models.Access.ServiceOrders }),
  );
});
</script>

<template>
  <baf-modal ref="modal" :title="`${$t('general.assign')}`" tag="form" @submit.prevent="onSubmit">
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <div class="field">
        <label class="label" for="employee">{{ $t('general.employee') }}</label>
        <div class="control">
          <baf-search-select
            id="employee"
            v-model="formData.employee"
            :values="employees"
            text-key="fullName"
            value-key="id"
          ></baf-search-select>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="button"
        data-testid="cancelButton"
        :class="loadingClass"
        @click="onCancel"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        type="submit"
        class="button is-primary"
        data-testid="updateButton"
        :class="loadingClass"
      >
        {{ $t('general.update') }}
      </button>
    </template>
  </baf-modal>
</template>
