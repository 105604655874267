<script setup lang="ts">
import { computed, toRefs } from 'vue';

const props = withDefaults(
  defineProps<{
    size?: number;
    block?: boolean;
  }>(),
  {
    size: 20,
  },
);
const { size, block } = toRefs(props);

const underline = computed(() => '_'.repeat(size.value));
</script>

<template>
  <span class="print-underline" :class="{ 'is-block': block }">{{ underline }}</span>
</template>

<style lang="scss" scoped>
.print-underline {
  letter-spacing: -2px;
}
</style>
