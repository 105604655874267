import { customerIdbService } from '@/services/indexed-db/customer-idb-service';
import { models } from 'baf-shared';
import { isFailedToFetchErrorOrThrow } from '../standard/failed-to-fetch-service';
import { appHttpService } from '../standard/http-service';

class CustomerService {
  async getAll(): Promise<models.Customer[]> {
    try {
      const customers = await appHttpService.request
        .url('/customers')
        .get()
        .json<models.Customer[]>();
      customerIdbService.putAll(customers);
      return customers;
    } catch (error) {
      isFailedToFetchErrorOrThrow(error);
      return customerIdbService.getAll();
    }
  }
}

export const customerService = new CustomerService();
