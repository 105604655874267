export var ServiceOrderStatus;
(function (ServiceOrderStatus) {
    ServiceOrderStatus["Created"] = "Created";
    ServiceOrderStatus["Assigned"] = "Assigned";
    ServiceOrderStatus["Received"] = "Received";
    ServiceOrderStatus["Accepted"] = "Accepted";
    ServiceOrderStatus["Denied"] = "Denied";
    ServiceOrderStatus["InProgress"] = "InProgress";
    ServiceOrderStatus["Resting"] = "Resting";
    ServiceOrderStatus["Finished"] = "Finished";
    ServiceOrderStatus["Completed"] = "Completed";
})(ServiceOrderStatus || (ServiceOrderStatus = {}));
export var ServiceOrderWarehouse;
(function (ServiceOrderWarehouse) {
    ServiceOrderWarehouse["Kalmar"] = "Kalmar";
    ServiceOrderWarehouse["Karlskrona"] = "Karlskrona";
    ServiceOrderWarehouse["Oskarshamn"] = "Oskarshamn";
    ServiceOrderWarehouse["Ryd"] = "Ryd";
    ServiceOrderWarehouse["Vaxjo"] = "Vaxjo";
})(ServiceOrderWarehouse || (ServiceOrderWarehouse = {}));
