import { computed, ref } from 'vue';

export function useLoading() {
  const loadingValue = ref(false);

  const loadingClass = computed(() => ({
    'is-loading': loadingValue.value,
  }));

  const startLoading = () => (loadingValue.value = true);
  const stopLoading = () => (loadingValue.value = false);

  return {
    loadingValue,
    loading: loadingValue,
    loadingClass,
    startLoading,
    stopLoading,
  };
}
