<script setup lang="ts">
import BafSearchSelect from '@/components/standard/search-select/baf-search-select.vue';
import { useLoading } from '@/composeables/use-loading';
import { pressureTestAssetService } from '@/services/pressure-test-asset/pressure-test-asset-service';
import { customerService } from '@/services/customer/customer-service';
import { models, type Nullish, utils } from 'baf-shared';
import { onBeforeMount, ref, toRefs } from 'vue';
import BafModal from '../standard/modal/baf-modal.vue';
import BafTextareaField from '@/components/standard/form/baf-textarea-field.vue';
import BafTextField from '@/components/standard/form/baf-text-field.vue';

const { startLoading, stopLoading, loadingClass } = useLoading();
const modal = ref<InstanceType<typeof BafModal>>();
const formData = ref(createFormData());
const customers = ref<models.Customer[]>([]);

type FormData = Omit<models.UpcreatePressureTestAsset, 'customerId'> & {
  customer: models.Customer;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  note?: string;
  deliveryAddress?: string;
  deliveryPostCode?: string;
  deliveryCity?: string;
  deliveryCountry?: string;
};
function createFormData(asset?: Nullish<models.PressureTestAsset>): FormData {
  return {
    name: asset?.name ?? '',
    contactName: asset?.contactName ?? '',
    contactEmail: asset?.contactEmail ?? '',
    contactPhone: asset?.contactPhone ?? '',
    note: asset?.note ?? '',
    deliveryAddress: asset?.deliveryAddress ?? '',
    deliveryPostCode: asset?.deliveryPostCode ?? '',
    deliveryCity: asset?.deliveryCity ?? '',
    deliveryCountry: asset?.deliveryCountry ?? '',
    customer: asset?.customer ?? utils.type.asType<models.Customer>(null),
  };
}

const props = defineProps<{
  asset?: models.PressureTestAsset;
}>();

const { asset } = toRefs(props);
const isUpdating = !!asset.value;

const emit = defineEmits<{
  (e: 'upcreate'): void;
}>();

async function onCancel() {
  formData.value = createFormData(asset.value);
  modal.value?.close();
}

async function upcreateAsset(): Promise<Nullish<models.PressureTestAsset>> {
  const upcreateAsset: models.UpcreatePressureTestAsset = {
    name: formData.value.name,
    contactName: formData.value.contactName,
    contactEmail: formData.value.contactEmail,
    contactPhone: formData.value.contactPhone,
    note: formData.value.note,
    deliveryAddress: formData.value.deliveryAddress,
    deliveryPostCode: formData.value.deliveryPostCode,
    deliveryCity: formData.value.deliveryCity,
    deliveryCountry: formData.value.deliveryCountry,
    customerId: formData.value.customer.id,
  };

  if (isUpdating) {
    return pressureTestAssetService.update(asset.value!.id, upcreateAsset);
  } else {
    await pressureTestAssetService.create(upcreateAsset);
    return undefined;
  }
}

async function onSubmit() {
  try {
    startLoading();
    const upcreatedAsset = await upcreateAsset();
    formData.value = createFormData(upcreatedAsset);
    emit('upcreate');
    modal.value?.close();
  } finally {
    stopLoading();
  }
}

onBeforeMount(async () => {
  try {
    startLoading();
    formData.value = createFormData(asset.value);
    customers.value = await customerService.getAll();
  } finally {
    stopLoading();
  }
});
</script>

<template>
  <baf-modal
    ref="modal"
    :title="isUpdating ? $t('general.assetUpdate') : $t('general.assetCreate')"
    tag="form"
    @submit.prevent="onSubmit"
  >
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <div class="field">
        <label class="label" for="assetCustomer"> {{ $t('general.customer') }}</label>
        <div class="control">
          <baf-search-select
            id="assetCustomer"
            v-model="formData.customer"
            data-testid="assetCustomerBafSearchSelect"
            :values="customers"
            value-key="id"
            text-key="name"
            required
          ></baf-search-select>
        </div>
      </div>

      <baf-text-field
        id="assetName"
        v-model="formData.name"
        :label="$t('general.name')"
        required
      ></baf-text-field>

      <baf-text-field
        id="assetContactName"
        v-model="formData.contactName"
        :label="$t('general.contactName')"
      ></baf-text-field>

      <baf-text-field
        id="assetEmail"
        v-model="formData.contactEmail"
        :label="$t('general.email')"
      ></baf-text-field>

      <baf-text-field
        id="assetPhone"
        v-model="formData.contactPhone"
        :label="$t('general.phoneNumber')"
      ></baf-text-field>

      <baf-text-field
        id="assetDeliveryAddress"
        v-model="formData.deliveryAddress"
        :label="$t('general.deliveryAddress')"
      ></baf-text-field>

      <baf-text-field
        id="assetDeliveryPostCode"
        v-model="formData.deliveryPostCode"
        :label="$t('general.deliveryPostCode')"
      ></baf-text-field>

      <baf-text-field
        id="assetDeliveryCity"
        v-model="formData.deliveryCity"
        :label="$t('general.deliveryCity')"
      ></baf-text-field>

      <baf-text-field
        id="assetDeliveryCountry"
        v-model="formData.deliveryCountry"
        :label="$t('general.deliveryCountry')"
      ></baf-text-field>

      <baf-textarea-field
        id="assetNote"
        v-model="formData.note"
        :label="$t('general.internalNote')"
        rows="5"
      ></baf-textarea-field>
    </template>

    <template #footer>
      <button
        type="button"
        class="button"
        data-testid="cancelButton"
        :class="loadingClass"
        @click="onCancel()"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        type="submit"
        class="button is-primary"
        data-testid="upcreateButton"
        :class="loadingClass"
      >
        {{ isUpdating ? $t('general.update') : $t('general.create') }}
      </button>
    </template>
  </baf-modal>
</template>
