<script setup lang="ts">
import BafModal from '@/components/standard/modal/baf-modal.vue';
import { useLoading } from '@/composeables/use-loading';
import { serviceOrderService } from '@/services/service-order/service-order-service';
import { models } from 'baf-shared';
import { ref, toRefs, watch } from 'vue';

type FormData = {
  information?: string;
  note?: string;
  informationSignature: boolean;
  noteSignature: boolean;
};

function createFormData(serviceOrder?: models.ServiceOrder): FormData {
  return {
    note: serviceOrder?.note ?? '',
    information: serviceOrder?.information ?? '',
    informationSignature: serviceOrder?.informationSignature ?? false,
    noteSignature: serviceOrder?.noteSignature ?? false,
  };
}
const { startLoading, stopLoading, loadingClass } = useLoading();
const modal = ref<InstanceType<typeof BafModal>>();

const emit = defineEmits<{
  (e: 'update', serviceOrder: models.ServiceOrder): void;
}>();

const props = withDefaults(
  defineProps<{
    serviceOrder: models.ServiceOrder;
    signatureRequired?: boolean;
  }>(),
  { signatureRequired: false },
);
const { serviceOrder, signatureRequired } = toRefs(props);

const formData = ref(createFormData(serviceOrder.value));

watch(serviceOrder, () => {
  formData.value = createFormData(serviceOrder.value);
});

function onCancel() {
  formData.value = createFormData(serviceOrder.value);
  modal.value?.close();
}

async function onSubmit() {
  try {
    startLoading();

    const updatedServiceOrder = await serviceOrderService.updateServiceOrder(
      serviceOrder.value.id,
      {
        warehouse: serviceOrder.value.warehouse,
        note: formData.value.note,
        information: formData.value.information,
        informationSignature: signatureRequired.value ? formData.value.informationSignature : false,
        noteSignature: signatureRequired.value ? formData.value.noteSignature : false,
      },
    );

    formData.value = createFormData(updatedServiceOrder);
    emit('update', updatedServiceOrder);

    modal.value?.close();
  } finally {
    stopLoading();
  }
}
</script>

<template>
  <baf-modal
    ref="modal"
    :title="
      signatureRequired
        ? `${$t('general.serviceOrderFinish')}`
        : `${$t('general.serviceOrderUpdate')}`
    "
    tag="form"
    @submit.prevent="onSubmit"
  >
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>
    <template #body>
      <div class="column">
        <div class="field">
          <label class="label" for="serviceOrderInformation">
            {{ $t('general.customerInformation') }}
          </label>
          <div class="control">
            <textarea
              id="serviceOrderInformation"
              v-model="formData.information"
              data-testid="serviceOrderInformationInput"
              rows="5"
              class="textarea"
            ></textarea>
          </div>
        </div>

        <div v-if="signatureRequired" class="field">
          <div class="control">
            <label class="checkbox">
              <input
                id="informationCheckbox"
                v-model="formData.informationSignature"
                type="checkbox"
                data-testid="informationCheckboxInput"
                required
              />
              {{ $t('general.iHaveReadThrough') }} {{ $t('general.customerInformation') }}
            </label>
          </div>
        </div>
        <div class="field">
          <label class="label" for="serviceOrderNote">{{ $t('general.internalNote') }}</label>
          <div class="control">
            <textarea
              id="serviceOrderNote"
              v-model="formData.note"
              data-testid="serviceOrderNoteInput"
              rows="5"
              class="textarea"
            ></textarea>
          </div>
        </div>
        <div v-if="signatureRequired" class="field">
          <div class="control">
            <label class="checkbox">
              <input
                id="noteCheckbox"
                v-model="formData.noteSignature"
                type="checkbox"
                data-testid="noteCheckboxInput"
                required
              />
              {{ $t('general.iHaveReadThrough') }} {{ $t('general.internalNote') }}
            </label>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="button"
        data-testid="cancelButton"
        :class="loadingClass"
        @click="onCancel()"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        type="submit"
        class="button is-primary"
        data-testid="submitButton"
        :class="loadingClass"
      >
        {{ signatureRequired ? $t('general.confirm') : $t('general.update') }}
      </button>
    </template>
  </baf-modal>
</template>
