<script setup lang="ts">
import { type models, sortBy } from 'baf-shared';
import BafTable from '@/components/standard/table/baf-table.vue';
import { computed, toRefs } from 'vue';
import { TableColumn } from '@/components/standard/table/models/table';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  certification: models.Certification;
}>();
const { certification } = toRefs(props);

const columns = computed(() => [
  TableColumn.for('rowNumber', t('general.rowNumberShort'), { charactersWidth: 4 }),
  TableColumn.for('data.name', t('general.name'), {
    renderer: (value, row) => row.data.name,
  }),
  TableColumn.for('data.placement', t('general.placement'), {
    renderer: (value, row) => row.data.placement,
  }),
  TableColumn.for('data.rechargePressureTest', t('general.pressureTestShort'), {
    renderer: (value, row) => row.data.rechargePressureTest,
  }),
  TableColumn.for('data.note', '', {
    renderer: (value, row) => row.data.note,
  }),
]);

const rows = computed(() => sortBy(certification.value.rows, 'rowNumber'));
</script>

<template>
  <baf-table :columns="columns" :rows="rows"></baf-table>
</template>
