<script setup lang="ts">
import { models, utils } from 'baf-shared';
import { toRefs } from 'vue';
import TitleValue from '@/components/standard/title-value/title-value.vue';

const props = defineProps<{
  disapprovedServiceOrderRows: models.ServiceOrderRow[];
}>();

const { disapprovedServiceOrderRows } = toRefs(props);

function getArticleDescriptionsFromRowActions(rowActions: models.ServiceOrderRowAction[]) {
  const articleDescriptions = rowActions.map((action) => action.article.description);
  return utils.joinValues(articleDescriptions, articleDescriptions.length <= 4 ? '\n' : ', ');
}
</script>

<template>
  <div>
    <h4>{{ $t('general.disapprovedServiceOrderRows') }}</h4>
    <table class="table">
      <thead>
        <tr>
          <th class="minimal-table-column nowrap">
            {{ $t('general.general') }}
          </th>
          <th>{{ $t('general.actions') }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="row in disapprovedServiceOrderRows" :key="row.id">
          <tr>
            <td>
              <title-value-root fullwidth>
                <title-value :title="$t('general.rowNumber')" :value="row.rowNumber" class="py-0">
                </title-value>
                <title-value
                  :title="$t('general.article')"
                  :value="row.article.description"
                  class="py-0"
                >
                </title-value>
                <title-value
                  :title="$t('general.placement')"
                  class="py-0"
                  :value="row.placement"
                ></title-value>
                <title-value
                  :title="$t('general.marking')"
                  class="py-0"
                  :value="row.marking"
                ></title-value>
              </title-value-root>
            </td>
            <td>
              <title-value-root fullwidth>
                <title-value
                  title=""
                  :value="getArticleDescriptionsFromRowActions(row.actions)"
                  class="py-0"
                  block
                  render-as-is
                >
                </title-value>
              </title-value-root>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.minimal-table-column {
  width: 40%;
}
.nowrap {
  white-space: nowrap;
}
</style>
