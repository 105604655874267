export var Command;
(function (Command) {
    Command["AddTopics"] = "add-topics-v1";
    Command["AddTenants"] = "add-tenants-v1";
    Command["Subscribe"] = "subscribe-v1";
})(Command || (Command = {}));
export var Topic;
(function (Topic) {
    Topic["Orders"] = "orders";
    Topic["Articles"] = "articles";
    Topic["Customers"] = "customers";
})(Topic || (Topic = {}));
