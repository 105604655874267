<script setup lang="ts">
import TitleValueRoot from '@/components/standard/title-value/title-value-root.vue';
import TitleValue from '@/components/standard/title-value/title-value.vue';
import { utils, type Nullish, type models } from 'baf-shared';
import { toRefs } from 'vue';

const props = defineProps<{
  customer?: Nullish<models.Customer>;
}>();
const { customer } = toRefs(props);
</script>

<template>
  <title-value-root>
    <title-value :title="$t('general.fortnoxId')" :value="customer?.fortnoxId"></title-value>
    <title-value
      :title="$t('general.organisationNumber')"
      :value="customer?.organisationNumber"
    ></title-value>
    <title-value :title="$t('general.name')" :value="customer?.name"></title-value>
    <title-value :title="$t('general.email')" :value="customer?.email"></title-value>
    <title-value
      :title="$t('general.updated')"
      :value="utils.date.formatDateTime(customer?.updated)"
    ></title-value>
  </title-value-root>
</template>
