<script setup lang="ts">
import BafLoader from '@/components/standard/loader/baf-loader.vue';
import { useAuth } from '@/composeables/use-auth';
import { RouteName } from '@/router/models/route-name';
import { models } from 'baf-shared';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { router } from '@/router/router';

const { t } = useI18n();
const { authenticated, me, loading: authenticationLoading, login } = useAuth();

const routes = computed(() => [
  {
    name: RouteName.ServiceOrdersRoot,
    text: t('general.routeName.serviceOrders'),
    requiresAuthentication: true,
    requiresAccess: [models.Access.ServiceOrders],
  },
  {
    name: RouteName.CertificationsRoot,
    text: t('general.routeName.certificates'),
    requiresAuthentication: true,
    requiresAccess: [models.Access.Certificates],
  },
  {
    name: RouteName.PressureTestsRoot,
    text: t('general.routeName.pressureTests'),
    requiresAuthentication: true,
    requiresAccess: [models.Access.PressureTests],
  },
]);
const $routes = computed(() =>
  routes.value.filter((route) => {
    if (route.requiresAuthentication && route.requiresAccess) {
      const hasAccess = route.requiresAccess.every((access) => me.value?.access?.includes(access));
      return authenticated.value && hasAccess;
    }

    return true;
  }),
);

onMounted(async () => {
  if ($routes.value.length === 1) {
    await router.push({ name: $routes.value[0].name });
  }
});
</script>

<template>
  <section class="section px-4">
    <p class="title">{{ $t('general.routeName.start') }}</p>
    <p v-if="authenticated" class="subtitle">{{ $t('pages.root.welcome') }}</p>

    <baf-loader v-if="authenticationLoading"></baf-loader>

    <section v-else-if="authenticated" class="section">
      <div class="columns">
        <div v-for="route in $routes" :key="route.name" class="column is-4">
          <router-link class="box" :to="{ name: route.name }">
            <p class="title is-4">{{ route.text }}</p>
          </router-link>
        </div>
      </div>
    </section>

    <section v-else class="section">
      <p class="subtitle is-5">{{ $t('pages.root.loggedOut') }}</p>

      <div class="columns">
        <div class="column is-3-desktop is-12-tablet">
          <button class="button is-primary is-light is-fullwidth" @click="login">
            {{ $t('general.login') }}
          </button>
        </div>
      </div>
    </section>
  </section>
</template>
