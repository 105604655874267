<script setup lang="ts">
import TitleValueRoot from '@/components/standard/title-value/title-value-root.vue';
import TitleValue from '@/components/standard/title-value/title-value.vue';
import { utils, type Nullish, type models } from 'baf-shared';
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  employee?: Nullish<models.Employee>;
}>();
const { employee } = toRefs(props);
const employeeAccess = computed(() =>
  employee.value?.access?.map((a) => t(`general.enums.access.${a}`)).join(', '),
);
</script>

<template>
  <title-value-root>
    <title-value :title="$t('general.fortnoxId')" :value="employee?.fortnoxId"></title-value>
    <title-value
      :title="$t('general.name')"
      :value="utils.joinValues([employee?.firstName, employee?.lastName])"
    ></title-value>
    <title-value :title="$t('general.access')" :value="employeeAccess"></title-value>
    <title-value
      :title="$t('general.updated')"
      :value="utils.date.formatDateTime(employee?.updated)"
    ></title-value>
  </title-value-root>
</template>
