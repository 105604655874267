<script setup lang="ts">
import BafTable from '@/components/standard/table/baf-table.vue';
import { TableColumn } from '@/components/standard/table/models/table';
import { type models, utils } from 'baf-shared';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { customerService } from '../../services/customer/customer-service';
import { useLoading } from '@/composeables/use-loading';

const { t } = useI18n();
const route = useRoute();
const { loading, startLoading, stopLoading } = useLoading();

const columns = [
  TableColumn.for('fortnoxId', t('general.fortnoxId'), { charactersWidth: 16 }),
  TableColumn.for('name', t('general.name')),
  TableColumn.for('updated', t('general.updated'), {
    renderer: (value) => utils.date.formatDateTime(value as string),
    right: true,
    charactersWidth: 20,
  }),
];
const data = ref<models.Customer[]>([]);

onBeforeMount(async () => {
  try {
    startLoading();
    data.value = await customerService.getAll();
  } finally {
    stopLoading();
  }
});
</script>

<template>
  <baf-table
    :columns="columns"
    :rows="data"
    :loading="loading"
    :sort-default="{ key: 'fortnoxId' }"
    :search-query="route.query"
    search
    paginate
  ></baf-table>
</template>
