<script setup lang="ts">
import BafTable from '@/components/standard/table/baf-table.vue';
import { TableColumn, type TableValue } from '@/components/standard/table/models/table';
import { useLoading } from '@/composeables/use-loading';
import { models, utils } from 'baf-shared';
import { computed, onBeforeMount, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { pressureTestService } from '@/services/pressure-test/pressure-test-service';
import PressureTestPrintInspectionCertificateModal from '@/components/pressure-test/pressure-test-print-inspection-certificate-modal.vue';
import { printData } from '@/utils/print-utils';
import { PrintTypeQuery } from '@/models/print-type-query';
import ConfirmModal from '@/components/modal/confirm-modal.vue';

const { t } = useI18n();
const route = useRoute();
const { loading, startLoading, stopLoading } = useLoading();

const props = defineProps<{
  sequentialNumber: models.PressureTestSequentialNumber;
}>();
const { sequentialNumber } = toRefs(props);

const emit = defineEmits<{
  (e: 'onClickEdit', pressureTest: models.PressureTest): void;
}>();

function onClickPrintReoccurringControl() {
  printData(PrintTypeQuery.ReoccurringControl, sequentialNumber.value.id);
}

const columns = [
  TableColumn.for('userActions', '', {
    charactersWidth: 1,
    search: false,
    sort: false,
  }),
  TableColumn.for('serialNumber', t('general.serialNumber')),
  TableColumn.for('customer', t('general.customer'), {
    renderer: (value) => utils.type.asType<models.Customer>(value).name,
  }),
  TableColumn.for('asset', t('general.object'), {
    renderer: (value) =>
      utils.joinValues([utils.type.asType<models.PressureTestAsset>(value).name]),
  }),
  TableColumn.for('article', t('general.article'), {
    renderer: (_value, row) => {
      const pressureTest = utils.type.asType<models.PressureTest>(row);
      return utils.joinValues(
        [pressureTest.manufacturer, pressureTest.gas, pressureTest.serialNumber],
        ', ',
      );
    },
  }),
  TableColumn.for('created', t('general.created'), {
    renderer: (value) => utils.date.formatDate(value as string),
    charactersWidth: 16,
  }),
  TableColumn.for('updated', t('general.updated'), {
    renderer: (value) => utils.date.formatDate(value as string),
    charactersWidth: 16,
  }),
  TableColumn.for('validFrom', t('general.validFrom'), {
    renderer: (value) => utils.date.formatDate(value as string),
    charactersWidth: 16,
  }),
  TableColumn.for('lastService', t('general.lastService'), {
    renderer: (value) => utils.date.formatDate(value as string),
    charactersWidth: 16,
  }),
  TableColumn.for('nextService', t('general.nextService'), {
    renderer: (value) => utils.date.formatDate(value as string),
    charactersWidth: 16,
  }),
];

const data = ref<models.PressureTest[]>([]);
const customers = computed(() =>
  utils.uniqueArrayByKey(
    data.value.map((data) => data.customer),
    'id',
  ),
);

const onEdit = (row: models.PressureTest) => {
  emit('onClickEdit', row);
};

function onDelete(id: TableValue) {
  const assetId = id as string;
  return async () => {
    try {
      startLoading();
      await pressureTestService.delete(assetId);
      data.value = await pressureTestService.getAll({
        sequentialNumberId: sequentialNumber.value.id,
      });
    } finally {
      stopLoading();
    }
  };
}

const loadPressureTests = async () => {
  try {
    startLoading();
    data.value = await pressureTestService.getAll({
      sequentialNumberId: sequentialNumber.value.id,
    });
  } finally {
    stopLoading();
  }
};

defineExpose({
  loadPressureTests,
});

onBeforeMount(async () => {
  await loadPressureTests();
});
</script>

<template>
  <div class="buttons is-right">
    <div class="column is-narrow is-12-touch">
      <a class="button is-info" @click.stop="onClickPrintReoccurringControl">
        <iconify-icon icon="mdi:printer"></iconify-icon>
        <span class="ml-2">{{ $t('general.printReoccurringControl') }}</span>
      </a>
    </div>

    <div class="column is-narrow is-12-touch">
      <pressure-test-print-inspection-certificate-modal
        :customers="customers"
        :sequential-number-id="sequentialNumber.id"
        #="{ open }"
      >
        <button
          class="button is-info"
          data-testid="printInspectionCertificateButton"
          @click.stop="open"
        >
          <iconify-icon icon="mdi:printer"></iconify-icon>
          <span class="ml-2">{{ $t('general.printInspectionCertificate') }}</span>
        </button>
      </pressure-test-print-inspection-certificate-modal>
    </div>
  </div>

  <baf-table
    :columns="columns"
    :rows="data"
    :loading="loading"
    :sort-default="{ key: 'updated', asc: false }"
    :search-query="route.query"
    search
    paginate
    hover
  >
    <template #column-userActions="{ row }">
      <div class="tags">
        <a class="tag is-link is-medium" data-testid="editTag" @click.stop="onEdit(row)">
          <iconify-icon icon="mdi:edit"></iconify-icon>
          <span class="ml-2">{{ $t('general.edit') }}</span>
        </a>
        <confirm-modal
          :message="
            $t('general.deleteConfirmPressureTest', {
              value: row.serialNumber,
            })
          "
          :confirm-callback="onDelete(row.id)"
          #="{ open }"
        >
          <a class="tag is-danger is-medium" data-testid="deleteButton" @click.stop="open">
            <iconify-icon icon="material-symbols:delete-outline"></iconify-icon>
            <span class="ml-2">{{ $t('general.delete') }}</span>
          </a>
        </confirm-modal>
      </div>
    </template>
  </baf-table>
</template>
