import type { PrintTypeQuery } from '@/models/print-type-query';
import { RouteName } from '@/router/models/route-name';
import { router } from '@/router/router';

export const printData = (type: PrintTypeQuery, id: string, customerId?: string) => {
  const resolved = router.resolve({
    name: RouteName.Print,
    query: {
      type,
      id,
      customerId,
    },
  });
  window.open(resolved.href, '_blank');
};
